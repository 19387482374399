import React from 'react';

const ProgressBar = ({ percentage, label }) => {
  return (
    <div className="w-full">
      <div className="relative pt-1">
        <div className="flex items-center justify-between">
          <div>
            <span className="text-xs font-semibold inline-block text-gray-600">{label}</span>
          </div>
          <div className="text-right">
            <span className="text-xs font-semibold inline-block text-gray-600">{percentage}%</span>
          </div>
        </div>
        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
          <div
            style={{ width: `${percentage}%` }}
            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-400"
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
