import React from 'react'
import { Container,Col,Row } from 'react-bootstrap'
import Register from '../../assets/images/register.png';
import Jwellery from '../../assets/images/jwellery.png';
import Orders from '../../assets/images/orders.png';
import Shipping from '../../assets/images/shipping.png';
import Money from '../../assets/images/money.png';
import HeaderTitle from '../HeaderTitle/HeaderTitle';

function JoinUs() {
  return (
    <div className='list join-us'>
        <Container>
        <div className="step-desc">
        <h1 > join us to <span className="pink-color-text">solve</span> your day to day problems.</h1>
        <p>digital duniya mein, apne new contacts digitally banao.</p>
        </div>
            <Row>
            <Col md={6}>
                    <div className='list-poster'>

                    </div>
                </Col>
                <Col md={5}>
                    <div className='list-content'>
                        <ul>
                            <li>
                                <div className='list-icon'>
                                </div>
                                <div className='list-details'>
                                    <h5>No Risky Travelling</h5>
                                    <p>Jewecle offers every wholeseller to sell unlimited jewellery stock by just sitting on the office chair. Then why to travel with gold stock which is full of risk.</p>
                                </div>
                            </li>
                            <li>
                                <div className='list-icon'>
                                </div>
                                <div className='list-details'>
                                    <h5>zero acquisition cost</h5>
                                  <p>get your new customers each day by showcasing your jewellery catalogue free of cost.</p>
                                </div>
                            </li>
                            <li>
                                <div className='list-icon'>
                                </div>
                                <div className='list-details'>
                                    <h5>zero uncertain credits</h5>
                                  <p>sell your jewellery and get your payment confirmed in 7 days. now no need to sell stock with uncertain credits.</p>
                                </div>
                            </li>
                            <li>
                                <div className='list-icon'>
                                </div>
                                <div className='list-details'>
                                    <h5>Stock is open</h5>
                                    <p>show your full stock to thousands of retailers and increase their interest in you. no need to take permission from a retailer to open your stock.</p>
                                </div>
                            </li>
                            <li>
                                <div className='list-icon'>
                                </div>
                                <div className='list-details'>
                                    <h5>Ease of doing business</h5>
                                    <p>with powerful supply chain management make your business easy and make more money day by day.</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </Col>
               
            </Row>
        </Container>
    </div>
  )
}

export default JoinUs
