import React from "react";
import moment from "moment";
import Loader from "../../../../Components/Loader/loaderv2";
import InquiryForm from "../../../../Pages/Dashboard/Enquiry/Sent/sentEnquiryForm";
import { useDeleteEnquiry, useGetEnquiry } from "../../../../hooks/enquiry";
import { useSocket } from "../../../../context/socket";
import { useEffect, useState } from "react";
import DeleteModal from "../../../../Components/DeleteConfirmationModal";
import CarouselComponent from "../../../../Components/Carousel/index";
import ImageGallery from "react-image-gallery";
const Index = () => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [inquiry, setInquiry] = useState([]);
  const [id, setId] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [offset, setOffset] = useState(0);
  const [showGallery, setShowGallery] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [images, setImages] = useState([]);
  // const { data, error, isLoading, refetch } = useJewelleryInquiry(perPage, page);
  const socket = useSocket();
  const GetJewelleryMutation = useGetEnquiry();
  useEffect(() => {
    fetchJewellery(currentPage, perPage);
  }, [currentPage, perPage]);

  useEffect(() => {
    if (socket) {
      socket.on("jewellery_inquiry", (data) => {
        fetchJewellery(currentPage, perPage);
      });
    }
  }, [socket]);

  const fetchJewellery = async (pageNo, perPage) => {
    setPage(pageNo);
    setPerPage(perPage);
    setCurrentPage(pageNo);
    const data = await GetJewelleryMutation.mutateAsync({
      perPage: perPage,
      page: pageNo,
      sent: 1,
    });
    setCount(data?.responseData?.count);
    setInquiry(data);
  };

  const handleDeleteModal = (event, enquiryList) => {
    event.stopPropagation();
    setDeleteModal(!deleteModal);
    // setInquiry(data)
  };

  const getText = (data) => {
    return `
    %2A%2ASupplier%27s+Response%3A%2A%2A%0AHello+${data?.user_id?.firstName}%2C%0A%0AThank+you+for+your+inquiry+regarding+the+product.+Here+are+the+details%3A%0A-+%2A%2AProduct+Name%3A%2A%2A+${data?.jewellery_name}%0A-+%2A%2APurity%3A%2A%2A+${data?.purity}%0A-+%2A%2ASize%3A%2A%2A+${data?.size}%0A-+%2A%2AWeight%3A%2A%2A+${data?.weight}%0A-+%2A%2AColor%3A%2A%2A+${data?.color}%0A-+%2A%2ADate%3A%2A%2A+${data?.createdAt}%0A%0AIf+you+have+any+further+questions+or+would+like+to+proceed+with+an+order%2C+please+feel+free+to+contact+us.+We+are+here+to+assist+you.%0A%0ABest+Regards%2C%0ASupplier%27s+Name&type=phone_number&app_absent=0
    `;
  };

  const getColor = (color) => {
    if (color === "Yellow") {
      return "#FAE270";
    } else if (color === "Rose Gold") {
      return "#B18A6F";
    } else {
      return "#9D9EA3";
    }
  };

  const getPagesCount = () => {
    const totalPages = Math.ceil(count / perPage);
    return [...Array(totalPages).keys()].map((n) => n + 1);
  };

  const totalPages = getPagesCount().length;

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
      setPage((prevPage) => prevPage + 1);
      if (currentPage - offset >= 4) {
        // If we're at the last displayed page button
        setOffset((prevOffset) => prevOffset + 5);
      }
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      setPage((prevPage) => prevPage - 1);
      if (currentPage - offset === 1) {
        // If we're at the first displayed page button
        setOffset((prevOffset) => prevOffset - 5);
      }
    }
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    setPage(pageNumber);
    fetchJewellery(pageNumber, perPage);
    setCurrentPage(pageNumber);
  };

  const handlePePage = (e) => {
    setPerPage(Number(e.target.value));
    setPage(1);
    setCurrentPage(1);
  };
  const closeGallery = () => {
    setShowGallery(false);
  };

  const openGallery = (index, images) => {
    setShowGallery(true);
    setCurrentIndex(index);
    const arr = [];
    images.map((image) => {
      arr.push({
        original: image,
        thumbnail: image,
      });
    });
    setImages(arr);
  };

  return showGallery ? (
    <>
      <div className="relative h-screen">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-8 w-8 absolute top-8 left-8 cursor-pointer text-white z-10"
          fill="none"
          viewBox="0 0 24 24"
          stroke="#F64668"
          onClick={closeGallery}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
        <ImageGallery
          items={images}
          showFullscreenButton={true}
          showPlayButton={false}
          startIndex={currentIndex}
          showIndex={true}
          showNav={true}
          additionalClass="image-gallery" // Optional: Add a custom CSS class to ImageGallery
          onRequestClose={closeGallery}
        />
      </div>
    </>
  ) : (
    <>
      <div className="common-layout min-h-[850px]">
        <div className="flex justify-between w-full items-center my-3">
          <h1 className="text-lg md:text-2xl ml-2 font-bold">Sent Enquiries</h1>
        </div>
        {GetJewelleryMutation.isLoading ? (
          <Loader />
        ) : (
          <div className="flex flex-col gap-2">
            {inquiry?.responseData?.items &&
            inquiry?.responseData.items.length > 0 ? (
              inquiry?.responseData.items.map((inquiry, index) => (
                <div
                  className="bg-white rounded-lg shadow-md pt-2 px-2 pb-0 w-full flex flex-col md:flex-row mt-3 md:mt-0"
                  key={index}
                >
                  <div className="flex flex-col">
                      <div className="flex gap-2 mb-2 justify-center">
                        <h5 className="font-bold text-lg">By</h5>
                        <p className="whitespace-nowrap text-lg font-bold">
                          {inquiry?.user_id?.firstName
                            ? inquiry?.user_id?.firstName
                            : "-"}
                        </p>
                      </div>
                      <div className="flex justify-center ">
                        <div
                          onClick={() =>
                            openGallery(index, inquiry.jewellery_images)
                          }
                        >
                          <CarouselComponent
                            images={inquiry.jewellery_images}
                          />
                        </div>
                      </div>
                    </div>
                  <div className="md:w-1/3 flex flex-col mr-2 md:ml-3 pr-4 mt-1">
                    <div className="flex gap-2">
                      {/* <h5 className="font-bold text-lg">Jewellery name:</h5> */}
                      <h5 className="whitespace-nowrap font-bold">
                        {inquiry.jewellery_name}
                      </h5>
                    </div>
                    <div className="md:flex gap-4">
                      <div className="md:w-1/2">
                        <div className="flex gap-2 mb-2">
                          <h5 className="font-bold text-base">Purity:</h5>
                          <p className="whitespace-nowrap text-base">
                            {inquiry.purity ? inquiry.purity : "-"}k
                          </p>
                        </div>
                        <div className="flex gap-2 mb-2">
                          <h5 className="font-bold text-base">Size:</h5>
                          <p className="whitespace-nowrap text-base">
                            {inquiry.size ? inquiry.size : "-"}
                          </p>
                        </div>
                        <div className="flex gap-2">
                          <h5 className="font-bold text-base">Date:</h5>
                          <p className="whitespace-nowrap text-base">
                            {moment(inquiry.createdAt).format("YYYY-MM-DD")}
                          </p>
                        </div>
                        {/* <div className="flex gap-2 mb-2">
                          <h5 className="font-bold text-lg">By:</h5>
                          <p className="whitespace-nowrap">
                            {inquiry?.user_id?.firstName
                              ? inquiry?.user_id?.firstName
                              : "-"}
                          </p>
                        </div> */}
                      </div>
                      <div className="md:w-1/2 mr-1">
                        <div className="flex gap-2 mb-2">
                          <h5 className="font-bold text-base">Weight:</h5>
                          <p
                            className={`${
                              inquiry.is_ask_weight &&
                              "text-primary-pink font-bold text-base"
                            }`}
                          >
                            {inquiry.is_ask_weight
                              ? "Ask for!"
                              : inquiry.weight || "-"}
                          </p>
                        </div>
                        <div className="flex items-center gap-2 mb-2">
                          <h5 className="font-bold text-base">Color:</h5>
                          {inquiry?.color
                            ? getColor(inquiry.color) && (
                                <p
                                  className="whitespace-nowrap rounded-full  border p-2"
                                  style={{
                                    backgroundColor: getColor(inquiry.color),
                                  }}
                                ></p>
                              )
                            : "-"}
                        </div>
                        <div className="flex gap-2 mb-2">
                            <h5 className="font-bold text-base">Ph:</h5>
                            <p className="text-base ">
                              {inquiry?.user_id?.phone}
                            </p>
                          </div>
                       
                      </div>
                    </div>

                    <div className="mt-2 flex gap-2">
                      <a
                        href={`https://wa.me/${
                          inquiry?.user_id?.phone
                        }?text=${getText(inquiry)}`}
                      >
                        <button className="bg-green-500 text-white px-2 py-1 rounded-md mr-1">
                          WhatsApp
                        </button>
                      </a>
                      <a href={`tel:${inquiry?.user_id?.phone}`}>
                        <button className="bg-blue-500 text-white px-2 py-1 rounded-md mr-1">
                          Call
                        </button>
                      </a>
                    </div>
                  </div>
                  <hr className="md:hidden my-3"></hr>
                  {/* Send Quotation Form */}
                  <InquiryForm key={inquiry.id} inquiry={inquiry} />
                </div>
              ))
            ) : (
              <h3>No Data Found</h3>
            )}
            {/* Pagination Controls */}
            {!GetJewelleryMutation.isLoading && count !== 0 && (
              <div className="md:flex md:items-center md:justify-end mt-4">
                {/* <div className="flex items-center space-x-4">
                  <label htmlFor="perPage" className="text-sm text-gray-600">
                    Per Page:
                  </label>
                  <div className="relative">
                    <select
                      id="perPage"
                      onChange={(e) => handlePePage(e)}
                      value={perPage}
                      className="block appearance-none w-24 bg-white border border-gray-300 text-gray-600 py-2 px-3 pr-8 rounded leading-tight  "
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                    </select>
                  </div>
                </div> */}

                {/* Pagination Controls */}
                <div className="flex items-center mt-3 md:mt-0">
                  <button
                    disabled={currentPage === 1}
                    onClick={handlePrev}
                    className={`px-3 py-2 border rounded-md text-gray-700 border-gray-300 ${
                      currentPage === 1
                        ? "opacity-50 cursor-not-allowed"
                        : "hover:bg-gray-200 focus:outline-none"
                    }`}
                    id="prev"
                  >
                    &laquo; Prev
                  </button>

                  <div className="flex space-x-2">
                    {getPagesCount().map((page, index) => (
                      <button
                        key={index}
                        onClick={() => handlePageClick(page)}
                        className={`px-3 py-2 border rounded-md ${
                          page === currentPage
                            ? "font-semibold text-white bg-primary-pink"
                            : "text-gray-700 border-gray-300"
                        } hover:bg-gray-200 focus:outline-none`}
                        data-page={page}
                      >
                        {page}
                      </button>
                    ))}
                  </div>

                  <button
                    disabled={currentPage === totalPages}
                    onClick={handleNext}
                    className={`ml-2 px-3 py-2 border rounded-md text-gray-700 border-gray-300 ${
                      currentPage === totalPages
                        ? "opacity-50 cursor-not-allowed"
                        : "hover:bg-gray-200 focus:outline-none"
                    }`}
                    id="next"
                  >
                    Next &raquo;
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Index;
