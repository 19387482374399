import React, { useState } from "react";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import upload_file_ic from "../../../assets/images/icons/upload_file_ic.svg";
import { useSendQuotationMutation } from "../../../hooks/enquiry";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import { RxCross2 } from "react-icons/rx";
import { useImageUpload } from "../../../hooks/common";

const customStyles = {
  content: {
    top: "55%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "40%", // For mobile by default
    height: "auto",
    backgroundColor: "white", // Change this to a solid color
  },
  contentMobile: {
    width: "80%",
    height: "60%",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // This is the backdrop
  },
};
const EnquiryModal = ({ openGallery, isOpen, closeModal, inquiry, togglePreview }) => {
  const sendQuotation = useSendQuotationMutation(closeModal);
  const imageUpload = useImageUpload();
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

  const isMobile = window.innerWidth <= 768;
  const appliedStyles = isMobile
    ? { ...customStyles.content, ...customStyles.contentMobile }
    : customStyles.content;
  const {
    register,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("inquiry_id", inquiry._id);
    formData.append("jewellery_images", data.photo[0]);
    formData.append("jewellery_wastage", data.wastage);
    formData.append("charges", data.charges);
    formData.append("delivery_time", data.delivery);
    formData.append("note", data.note);

    await sendQuotation.mutate(formData);
    reset();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      imageUpload.mutate(formData);
      const reader = new FileReader();

      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };

      reader.readAsDataURL(file);
    } else {
      setImagePreviewUrl(null);
    }
    trigger("photo");
  };

  const clearImage = () => {
    setImagePreviewUrl(null);
  };


  

  return (
    <Modal
    isOpen={isOpen}
    onRequestClose={closeModal}
    style={{ ...customStyles, content: appliedStyles }}
  >
    <div className="overflow-hidden">
      <h3 className="font-bold mb-3">Enquiry Form</h3>
      <div className="border rounded-lg shadow-md w-full flex flex-col md:flex-row mt-3 md:mt-0">
        <div className="w-full h-full md:w-1/4 flex" onClick={() =>
                      openGallery(inquiry.jewellery_images)
                    }>
          <img
            src={inquiry?.jewellery_images[0]}
            alt={inquiry.jewelleryName}
            className="md:w-40 h-36 w-full md:h-40 object-cover rounded-md"
          />
        </div>
        <div className="md:w-1/3 flex flex-col mr-2 mt-3 md:mt-0 md:ml-3 pr-4">
          <div className="md:flex md:gap-28">
            <div className="md:w-1/2">
              <div className="flex gap-2 mb-2">
                <h5 className="font-bold text-sm">Purity:</h5>
                <p className="whitespace-nowrap">{inquiry.purity} K</p>
              </div>
              <div className="flex gap-2 mb-2">
                <h5 className="font-bold text-sm">Size:</h5>
                <p className="whitespace-nowrap">{inquiry.size}</p>
              </div>
              <div className="flex gap-2 mb-2">
                <h5 className="font-bold text-sm">By:</h5>
                <p className="whitespace-nowrap">{inquiry.customer_name}</p>
              </div>
            </div>
            <div className="md:w-1/2">
              <div className="flex gap-2 mb-2">
                <h5 className="font-bold text-sm">Weight:</h5>
                <p className="whitespace-nowrap">
                  {inquiry.is_ask_weight ? "Ask for!" : inquiry.weight}
                </p>
              </div>
              <div className="flex gap-2 mb-2">
                <h5 className="font-bold text-sm">Color:</h5>
                <p className="whitespace-nowrap">{inquiry.color}</p>
              </div>
              <div className="flex gap-2 mb-2">
                <h5 className="font-bold text-sm">Date:</h5>
                <p className="whitespace-nowrap">
                  {moment(inquiry.createdAt).format("YYYY-MM-DD")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        key={inquiry._id}
        className="mt-3"
      >
        <div className="md:flex md:gap-3">
          <div className="mb-3">
            <label className="font-bold">Wastage</label>
            <input
              type="text"
              className={`border-2 rounded-md p-2 w-full text-primary-pink font-bold ${
                errors.wastage ? "border-red-700" : ""
              }`}
              {...register("wastage", { required: true })}
            />
            {errors.wastage && (
              <p className="text-red-600 ">Wastage is required</p>
            )}
          </div>
          <div className="mb-3 font-bold">
            <label className="">Other Charges</label>
            <input
              type="text"
              {...register("charges", { required: true })}
              className={`border-2 rounded-md p-2 w-full ${
                errors.delivery ? "border-1 border-red-700" : ""
              }`}
            />
            {errors.charges && (
              <p className="text-red-600 ">Charges is required</p>
            )}
          </div>
          <div className="mb-3 font-bold">
            <label className="">Deliver in Days</label>
            <input
              type="text"
              {...register("delivery", { required: true })}
              className={`border-2 rounded-md p-2 w-full ${
                errors.delivery ? "border-1 border-red-700" : ""
              }`}
            />
            {errors.delivery && (
              <p className="text-red-600 ">Delivery in days is required</p>
            )}
          </div>
          {inquiry.is_ask_weight && (
            <div className="mb-3 font-bold">
              <label htmlFor="weight" className="">
                Weight
              </label>
              <input
                type="text"
                {...register("weight", {
                  required: inquiry.is_ask_weight && true,
                })}
                className={`border-2 rounded-md p-2 w-full ${
                  errors.weight ? "border-1 border-red-700" : ""
                }`}
              />
              {errors.weight && (
                <p className="text-red-600 ">Weight is required</p>
              )}
            </div>
          )}
        </div>
        <div className="mb-3 w-full">
          <label className="">Note</label>
          <input
            type="text"
            {...register("note")}
            className="border-2 rounded-md p-2 w-full"
          />
        </div>
        <div className="mb-3">
          <div className="flex items-center justify-center">
            <div className="flex flex-row items-center p-3 space-x-4 rounded-md">
              {imagePreviewUrl ? (
                <div className="relative w-32 h-32 border rounded-lg">
                  <img
                    src={imagePreviewUrl}
                    onClick={() => togglePreview(imagePreviewUrl)}
                    className="w-full h-full object-cover object-center"
                    alt="Photo Preview"
                  />
                  <div
                    className="absolute top-0 cursor-pointer right-0 bg-gray-400 rounded-full p-2"
                    onClick={clearImage}
                  >
                    <RxCross2 />
                  </div>
                </div>
              ) : (
                <>
                  <label
                    className={`flex flex-col items-center px-6 py-3 tracking-wide uppercase transition-colors duration-200 bg-white border border-blue-400 rounded-md shadow-md cursor-pointer hover:bg-blue-50 text-blue-400 ease select-none focus:outline-none`}
                  >
                    <img
                      className="upload-ic"
                      src={upload_file_ic}
                      alt=""
                      style={{ width: "24px", height: "24px" }}
                    />
                    <span className="mt-1 text-sm  leading-normal text-pink-600">
                      Select a file
                    </span>
                    <input
                      type="file"
                      className="hidden"
                      {...register("photo")}
                      onChange={handleFileChange}
                    />
                  </label>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-between">
          <button
            type="button"
            className=" px-2 py-1 rounded-md  mt-4 whitespace-nowrap"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            type="submit"
            className={`bg-primary-pink  text-white px-2 py-1 rounded-md  mt-4 whitespace-nowrap
                              ${
                                sendQuotation.isLoading
                                  ? "disable-button"
                                  : ""
                              }`}
            disabled={sendQuotation.isLoading ? true : false}
          >
            {sendQuotation.isLoading ? (
              <>
                <Spinner animation="border" size="sm" className="me-2" />
                Loading...
              </>
            ) : (
              " Send Quotation"
            )}
          </button>
        </div>
      </form>
    </div>
  </Modal>
  )
};

export default EnquiryModal;
