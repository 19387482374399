import { useEffect, useRef, useState } from "react";
import Loaderv2 from "../../../Components/Loader/loaderv2";
import {
  useGetOrders,
  useUpdateOrderStatusMutation,
} from "../../../hooks/order";
import jsPDF from "jspdf";
import { FaEye } from "react-icons/fa";
import { PiFilePdfLight } from "react-icons/pi";
import { IoMdDoneAll } from "react-icons/io";
import ViewOrderModal from './ViewOrderModal'
import ConfirmModal from "../../../Components/DeleteConfirmationModal";
import moment from 'moment';
const Index = () => {
  const pdfRef = useRef();
  const updateOrderStatusMutation = useUpdateOrderStatusMutation();
  const GetJewelleryMutation = useGetOrders();
  const [orders, setOrders] = useState([]);
  const [perPage, setPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(0);
  const [isOrderViewModalOpen, setIsViewModalOpen] = useState(false)
  const [specificOrderDetails, setSpecificOrderDetails] = useState()
  const [isConfirmationModalOpen, setIsConfirmationModal] = useState(false)
  const fetchOrders = async (pageNo, perPage) => {
    setPage(pageNo);
    setPerPage(perPage);
    setCurrentPage(pageNo);
    const data = await GetJewelleryMutation.mutateAsync({
      page: pageNo,
      perPage: perPage,
      status: "pending",
    });
    setOrders(data.responseData.orders);
    setCount(data.responseData.count);
  };
  useEffect(() => {
    fetchOrders(currentPage, perPage);
  }, [currentPage, perPage]);

  const openViewOrderModal  = (item) => {
    setSpecificOrderDetails(item)
    setIsViewModalOpen(true)
  }

  const openConfirmModal  = (item) => {
    setSpecificOrderDetails(item)
    setIsConfirmationModal(true)
  }
  const renderTableBody = () => {
    if (!orders || orders.length === 0) {
      return (
        <tr className="flex justify-center items-center">
          <td
            colSpan="1"
            className="text-center font-bold whitespace-nowrap p-3"
          >
            No Data Found
          </td>
        </tr>
      );
    }
    
    return orders.map((item, index) => (
      <tr key={item.id} className="">
        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
          <div class="flex items-center">
            <div class="flex-shrink-0 h-10 w-10">
              <img
                class="h-10 w-10 rounded-full"
                src={item?.images && item?.images[0]}
                alt={item?.product_name}
                // onClick={() => openGallery(index, item?.images)}
              />
            </div>
          </div>
        </td>
        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-gray-900 text-sm">
          {item?.jewellery.product_name}
        </td>
        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-gray-900 text-sm">
          {moment(item?.createdAt).format('YYYY-MM-DD HH:mm:ss')}
        </td>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-base leading-5 text-gray-500">
          {item?.status}
        </td>
        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-gray-900 text-sm">
          {item.jewellery.jewecle_id}
        </td>
        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-gray-900 text-sm">
          {item.purity + " " + "K"}
        </td>
        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-gray-900 text-sm">
          {item.net_weight} GRMS
        </td>
        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-gray-900 text-sm">
          {item.gross_weight} GRMS
        </td>
        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-gray-900 text-sm">
          {item.size + " " + item.measurement_in}
        </td>
        <td class="px-6 py-4 whitespace-no-wrap border-b text-primary-pink text-sm border-gray-200 font-bold">
          {item.jewellery.wastage_details.wastage} %
        </td>
        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-gray-900">
          {item?.color
            ? getColor(item?.color) && (
                <p
                  className="whitespace-nowrap rounded-full border p-3 w-4"
                  style={{
                    backgroundColor: getColor(item?.color),
                  }}
                ></p>
              )
            : "-"}
        </td>
        <td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
          <div className="flex gap-3">
            <div className="cursor-pointer" onClick={() => openViewOrderModal(item)}>
              <button className="bg-pink-500 text-white text-sm px-2 py-1 rounded-md mr-1">
               <FaEye className="w-5 h-5" /> 
              </button>
            </div>
            {/* <div className="cursor-pointer" onClick={() => handlePdf(item)}> */}
            <div className="cursor-pointer" onClick={() => handlePdf(item)}>
              <button className="bg-blue-500 text-white text-sm px-2 py-1 rounded-md mr-1">
                <PiFilePdfLight className="w-5 h-5"   />
              </button>
            </div>
            <div
              className="cursor-pointer"
              // onClick={() => handleUpdateStatus(item._id)}
              onClick={() => openConfirmModal(item._id)}
            >
              <button className="bg-green-500 text-white px-2 text-sm py-1 rounded-md mr-1">
                <IoMdDoneAll className="w-5 h-5" />              </button>
            </div>
          </div>
        </td>
      </tr>
    ));
  };
  const getColor = (color) => {
    if (color === "Yellow") {
      return "#FAE270";
    } else if (color === "Rose Gold") {
      return "#B18A6F";
    } else {
      return "#9D9EA3";
    }
  };
  const handleUpdateStatus = async (orderId) => {
     updateOrderStatusMutation.mutate({
      orderId: specificOrderDetails,
      data: { status: "completed" },
    });
    await fetchOrders(currentPage, perPage);
    setIsConfirmationModal(false)
  };
 

  const handlePdf = (orderData) => {
    const pdf = new jsPDF();
    pdf.setFont("helvetica");
    pdf.setFontSize(14);
    pdf.text(20, 20, `Order Details - ${orderData.jewellery.product_name}`);
    pdf.setFontSize(12);
    pdf.text(20, 30, `Name: ${orderData.jewellery.product_name}`);
    pdf.text(20, 40, `Color: ${orderData.color}`);
    pdf.text(20, 50, `Gross Weight: ${orderData.gross_weight}`);
    pdf.text(20, 60, `Net Weight: ${orderData.net_weight}`);
    pdf.text(20, 70, `Purity: ${orderData.purity}`);
    pdf.text(20, 80, `Size: ${orderData.size} ${orderData.measurement_in}`);
  
    const img = new Image();
  
    img.onload = function () {
      pdf.addImage(this, "JPEG", 20, 90, 100, 80);
      pdf.save("jewelry_details.pdf");
    };
  
    img.onerror = function () {
      console.error("Error loading image:", orderData.images[0]);
      // Handle the error, such as displaying an error message to the user
    };
  
    img.src = orderData.images[0];
    pdf.save("jewelry_details.pdf");
   
  };
  
  const handlePePage = (e) => {
    setPerPage(Number(e.target.value));
    setPage(1);
    setCurrentPage(1);
  };
  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      setPage((prevPage) => prevPage - 1);
      if (currentPage - offset === 1) {
        // If we're at the first displayed page button
        setOffset((prevOffset) => prevOffset - 5);
      }
    }
  };
  const getPagesCount = () => {
    const totalPages = Math.ceil(count / perPage);
    return [...Array(totalPages).keys()].map((n) => n + 1);
  };
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    setPage(pageNumber);
    fetchOrders(pageNumber, perPage);
    setCurrentPage(pageNumber);
  };
  const totalPages = getPagesCount().length;
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
      setPage((prevPage) => prevPage + 1);
      if (currentPage - offset >= 4) {
        // If we're at the last displayed page button
        setOffset((prevOffset) => prevOffset + 5);
      }
    }
  };
  const getDisplayedPages = () => {
    const totalPagesToShow = 5; // Adjust the number of pages to show
    const startPage = Math.max(
      1,
      currentPage - Math.floor(totalPagesToShow / 2)
    );
    const endPage = Math.min(totalPages, startPage + totalPagesToShow - 1);

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, index) => startPage + index
    );
  };

  const renderViewModal = () => {
    if(!isOrderViewModalOpen){
      return
    }
    return <ViewOrderModal isOpen={isOrderViewModalOpen} specificOrderDetails={specificOrderDetails} closeModal={() => setIsViewModalOpen(false)} />
  }
  console.log('isConfirmationModalOpen', isConfirmationModalOpen)
  const renderConfirmationModal = () => {
   return isConfirmationModalOpen && (
     <ConfirmModal
        isOpen={isConfirmationModalOpen}
        closeModal={() => setIsConfirmationModal(false)}
        handleDeleteConfirmation={handleUpdateStatus}
        title="Are you sure want to finish order?"
      />
    )
  }
  return (
    <div>
      {GetJewelleryMutation.isLoading ? (
        <div className="">
          <Loaderv2 />
        </div>
      ) : (
        <div className="p-3">
          <div className="h-[600px] w-full overflow-x-auto  scrollbar-thumb-gray-300 scrollbar-track-gray-100">
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table class="min-w-full">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Image
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Creation Date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Jewecle Id
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Purity
                    </th>
                    {/* <th scope="col" className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                    Availablity
                                                </th> */}
                    <th
                      scope="col"
                      className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Net Weight
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Gross Weight
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Size
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Wastage
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Color
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Action
                    </th>
                  </tr>
                </thead>

                <tbody class="bg-white">{renderTableBody()}</tbody>
              </table>
            </div>
          </div>
          {!GetJewelleryMutation.isLoading && (
            <div className="md:flex md:items-center md:justify-end mt-4">
              {/* <div className="flex items-center space-x-4">
                <label htmlFor="perPage" className="text-sm text-gray-600">
                  Per Page:
                </label>
                <div className="relative">
                  <select
                    id="perPage"
                    onChange={(e) => handlePePage(e)}
                    value={perPage}
                    className="block appearance-none w-24 bg-white border border-gray-300 text-gray-600 py-2 px-3 pr-8 rounded leading-tight  "
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                  </select>
                </div>
              </div> */}

              {/* Pagination Controls */}
              <div className="flex items-center mt-3 md:mt-0">
                <button
                  disabled={currentPage === 1}
                  onClick={handlePrev}
                  className={`px-3 py-2 border rounded-md text-gray-700 border-gray-300 ${
                    currentPage === 1
                      ? "opacity-50 cursor-not-allowed"
                      : "hover:bg-gray-200 focus:outline-none"
                  }`}
                  id="prev"
                >
                  <span className="hidden md:inline">&laquo;&nbsp;</span>Prev
                </button>

                <div className="flex space-x-2">
                  {getDisplayedPages().map((page, index) => (
                    <button
                      key={index}
                      onClick={() => handlePageClick(page)}
                      className={`px-3 py-2 border rounded-md ${
                        page === currentPage
                          ? "font-semibold text-white bg-primary-pink"
                          : "text-gray-700 border-gray-300"
                      } hover:bg-gray-200 focus:outline-none`}
                      data-page={page}
                    >
                      {page}
                    </button>
                  ))}
                </div>

                <button
                  disabled={currentPage === totalPages}
                  onClick={handleNext}
                  className={`ml-2 px-3 py-2 border rounded-md text-gray-700 border-gray-300 ${
                    currentPage === totalPages
                      ? "opacity-50 cursor-not-allowed"
                      : "hover:bg-gray-200 focus:outline-none"
                  }`}
                  id="next"
                >
                  Next&nbsp;<span className="hidden md:inline">&raquo;</span>
                </button>
              </div>
            </div>
          )}
        </div>
      )}
      {renderViewModal()}
      {renderConfirmationModal()}
    </div>
  );
};
export default Index;
