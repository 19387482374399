import React from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SuccesIc from '../../assets/images/icons/succes_ic.svg'

// function Successed() {
//  const Toast = ()=>{
//     return(
//        <p className='text-success'><span><img src={SuccesIc} alt="" /></span> login successful</p>
//     )
//  }
//     const notify = () => toast(<Toast/>);
//   return (
//     <div className='toaster'>
//         <button onClick={notify}>Notify!</button>
//         <ToastContainer
//         autoClose={1000}
//         hideProgressBar
//         />
//       </div>
//   )
// }

const Toast = ({message})=>{
   return(
      <p className='text-success flex items-center'>
      <img src={SuccesIc} alt="" className="align-middle" />
      <span className="align-middle ml-2">{message}</span>
  </p>
   )
 }

export default Toast
