import { useEffect, useState } from "react";
import { useGetProducts } from "../../hooks/uploadJewellery";
import { useSubmitPreferenceInfo } from "../../hooks/register";
import { Spinner } from "react-bootstrap";
import Arrow from "../../assets/images/arrow.png";

const Preference = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const [products, setProducts] = useState([]);

  const getProductsMutation = useGetProducts();
  const submitPreferenceInfoMutation = useSubmitPreferenceInfo();
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const data = await getProductsMutation.mutateAsync({ gender: "" });
        console.log("data :>> ", data);
        setProducts(data.responseData);
      } catch (error) {
        // Handle error here
      }
    };
    fetchProducts();
  }, []);

  const JewelryCard = ({ imageSrc, jewelryType, onSelect, isSelected }) => {
    return (
      <div
        className={`bg-white p-4 rounded-md cursor-pointer transition duration-300 transform hover:scale-105 ${
          isSelected
            ? "border-2 border-pink-500 shadow-md"
            : "border-2 border-transparent"
        }`}
        onClick={onSelect}
      >
        <img
          src={imageSrc}
          alt={jewelryType}
          className="w-full h-32 object-cover mb-2 rounded-md"
        />
        <div className="text-center font-semibold">{jewelryType}</div>
      </div>
    );
  };
  const handleCardSelect = (id) => {
    let prods = [...products];
    prods.forEach((item, index) => {
      if (item._id == id) {
        prods[index] = {
          ...prods[index],
          selected: item.selected ? false : true,
        };
      }
    });
    setProducts(prods);
  };

  const handleSubmit = async () => {
    let body = [];
    products.forEach((item) => {
      if (item.selected) {
        body.push(item._id);
      }
    });
    console.log("body :>> ", body);
    await submitPreferenceInfoMutation.mutateAsync({
      preferences: body,
    });
  };

  return (
    <div className="register-form form">
      <div className="form-content-container">
        <div className="form-content">
          <div className="form-heading">
            <h3>What are you dealing with ?</h3>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 h-[600px] overflow-y-auto">
            {!getProductsMutation.isLoading &&
              products.map((item) => (
                <JewelryCard
                  imageSrc={item.sampleImage}
                  jewelryType={item.name}
                  onSelect={() => handleCardSelect(item._id)}
                  isSelected={item.selected}
                />
              ))}
          </div>
          {getProductsMutation.isLoading && (
            <div className="w-full flex justify-center items-center">
              <Spinner animation="border" size="lg" className="me-2" />
            </div>
          )}
          <div>
            <button
              className={
                !submitPreferenceInfoMutation.isLoading
                  ? "primary-btn w-100 p-lg "
                  : "primary-btn w-100 p-lg disable-button"
              }
              disabled={submitPreferenceInfoMutation.isLoading}
              onClick={handleSubmit}
            >
              {submitPreferenceInfoMutation.isLoading ? (
                <>
                  <Spinner animation="border" size="sm" className="me-2" />
                  Loading...
                </>
              ) : (
                <>
                  Next
                  <img src={Arrow} alt="arrow-right" />
                </>
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="form-poster-container form_bg address d-none d-md-flex"></div>
    </div>
  );
};
export default Preference;
