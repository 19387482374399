import React from "react";
import Modal from "react-modal";
import option_ic_close_arrow from "../../../assets/images/icons/option_ic_close_arrow.svg";
import moment from 'moment';

const customStyles = {
  content: {
    top: "55%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%", // For mobile by default
    height: "auto",
    backgroundColor: "white", // Change this to a solid color
  },
  contentMobile: {
    width: "80%",
    height: "60%",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // This is the backdrop
  },
};
const ViewOrderModal = ({ isOpen, closeModal, specificOrderDetails }) => {
  const isMobile = window.innerWidth <= 768;
  const appliedStyles = isMobile
    ? { ...customStyles.content, ...customStyles.contentMobile }
    : customStyles.content;
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={{ ...customStyles, content: appliedStyles }}
    >
      <div className="flex justify-between items-center mb-1">
        <h3 className="font-bold">View Order</h3>
        <button className="text-pink-600">
          <img
            onClick={closeModal}
            src={option_ic_close_arrow}
            alt="Close"
            className="w-4 h-4"
          />
        </button>
      </div>
      <div
        className={`flex flex-col md:flex-row bg-white cursor-pointer transition duration-300 ease-in-out transform `}
      >
        <div className={`md:w-1/5 mt-3 md:mt-5 md:mb-0 flex justify-center md:justify-start`}>
          <img
            src={specificOrderDetails?.images[0]}
            alt={specificOrderDetails?.jewellery?.product_name}
            className="w-40 h-40 object-fill rounded-md"
          />
        </div>
        <div className="md:w-4/5 ">
          <h2 className="text-2xl font-bold text-gray-800 mb-2 pt-1">
            {specificOrderDetails?.jewellery?.product_name || "-"}
          </h2>
          <div className="flex items-center mr-4 md:mb-2">
            <p className="font-bold mr-2 text-base">Description:</p>
            <p className="text-sm">{specificOrderDetails.jewellery?.basic_info?.description || '-'}</p>
          </div>
          <div className="flex flex-wrap pt-1">
          <div className="flex items-center mr-4 md:mb-2">
              <p className="font-bold mr-2 text-base">Creation Date:</p>
              <p className="text-sm">
                {moment(specificOrderDetails?.createdAt).format('YYYY-MM-DD HH:mm:ss') || '-'}
              </p>
            </div>
            <div className="flex items-center mr-4 md:mb-2">
              <p className="font-bold mr-2 text-base">Tag ID:</p>
              <p className="text-sm">
                {specificOrderDetails.jewellery?.tag_id || '-'}
              </p>
            </div>
            <div className="flex items-center mr-4 md:mb-2">
              <p className="font-bold mr-2 text-base">Jewecle ID:</p>
              <p className="text-sm">
                {specificOrderDetails.jewellery?.jewecle_id || '-'}
              </p>
            </div>
          </div>
          <div className="flex flex-wrap  flex-col md:flex-row pt-1">
            <div className="flex items-center mr-4 ">
              <p className="font-bold mr-2 text-base">Gross Weight:</p>
              <p className="text-sm text-pin">
                {`${specificOrderDetails?.jewellery?.product_details
                  ?.gross_weight} grms` || "-"}
              </p>
            </div>
            <div className="flex items-center mr-4 ">
              <p className="font-bold mr-2 text-base">Net Weight:</p>
              <p className="text-sm">
                {`${specificOrderDetails?.jewellery?.product_details?.net_weight} grms` || '-'}
              </p>
            </div>

            <div className="flex items-center mr-4 ">
              <p className="font-bold mr-2 text-base">Purity:</p>
              <p className="text-sm">
                {specificOrderDetails?.jewellery?.product_details?.purity} k
              </p>
            </div>

            <div className="flex items-center mr-4 ">
              <p className="font-bold mr-2 text-base">Size:</p>
              <p className="text-sm">
                {specificOrderDetails?.jewellery?.product_details?.size}
              </p>
            </div>
            <div className="flex items-center mr-4 ">
              <p className="font-bold mr-2 text-base">Color:</p>
              <p className="text-sm">
                {specificOrderDetails?.jewellery?.product_details?.color}
              </p>
            </div>
          </div>
          <div className="flex flex-wrap flex-col md:flex-row pt-1">
            <div className="flex items-center mr-4 md:mt-2">
              <p className="font-bold mr-2 text-base">Wastage:</p>
              <p className="text-sm text-primary-pink font-bold ">
                {specificOrderDetails?.jewellery?.wastage_details?.wastage} %
              </p>
            </div>

            <div className="flex items-center mr-4 md:mt-2 ">
              <p className="font-bold mr-2 text-base">Customize Available:</p>
              <p className="text-sm">
                {specificOrderDetails?.is_customize ? "Yes" : "No"}
              </p>
            </div>
            <div className="flex items-center mr-4 md:mt-2">
              <p className="font-bold mr-2 text-base">Note:</p>
              <p className="text-sm ">
                {specificOrderDetails?.note || '-'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ViewOrderModal;
