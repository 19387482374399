import React from 'react'
import zeroImg from '../../assets/images/zero_img.svg'
function ZeroCharges() {
  return (
    <>
      <section className="zero-charges pb-0">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 flex justify-center">
              <img className='img-fluid' src={zeroImg} alt="" />
            </div>
            <div className="col-md-6">
              <div className="content-zero-charges">
                <h1 className='text-2xl lg:text-4xl'>ab apka har maal <span className='pink-color-text'>dikhega,</span><br />
                  aur har jagah <span className='pink-color-text'>bikega</span></h1>
                <h5 className='text-xl'>We offer you better jewelry options with 0% charges at joining on our platform </h5>

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ZeroCharges
