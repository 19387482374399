import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { Controller, useForm } from "react-hook-form";
import {
  useEditJewellery,
  useMultipleUploadProducts,
  useUploadProducts,
} from "../hooks/uploadJewellery";
import ImageGallery from "react-image-gallery";
import { useAppContext } from "../appContext";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import Toast from "../Components/Toaster/Successed";
function ViewEditProduct({ productData, isView, closeModal, fetchData }) {
  const UploadProductsMutation = useUploadProducts();
  const MupltipleUploadProductMutation = useMultipleUploadProducts();
  const EditJewelleryProductMutation = useEditJewellery();
  const [showGallery, setShowGallery] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (productData && isView) {
      const arr = [];
      productData[0]?.selectedImages.map((image) => {
        arr.push({
          original: image,
          thumbnail: image,
        });
      });
      setImages(arr);
    } else {
      const arr = [];
      setImages(
        productData?.map((item) => {
          item.selectedImages.map((sImage) => {
            arr.push({
              original: sImage.url,
              thumbnail: sImage.url,
            });
          });
        })
      );
      setImages(arr);
    }
  }, [productData]);
  const defaultValues = {
    forms: productData[0].details?.map((data) => ({
      description: data?.description,
      status: data?.status,
      tag_id: data?.tag_id,
      // huid: data?.huid,
      gross_weight: data?.gross_weight,
      net_weight: data?.net_weight,
      width: data?.width,
      length: data?.length,
      breadth: data?.breadth,
      measurement_in: data?.measurement_in,
      purity: data?.purity,
      size: data?.size,
      color: data?.color,
      days: data?.days,
      is_customize: data?.is_customize,
      wastage: data?.wastage,
      discount: data?.discount,
      other_amt: data?.other_amt,
      // total_labour: data?.total_labour,
      final_wastage: data?.final_wastage,
    })),
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    getValues,
  } = useForm({ defaultValues });

  const handleFillDetails = (checked, index) => {
    const watchForm = watch(`forms[${index - 1}]`);
    if (checked) {
      // Fill the values in the second and third forms with the data from the first form
      setValue(`forms[${index}]`, watchForm);
    } else {
      // Clear the values in the second and third forms
      setValue(`forms[${index}]`, {});
    }
  };

  const handleSubmitForm = async (data, event) => {
    const clickedButton = event.nativeEvent.submitter;
    const draft = clickedButton.id === "submit1" ? 1 : 0;
    let finalData;
    if (productData.length == 1) {
      finalData = {
        product_id: productData[0].selectedProduct.product_id,
        product_category: productData[0].selectedProduct.subCategoryId,
        tag_id: data.forms[0].tag_id,
        is_customize: data.forms[0].is_customize === "true",
        basic_info: {
          name: productData[0].selectedProduct.category,
          description: data.forms[0].description,
        },
        wastage_details: {
          wastage: +data.forms[0].wastage,
          discount: +data.forms[0].discount,
          final_wastage: +data.forms[0].final_wastage,
          other_amt: +data.forms[0].other_amt,
          wastage_in_rs: 500,
          // total_labour: +data.forms[0].total_labour,
        },
        product_details: {
          gross_weight: +data.forms[0].gross_weight,
          net_weight: +data.forms[0].net_weight,
          purity: +data.forms[0].purity,
          size: data.forms[0].size ? data.forms[0].size : "",
          color: data.forms[0].color,
          // huid: data.forms[0].huid,
          // status: data.forms[0].status,
          days: data.forms[0].days,
          // width: data.forms[0].width,
          // length: data.forms[0].length,
          // breadth: data.forms[0].breadth,
          //   measurement_in: data.forms[0].measurement_in,
          measurement_in: productData[0].selectedProduct.length,
        },
        images: productData[0].selectedImages.map((item) => item.uploadedUrl),
        manufacturer_detail: "",
        packer_detail: "",
      };
    } else {
      finalData = [];
      for (let i = 0; i < data?.forms?.length; i++) {
        finalData.push({
          product_id: productData[0]?.selectedProduct?.product_id,
          product_category: productData[0]?.selectedProduct?.subCategoryId,
          tag_id: data?.forms[i]?.tag_id,
          is_customize: data?.forms[i]?.is_customize === "true",
          basic_info: {
            name: productData[0]?.selectedProduct?.category,
            description: data?.forms[i]?.description,
          },
          wastage_details: {
            wastage: +data?.forms[i]?.wastage,
            discount: +data?.forms[i]?.discount,
            final_wastage: +data?.forms[i]?.final_wastage,
            other_amt: +data?.forms[i]?.other_amt,
            wastage_in_rs: 500,
            // total_labour: +data.forms[i].total_labour,
          },
          product_details: {
            gross_weight: +data?.forms[i]?.gross_weight,
            net_weight: +data?.forms[i]?.net_weight,
            purity: +data?.forms[i]?.purity,
            size: data?.forms[i]?.size ? data?.forms[i]?.size : "",
            color: data?.forms[i]?.color,
            // huid: data.forms[i].huid,
            // status: data.forms[i].status,
            days: data?.forms[i]?.days,
            // width: data.forms[i].width,
            // length: data.forms[i].length,
            // breadth: data.forms[i].breadth,
            // measurement_in: data.forms[i].measurement_in,
            measurement_in: productData[0]?.selectedProduct?.length,
          },
          images: productData[i]?.selectedImages.map(
            (item) => item.uploadedUrl
          ),
          manufacturer_detail: "",
          packer_detail: "",
        });
      }
      fetchData(1, 1);
    }
    if (data?.forms?.length == 1) {
      if (productData[0]?.details && productData[0]?.details[0]?._id) {
        await EditJewelleryProductMutation.mutate({
          data: finalData,
          id: productData[0]?.details[0]?._id,
        });
      } else {
        await UploadProductsMutation.mutate({ data: finalData, draft: draft });
      }
    } else {
      await MupltipleUploadProductMutation.mutate({
        data: finalData,
        draft: draft,
      });
    }
    fetchData(1, 20);
  };

  useEffect(() => {
    if (EditJewelleryProductMutation.data) {
      closeModal();
      toast(<Toast message={EditJewelleryProductMutation.data.message} />);
    }
  }, [EditJewelleryProductMutation.data]);

  // Function to open the gallery on image click
  const openGallery = (index) => {
    setShowGallery(true);
    setCurrentIndex(index);
  };

  // Function to close the gallery
  const closeGallery = () => {
    setShowGallery(false);
  };

  // Function to calculate final wastage percentage
  const calculateFinalWastage = (index) => {
    const wastagePercentage = parseFloat(
      getValues(`forms[${index}].wastage`) || 0
    );
    const discountPercentage = parseFloat(
      getValues(`forms[${index}].discount`) || 0
    );
    return wastagePercentage - discountPercentage;
  };

  const calculateTotalEarning = (index) => {
    const liveRate = 6000;
    const finalWastagePercentage =
      parseFloat(getValues(`forms[${index}].final_wastage`) || 0) / 100;
    const grossWeight = parseFloat(
      getValues(`forms[${index}].gross_weight`) || 0
    );
    const otherAmt = parseFloat(getValues(`forms[${index}].other_amt`) || 0);

    return liveRate * finalWastagePercentage * grossWeight + otherAmt;
  };

  return (
    <>
      <div>
        {showGallery ? (
          <>
            <div className="relative h-screen">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8 absolute top-8 left-8 cursor-pointer text-white z-10"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#F64668"
                onClick={closeGallery}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
              <ImageGallery
                items={images}
                showFullscreenButton={true}
                showPlayButton={false}
                startIndex={currentIndex}
                showIndex={true}
                showNav={true}
                additionalClass="image-gallery"
                onRequestClose={closeGallery}
              />
            </div>
          </>
        ) : (
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            <div className="">
              {productData?.map((data, index) => (
                <>
                  <div className="">
                    <p className="font-bold text-lg">
                      {data?.selectedProduct?.category}{" "}
                      {data?.selectedProduct?.subcategory?.name}
                    </p>
                    {index >= 1 && (
                      <div className="flex justify-between items-center">
                        <div className="input-field w-4 h-4">
                          <input
                            id="terms_condition"
                            type={"checkbox"}
                            placeholder="Create your password"
                            name="registerTermsCondition"
                            onChange={(e) =>
                              handleFillDetails(e.target.checked, index)
                            }
                            className="pink-color-text"
                          />
                        </div>
                        <label
                          htmlFor="terms_condition"
                          className="pink-color-text pt-2 pl-2"
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          fill details as above
                        </label>
                      </div>
                    )}
                  </div>
                  <div className="md:flex">
                    {/* Left Part */}
                    <div className="md:w-44">
                      <p className="font-bold text-sm flex justify-center items-start ">
                        {data?.selectedProduct?.title}
                      </p>
                      <div className="flex justify-center">
                        <img
                          src={
                            isView
                              ? data?.selectedImages[0]
                              : data?.selectedImages[0]?.url
                          }
                          className="cursor-pointer rounded-lg mt-1 w-28 h-28 object-cover "
                          alt=""
                          onClick={() => openGallery(index)}
                        />
                      </div>
                    </div>
                    {/* Right Part */}
                    <div className="md:w-full md:pl-8 ">
                      <div className="flex flex-wrap md:gap-4">
                        <div className="w-1/2 md:w-16 pt-2">
                          <div className="input-container">
                            <label className="font-bold text-sm">Tag ID</label>
                            <div className="input-field">
                              <input
                                disabled={isView}
                                type="text"
                                className="w-full p-1 border border-gray-400 rounded-md text-sm"
                                name={`forms[${index}].tag_id`}
                                {...register(`forms[${index}].tag_id`, {
                                  // required: "Please enter tag id",
                                })}
                              />
                            </div>
                          </div>
                          {errors.forms?.[index]?.tag_id && (
                            <p className="text-red-500  text-sm">
                              {errors.forms?.[index].tag_id.message}
                            </p>
                          )}
                        </div>
                        <div className="w-1/2 md:w-24 pl-1 md:ml-2 pt-2">
                          <div className="input-container">
                            <label className="font-bold text-sm">
                              Purity
                              <span className="text-red-600 ml-1">*</span>
                            </label>
                            <div className="input-field">
                              <select
                                disabled={isView}
                                className="w-full p-1 border border-gray-400 rounded-md text-sm"
                                {...register(`forms[${index}].purity`, {
                                  required: "Please select a purity",
                                })}
                              >
                                <option value="18">18K</option>
                                <option value="20">20K</option>
                                <option value="22">22K</option>
                                <option value="24">24K</option>
                              </select>
                            </div>
                          </div>
                          {errors.forms?.[index]?.purity && (
                            <p className="pink-color-text text-lg">
                              {errors.forms?.[index].purity.message}
                            </p>
                          )}
                        </div>
                        <div className="input-container w-1/2 md:w-32 md:ml-2 pt-2">
                          <label className="font-bold text-sm">
                            Gross Weight
                            <span className="text-red-600 ml-1">*</span>
                          </label>
                          <div className="input-field">
                            <Controller
                              name={`forms[${index}].gross_weight`}
                              control={control}
                              rules={{
                                required: "Please enter gross weight",
                                pattern: {
                                  value: /^[0-9]+(\.[0-9]+)?$/, // Updated regex to allow float numbers
                                  message: "Please enter a valid number",
                                },
                              }}
                              render={({ field }) => (
                                <input
                                  disabled={isView}
                                  type="text"
                                  className="w-full p-1 border border-gray-400 rounded-md text-sm"
                                  {...field}
                                  onChange={(e) => {
                                    field.onChange(e);
                                    const finalWastage =
                                      calculateFinalWastage(index);
                                    const totalEarning =
                                      calculateTotalEarning(index);
                                    setValue(
                                      `forms[${index}].total_labour`,
                                      totalEarning
                                    );
                                    setValue(
                                      `forms[${index}].final_wastage`,
                                      finalWastage
                                    );
                                  }}
                                />
                              )}
                            />
                          </div>
                          {errors.forms?.[index]?.gross_weight && (
                            <p className="text-red-500 text-sm">
                              {errors.forms?.[index].gross_weight.message}
                            </p>
                          )}
                        </div>
                        <div className="input-container md:ml-2 w-1/2 md:w-32 pl-1 pt-2">
                          <label className="font-bold text-sm">
                            Net weight
                            <span className="pink-color-text ml-1">*</span>
                          </label>
                          <div className="input-field">
                            <input
                              disabled={isView}
                              type="text"
                              className="w-full p-1 border border-gray-400 rounded-md text-sm" // Same class applied here
                              name={`forms[${index}].net_weight`}
                              {...register(`forms[${index}].net_weight`, {
                                required: "Please enter net weight",
                              })}
                            />
                          </div>
                          {errors.forms?.[index]?.net_weight && (
                            <p className="text-red-500 text-sm">
                              {errors.forms?.[index].net_weight.message}
                            </p>
                          )}
                        </div>
                        {data?.selectedProduct?.length && (
                          <div className="input-container w-1/2 md:w-32 md:ml-3 pt-2">
                            <label className="font-bold text-sm">
                              Size
                              <span className="pink-color-text ml-1">*</span>
                            </label>
                            <div className="input-field">
                              <select
                                disabled={isView}
                                name={`forms[${index}].size`}
                                {...register(`forms[${index}].size`, {
                                  required: "Please enter size",
                                })}
                                className="w-full p-1 border border-gray-400 rounded-md text-sm" // Same class applied here
                              >
                                {data?.selectedProduct?.lengthValue?.map(
                                  (value) => (
                                    <option
                                      value={value}
                                    >{`${value} ${data?.selectedProduct?.length}`}</option>
                                  )
                                )}
                              </select>
                            </div>
                            {errors.forms?.[index]?.size && (
                              <p className="text-red-500 text-lg">
                                {errors.forms?.[index].size.message}
                              </p>
                            )}
                          </div>
                        )}
                        <div className=" pl-1 pt-2 w-1/2 md:w-32">
                          <div className="input-container">
                            <label className="font-bold text-sm">
                              Color
                              <span className="text-red-600 ml-1">*</span>
                            </label>
                            <div key={index} className="color-wrapper">
                              <div className="input-field">
                                <select
                                  disabled={isView}
                                  name={`forms[${index}].color`}
                                  {...register(`forms[${index}].color`, {
                                    required: "Please enter color",
                                  })}
                                  className="w-full p-1 border border-gray-400 rounded-md text-sm" // Same class applied here
                                >
                                  <option value="Rose Gold">Rose Gold</option>
                                  <option value="White Gold">Platinum</option>
                                  <option value="Yellow">Gold</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          {errors.forms?.[index]?.color && (
                            <p className="text-red-500 text-lg">
                              {errors.forms?.[index].color.message}
                            </p>
                          )}
                        </div>
                        <div className="input-container w-1/2 md:w-28 pt-2">
                          <label className="font-bold text-sm">
                            Wastage Per
                            <span className="pink-color-text">*</span>
                          </label>
                          <div className="input-field">
                            <Controller
                              name={`forms[${index}].wastage`}
                              control={control}
                              rules={{
                                required: "Please enter wastage",
                                pattern: {
                                  value: /^[1-9]\d*(\.\d+)?|0(\.\d+)?$/,
                                  message: "Please enter a valid number",
                                },
                              }}
                              render={({ field }) => (
                                <input
                                  disabled={isView}
                                  type="text"
                                  {...field}
                                  className="w-full p-1 border border-gray-400 rounded-md text-sm text-primary-pink font-bold"
                                  onChange={(e) => {
                                    field.onChange(e);
                                    const finalWastage =
                                      calculateFinalWastage(index);
                                    const totalEarning =
                                      calculateTotalEarning(index);
                                    setValue(
                                      `forms[${index}].total_labour`,
                                      totalEarning
                                    );
                                    setValue(
                                      `forms[${index}].final_wastage`,
                                      finalWastage
                                    );
                                  }}
                                />
                              )}
                            />
                          </div>
                          {errors.forms?.[index]?.wastage && (
                            <p className="text-red-500 text-sm">
                              {errors.forms?.[index].wastage.message}
                            </p>
                          )}
                        </div>
                        <div className="input-container md:ml-2 w-1/2 md:w-28 pl-1 pt-2">
                          <label className="font-bold text-sm">
                            Other charges
                          </label>
                          <div className="input-field">
                            <Controller
                              name={`forms[${index}].other_amt`}
                              control={control}
                              render={({ field }) => (
                                <input
                                  disabled={isView}
                                  className="w-full p-1 border border-gray-400 rounded-md text-sm"
                                  type="text"
                                  {...field}
                                  onChange={(e) => {
                                    field.onChange(e);
                                    const finalWastage =
                                      calculateFinalWastage(index);
                                    const totalEarning =
                                      calculateTotalEarning(index);
                                    setValue(
                                      `forms[${index}].total_labour`,
                                      totalEarning
                                    );
                                    setValue(
                                      `forms[${index}].final_wastage`,
                                      finalWastage
                                    );
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="input-container md:ml-4 md:mt-1 pt-2">
                          <label className="font-bold text-sm">
                            Customization available?*
                          </label>
                          <div className="input-radio md:mt-1">
                            <div className="multiple-radio pb-0 flex">
                              <div className="radio-group">
                                <input
                                  disabled={isView}
                                  type="radio"
                                  id={`is_customize${index}_1`}
                                  value={true}
                                  name={`forms[${index}].is_customize`}
                                  {...register(`forms[${index}].is_customize`, {
                                    required: "Please select customization",
                                  })}
                                  defaultChecked={
                                    defaultValues?.forms &&
                                    defaultValues?.forms[index].is_customize ===
                                      true
                                  }
                                />
                                <label htmlFor={`is_customize${index}_1`}>
                                  Yes
                                </label>
                              </div>
                              <div className="radio-group ml-2">
                                <input
                                  disabled={isView}
                                  type="radio"
                                  id={`is_customize${index}_2`}
                                  value={false}
                                  name={`forms[${index}].is_customize`}
                                  {...register(`forms[${index}].is_customize`, {
                                    required: "Please select customization",
                                  })}
                                  defaultChecked={
                                    defaultValues?.forms &&
                                    defaultValues?.forms[index].is_customize ===
                                      false
                                  }
                                />
                                <label htmlFor={`is_customize${index}_2`}>
                                  No
                                </label>
                              </div>
                            </div>
                            {errors.forms?.[index]?.is_customize && (
                              <p className="text-red-500 text-sm">
                                {errors.forms?.[index].is_customize.message}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="input-container pt-2">
                          <label className="font-bold text-sm">
                            Order Ready
                          </label>
                          <div className="input-field order-days">
                            <input
                              disabled={isView}
                              type="text"
                              className="w-20 p-1 border border-gray-400 rounded-md text-sm" // Apply consistent class
                              name={`forms[${index}].days`}
                              {...register(`forms[${index}].days`, {
                                required: "Please enter days",
                              })}
                            />
                            <p className="mt-1 font-bold text-sm">days*</p>{" "}
                            {/* Adjust the position of days text */}
                          </div>
                          {errors.forms?.[index]?.days && (
                            <p className="text-red-500 text-sm">
                              {errors.forms?.[index].days.message}
                            </p>
                          )}
                        </div>
                        <div className="input-container w-32 pt-2">
                          <label className="font-bold text-sm whitespace-nowrap">
                            Any other information
                          </label>
                          <div className="input-field order-days">
                            <input
                              disabled={isView}
                              type="text"
                              className="w-full p-1 border border-gray-400 rounded-md text-sm" // Apply consistent class
                              name={`forms[${index}].days`}
                              {...register(`forms[${index}].days`, {
                                required: "Please enter days",
                              })}
                            />
                            {/* Adjust the position of days text */}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-wrap md:mt-6 md:gap-4">
                        {/* <textarea
                              disabled={isView}
                              className="border border-gray-400 rounded-md mt-2 p-2 w-56 ml-2 text-sm"
                              placeholder="Enter description"
                              name={`forms[${index}].description`}
                              {...register(`forms[${index}].description`, {
                                required: "Description is required",
                                validate: (value) =>
                                  value.length <= 250 ||
                                  "Description should not exceed 250 characters",
                              })}
                            />
                            {errors.forms?.[index]?.description && (
                              <p className="text-red-500 text-lg">
                                {errors.forms?.[index].description.message}
                              </p>
                            )} */}
                      </div>
                    </div>
                  </div>
                </>
              ))}

              <div className="flex justify-between items-center mt-4">
                {!isView && (
                  <>
                    <button
                      id="submit1"
                      className="ml-2 px-4 py-3 border-2 border-jw-golden rounded-md text-jw-golden  flex justify-center items-center"
                      type="submit"
                    >
                      Draft
                    </button>
                    <button
                      id="submit2"
                      className="ml-2 px-4 py-3 border-2 border-jw-golden rounded-md text-jw-golden bg-[#F9E8B1] flex justify-center items-center"
                      type="submit"
                    >
                      {EditJewelleryProductMutation.isLoading ||
                      MupltipleUploadProductMutation.isLoading ||
                      UploadProductsMutation.isLoading ? (
                        <>
                          <Spinner
                            animation="border"
                            size="sm"
                            className="me-2"
                          />
                          Loading...
                        </>
                      ) : (
                        <>Ready To Check</>
                      )}
                    </button>
                  </>
                )}
              </div>
            </div>
          </form>
        )}
      </div>
    </>
  );
}

export default ViewEditProduct;
