import React from 'react'
import Banner from '../../Components/Banner/Brand/Banner'
import bannerPoster  from '../../assets/images/dukan_to_brand_img.jpg';
import Support from '../../Components/Support/Support';
import Advertisements from '../../Components/FrontView/Advertisements';
import DigitalExhibition from '../../Components/FrontView/DigitalExhibition';
 
function DukanToBrand() {
  return (
   <>
     {/* <Header /> */}
       <Banner
       Pinkfirst='dukan'
        title='sirf ek jagah khadi'
        Breaktitle='rahegi, or'
        Pinktitle='brand'
        lasttitle='har jagah'
        desc='risky travelling karke kitna tension loge, phir bhi '
        breakDesc='pura maal to koi nai dekhta.'
        bannerImage={bannerPoster}
        />
        <Advertisements/>
        <DigitalExhibition/>
        <Support/>
   </>
  )
}

export default DukanToBrand
