import React, { useEffect, useState } from 'react';
import Arrow from '../../assets/images/arrow.png';
import BackArrow from '../../assets/images/icons/back_arrow.svg';
import Select from "react-select";
import { State, City } from 'country-state-city';
import { useForm } from "react-hook-form";
import { useSubmitPickupAddress } from '../../hooks/register';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function Address() {
const navigate = useNavigate()

//Mutation hooks
const SubmitPickupAddressMutation = useSubmitPickupAddress();

  //Use state
  const [selectedState, setSelectedState] = useState("GJ");
  const [selectedCity, setSelectedCity] = useState("Abrama");
  const [stateOptions,] = useState(State.getStatesOfCountry('IN').map((state) => ({
    value: state.isoCode,
    label: state.name
  })));
  const [cityOptions, setCityOptions] = useState(City.getCitiesOfState('IN', 'GJ'));

  // get list of cities for selected state
  const handleStateChange = (selectedState) => {
    let cityList = City.getCitiesOfState('IN', selectedState.target.value)
    setSelectedCity(cityList[0].name);
    setCityOptions(cityList);
    setSelectedState(selectedState.target.value);
  };

  const handleCityChange = (selectedCity) => {
    setSelectedCity(selectedCity.target.value)
  }

  //use form
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const filteredState = stateOptions.find(state => state.value == selectedState);
    const params = {
      "shop_no": data.shop_no,
      "street": data.street,
      "landmark": data.landmark,
      "pincode": data.pincode,
      "city": selectedCity,
      "state": filteredState?.label
    }
    SubmitPickupAddressMutation.mutate(params);
  };
  // useEffect(() => {
  //   if(localStorage.getItem('city')){
  //     setSelectedState(localStorage.getItem('city'))
  //   }
  // }, [localStorage.getItem('city')])
  return (
    <>
      <div className="register-form form">
        <div className='form-content-container'>
          {/* <button className='back-btn' onClick={() => {
            navigate("/admin/supplier/sign-up-business");
          }}> <img src={BackArrow} alt="" /></button> */}
          <form onSubmit={handleSubmit(onSubmit)}>
          <div className='form-content'>
            <div className='form-heading'>
              <h3>Address</h3>
              {/* <ul className='form_step'>
                <li></li>
                <li className='active'></li>
                <li></li>
              </ul> */}
            </div>
            <div className='input-container'>
              <div className='input-title'>
                <p>
                  <label>Shop No.<span>*</span></label>
                </p>
              </div>
              <div className='input-field'>
                <input placeholder='Enter shop number'
                  name="shop_no"
                  {...register("shop_no", {
                    required: "Shop number is required",
                  })}
                  Value={localStorage?.getItem('shop_no')}
                />
              </div>
              {errors.shop_no && <p className="text-danger">{errors.shop_no.message}</p>}
            </div>
            <div className='input-container'>
              <div className='input-title'>
                <p>
                  <label>Street<span>*</span></label>
                </p>
              </div>
              <div className='input-field'>
                <input placeholder='Enter Street' name="street" {...register("street", {
                    required: "street is required",

                  })}
                  Value={localStorage?.getItem('street')}
                  />
              </div>
              {errors.street && <p className="text-danger">{errors.street.message}</p>}
            </div>
            <div className='input-container'>
              <div className='input-title'>
                <p>
                  <label>Landmark</label>
                </p>
              </div>
              <div className='input-field'>
                <input placeholder='Enter Landmark' name="landmark" {...register("landmark")} 
                  Value={localStorage?.getItem('landmark')}
                />
              </div>
              {errors.landmark && <p className="text-danger">{errors.landmark.message}</p>}
            </div>
            <div className='input-container'>
              <div className='input-title'>
                <p>
                  <label>Pincode<span>*</span></label>
                </p>
              </div>
              <div className='input-field'>
                <input placeholder='Enter Pincode' name="pincode" {...register("pincode", {
                    required: "Pincode is required",
                    pattern: {
                      value: /^[1-9][0-9]{5}$/,
                      message: "Invalid pincode"
                    }
                  })}
                  Value={localStorage?.getItem('pincode')}
                  />
              </div>
              {errors.pincode && <p className="text-danger">{errors.pincode.message}</p>}
            </div>
            <div className='input-container'>
              <div className='input-title'>
                <p>
                  <label>State<span>*</span></label>
                </p>
              </div>
              <div className='input-field'>
                {/* <input type={'password'} placeholder='Enter state' /> */}
                <select value={selectedState} onChange={handleStateChange} 
                // name="state" {...register("state", {
                //     required: "State is required"
                //   })}
                  >
                  {
                    stateOptions.map((state) => (
                      <option value={state.value}>{state.label}</option>
                    ))
                  }
                </select>
              </div>
            </div>
            <div className='input-container'>
              <div className='input-title'>
                <p>
                  <label>City<span>*</span></label>
                </p>
              </div>
              <div className='input-field' name="city" >
                {/* <input type={'select'} placeholder='Your email'/> */}
                <select value={selectedCity} onChange={handleCityChange}>
                  {
                    cityOptions.map((city) => (
                      <option value={city.name}>{city.name}</option>
                    ))
                  }
                </select>
              </div>
            </div>

            <div>
              <button
                  type='submit'
                  className={!SubmitPickupAddressMutation.isLoading  ? 'primary-btn w-100 p-lg ' : 'primary-btn w-100 p-lg disable-button'}
                  disabled={SubmitPickupAddressMutation.isLoading }
                >
                  {SubmitPickupAddressMutation.isLoading ? (
                    <>
                      <Spinner animation="border" size="sm" className="me-2" />
                      Loading...
                    </>
                  ) : (
                    <>
                      Next
                      <img src={Arrow} alt="arrow-right" />
                    </>
                  )}
                </button>
            </div>
          </div>
          </form>
        </div>
        <div className='form-poster-container form_bg address d-none d-md-flex'>

        </div>
      </div>
    </>
  )
}

export default Address
