import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Controller, useForm } from "react-hook-form";
import { RxCrossCircled } from "react-icons/rx";
import ViewEditProduct from "../Components/viewEditProduct";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../Components/Loader/loaderv2";

const customStyles = {
  content: {
    top: "55%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "61%", // For mobile by default
    height: "auto",
    backgroundColor: "white", // Change this to a solid color
  },
  contentMobile: {
    width: "80%",
    height: "60%",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // This is the backdrop
  },
};
const ViewProductModal = ({
  isOpen,
  closeModal,
  productDetails,
  isLoading,
  fetchData,
  pageNo,
  perPage
}) => {
  const location = useLocation();
  const isMobile = window.innerWidth <= 768;
  const appliedStyles = isMobile
    ? { ...customStyles.content, ...customStyles.contentMobile }
    : customStyles.content;
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={{ ...customStyles, content: appliedStyles }}
    >
      <div className="overflow-hidden">
        <div className="flex justify-between items-center mb-1">
          {
            !isLoading && <>
              <h3 className="font-bold text-pink-600">{productDetails?.isView ? 'View': 'Edit'} Product</h3><button className="text-pink-600 text-2xl" onClick={closeModal}>
            <RxCrossCircled />
          </button>
            </>
          }
          
          
        </div>
        <div
          className={`flex flex-col md:flex-row bg-white shadow-md cursor-pointer transition duration-300 ease-in-out transform p-2 `}
        >
          {isLoading ? (
            <div className="flex items-center justify-center h-full w-full">
              <div className="loader ease-linear rounded-full border-4 border-t-4 border-primary-200 h-12 w-12"></div>
            </div>
          ) : (
            productDetails && (
              <ViewEditProduct
                productData={productDetails?.data}
                isView={productDetails?.isView}
                closeModal={closeModal}
                fetchData={fetchData}
                pageNo={pageNo}
                perPage={perPage}
              />
            )
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ViewProductModal;
