import React from 'react'
import Modal from 'react-modal';
const customStyles = {
    content: {
        top: '55%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '50%', // For mobile by default
        height: 'auto',
        backgroundColor: 'white', // Change this to a solid color
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // This is the backdrop
    }
};
const DeleteConfirmationModal = ({ isOpen, closeModal, handleDeleteConfirmation, id, title }) => {
    
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            style={customStyles}
            // className='w-1/2 md:w-1/3'
        >
            <h4 className="text-lg md:text-2xl  text-center mb-4">{title}</h4>
            <div className="flex justify-between">
                <button
                    className=" text-white px-4 py-2 rounded"
                    onClick={() => handleDeleteConfirmation(id)}
                    style={{
                        backgroundColor: '#EE3B61'
                    }}
                >
                    Yes
                </button>
                <button
                    className="bg-gray-500 text-white px-4 py-2 rounded"
                    onClick={closeModal}
                >
                    No
                </button>
            </div>
        </Modal>
    )
}

export default DeleteConfirmationModal