import { useMutation } from "react-query";
import axios from "axios";
import { toast } from "react-toastify";
import ToastFailed from "../Components/Toaster/Failed";
import Toast from "../Components/Toaster/Successed";
import { useNavigate } from "react-router-dom";

const API_URL = process.env.REACT_APP_BASE_URL;

export const useImageUpload = (uploadedLink, setUploadedLink) => {
  const imageUpload = useMutation(
    async (data) => {
      const response = await axios.post(`${API_URL}/user/upload`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    },
    {
      onSuccess: async (data) => {
        // toast(<Toast message={data.message}/>)
        // onSuccessCallback();
        if (uploadedLink) {
          await setUploadedLink([...uploadedLink, data.responseData]);
        }
      },
      onError: (error) => {
        if (Array.isArray(error.response.data.message)) {
          error.response.data.message.forEach((element) => {
            toast(<ToastFailed message={element} />);
          });
        } else {
          toast(<ToastFailed message={error.response.data.message} />);
        }
      },
    }
  );

  return imageUpload;
};

export const useGetCategorySubcategory = () => {
  const getCategories = useMutation(
    async () => {
      const response = await axios.get(`${API_URL}/product/SipplierProduct`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
          accept: "*/*",
        },
      });
      return response.data;
    },
    {
      onSuccess: async (data) => {},
      onError: (error) => {
        if (Array.isArray(error.response.data.message)) {
          error.response.data.message.forEach((element) => {
            toast(<ToastFailed message={element} />);
          });
        } else {
          toast(<ToastFailed message={error.response.data.message} />);
        }
      },
    }
  );

  return getCategories;
};

export const usePasswordReset = () => {
  const resetPassword = useMutation(
    async (data) => {
      const response = await axios.post(
        `${API_URL}/user/reset-password`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    },
    {
      onSuccess: async (data) => {
        // toast(<Toast message={data.message}/>)
        // onSuccessCallback();
      },
      onError: (error) => {
        if (Array.isArray(error.response.data.message)) {
          error.response.data.message.forEach((element) => {
            toast(<ToastFailed message={element} />);
          });
        } else {
          toast(<ToastFailed message={error.response.data.message} />);
        }
      },
    }
  );

  return resetPassword;
};

export const useForgotPassword = () => {
  const navigate = useNavigate();

  const forgotPassword = useMutation(
    async (data) => {
      const response = await axios.post(
        `${API_URL}/user/forgot-password`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    },
    {
      onSuccess: async (data) => {
        toast(
          <Toast
            message={
              "Password reset link sent successfully on your registered email address."
            }
          />
        );
        navigate("/login");
      },
      onError: (error) => {
        if (Array.isArray(error.response.data.message)) {
          error.response.data.message.forEach((element) => {
            toast(<ToastFailed message={element} />);
          });
        } else {
          toast(<ToastFailed message={error.response.data.message} />);
        }
      },
    }
  );

  return forgotPassword;
};
