import React from 'react'
import WhyIc1 from '../../assets/images/icons/why_Ic1.svg'
import WhyIc2 from '../../assets/images/icons/why_Ic2.svg'
import WhyIc3 from '../../assets/images/icons/why_Ic3.svg'
import WhyIc4 from '../../assets/images/icons/why_Ic4.svg'
import WhyIc5 from '../../assets/images/icons/why_Ic5.svg'
import WhyIc6 from '../../assets/images/icons/why_Ic6.svg'



function WhyJwecle() {
    return (
        <>
            <section className="why-jwecle pt-0">
                <div className="container">
                    <div className="heading-box pb-6 pt-3">
                        <h2 className='text-4xl'>Why <psan className='pink-color-text '>Jewecle</psan></h2>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-4 mb-4 mb-md-0">
                            <div className="why-box">
                                <div className="icon-box">
                                    <img src={WhyIc1} alt="" />
                                </div>
                                <h5>“0” Subscription<br /> charge to join<br /> jewecle</h5>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 mb-4 mb-md-0">
                            <div className="why-box">
                                <div className="icon-box">
                                    <img src={WhyIc2} alt="" />
                                </div>
                                <h5>More than 35<br /> jewellery categories<br /> to sell</h5>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 mb-4 mb-md-0">
                            <div className="why-box">
                                <div className="icon-box">
                                    <img src={WhyIc3} alt="" />
                                </div>
                                <h5>Every retiler will<br /> know<br /> who you are </h5>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 mb-4 mb-md-0">
                            <div className="why-box">
                                <div className="icon-box">
                                    <img src={WhyIc4} alt="" />
                                </div>
                                <h5>Now retailers can<br /> reach you just by<br /> single click</h5>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 mb-4 mb-md-0">
                            <div className="why-box">
                                <div className="icon-box">
                                    <img src={WhyIc5} alt="" />
                                </div>
                                <h5>no need to go shop<br /> by shop like<br /> a sales person</h5>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 mb-4 mb-md-0">
                            <div className="why-box">
                                <div className="icon-box">
                                    <img src={WhyIc6} alt="" />
                                </div>
                                <h5>Jewecle makes your<br /> each design visible<br /> to every customer</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default WhyJwecle
