import React, { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FaRegEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa6";

// Images
import Logo from "../../assets/images/jeweclelogo.png";
import RegImgs from "../../assets/images/reg_img.png";
import Arrow from "../../assets/images/arrow.png";
import BackArrow from "../../assets/images/icons/back_arrow.svg";
import {
  useSubmitBasicInfo,
  useVerifyOtp,
  useVerifyPhone,
} from "../../hooks/register";
import Spinner from "react-bootstrap/Spinner";
import Loader from "../../Components/Loader/Loader";

function Register() {
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  //useStates
  const [phone, setPhone] = useState("");
  const [isMobileError, setIsMobileError] = useState("");
  const [verifyOtpLoading, setVerifyOtpLogin] = useState(false);
  const [disableInput, setDisable] = useState(false);

  //Mutation hooks
  const verifyPhoneMutation = useVerifyPhone();
  const verifyOtpMutation = useVerifyOtp();
  const submitBasicInfoMutation = useSubmitBasicInfo();

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    submitBasicInfoMutation.mutate({
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      email: data.email,
      password: data.password,
      is_update: false,
      type: "SUPPLIER",
    });
  };

  const handleBackArrow = () => {
    navigate("/login");
  };

  const handleSendOtp = () => {
    console.log("phone.length !== 10", phone.length !== 10);
    if (!phone) {
      setIsMobileError("Phone number is required");
    } else if (phone.length !== 10) {
      setIsMobileError("Phone number length should be 10");
    } else {
      verifyPhoneMutation.mutate({ phone: phone, is_login: false });
      setIsMobileError("");
    }
  };

  const handleVerifyOtp = (e) => {
    if (e.target.value.length == 4) {
      verifyOtpMutation.mutate({
        otp: e.target.value,
        phone: phone,
      });
    }
  };

  const handleMobileNumber = (e) => {
    console.log("first", e.target.value.length);
    if (e.target.value === "") {
      setIsMobileError("Phone number is required");
      setPhone(e.target.value);
    } else if (e.target.value.length !== 10) {
      setIsMobileError("Phone number length should be 10");
      setPhone(e.target.value);
    } else if (e.target.value.length === 10) {
      setIsMobileError("");
      setPhone(e.target.value);
    } else {
      setPhone(e.target.value);
    }
  };

  const handleKeyPress = (event) => {
    const regex = /^[0-9\b]+$/; // Regex to accept only numbers and backspace
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (verifyOtpMutation.isLoading) {
      setVerifyOtpLogin(verifyOtpMutation.isLoading);
    }
  }, [verifyOtpMutation.isLoading]);

  useEffect(() => {
    if (verifyOtpLoading) {
      setTimeout(() => {
        setVerifyOtpLogin(false);
      }, 2000);
    }
  }, [verifyOtpLoading]);

  useEffect(() => {
    if (!verifyPhoneMutation.isSuccess) {
      setDisable(true);
    }
    if (location?.state?.mobileNumber) {
      setValue("phone", location?.state?.mobileNumber);
      setPhone(location?.state?.mobileNumber);
      setDisable(true);
    }
  }, [verifyPhoneMutation.isSuccess || location?.state?.mobileNumber]);

  return (
    <>
      <div className="register-form form">
        <div className="form-content-container">
          <button
            className="back-btn"
            type="button"
            onClick={() => handleBackArrow()}
          >
            <img src={BackArrow} alt="" />
          </button>
          <div className="flex flex-col items-start">
            <img className="w-32 lg:w-48 mb-4" src={Logo} alt="site-logo" />
          </div>
          {(verifyPhoneMutation?.isLoading || verifyOtpMutation.isLoading) && (
            <Loader />
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-content">
              <div className="form-heading">
                <h3>Welcome to Jewecle!</h3>
                <p>
                  Already have an account?
                  <Link className="pink-color-link" to="/login">
                    Log in
                  </Link>
                </p>
              </div>

              <div className="input-container">
                <div className="input-title">
                  <p>
                    <label>
                      Mobile Number<span>*</span>
                    </label>
                  </p>
                </div>
                <div className="input-field">
                  <input
                    type="tel"
                    maxLength="10"
                    placeholder="Your mobile number"
                    name="phone"
                    {...register("phone", {
                      required: "Mobile number is required",
                      minLength: {
                        value: 10,
                        message: "Mobile number must have at least 10 digits",
                      },
                    })}
                    onChange={(e) => handleMobileNumber(e)}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <button
                    type="button"
                    className={
                      verifyPhoneMutation.isLoading
                        ? "primary-btn  disable-button"
                        : "primary-btn "
                    }
                    onClick={handleSendOtp}
                    disabled={
                      !phone && verifyPhoneMutation.isLoading ? true : false
                    }
                  >
                    {verifyPhoneMutation.isLoading ? (
                      <>
                        <Spinner
                          animation="border"
                          size="sm"
                          className="me-2"
                        />
                        Loading...
                      </>
                    ) : (
                      "Send OTP"
                    )}
                  </button>
                </div>
                {isMobileError && (
                  <span className="text-danger">{isMobileError}</span>
                )}
              </div>
              <div className="input-container">
                <div className="input-title">
                  <p>
                    <label>
                      OTP<span>*</span>
                    </label>
                  </p>
                </div>
                <div
                  className={
                    !verifyPhoneMutation.isSuccess &&
                    !location?.state?.mobileNumber
                      ? "input-field disable-input"
                      : "input-field"
                  }
                >
                  <input
                    placeholder="Enter OTP"
                    name="registerOTP"
                    type="text"
                    pattern="\d{4}"
                    {...register("registerOTP", {
                      required: "OTP is required",
                      valueAsNumber: true,
                    })}
                    maxLength={4}
                    onKeyPress={handleKeyPress}
                    onChange={handleVerifyOtp}
                    disabled={
                      !verifyPhoneMutation.isSuccess &&
                      !location?.state?.mobileNumber
                        ? true
                        : false
                    }
                  />
                </div>
              </div>
              {errors.registerOTP && (
                <p className="text-danger">{errors.registerOTP.message}</p>
              )}
              <div className="multiple-inputs">
                <div className="input-container">
                  <div className="input-title">
                    <p>
                      <label>
                        First name<span>*</span>
                      </label>
                    </p>
                  </div>
                  <div
                    className={
                      verifyOtpMutation.isSuccess
                        ? "input-field"
                        : "input-field disable-input"
                    }
                  >
                    <input
                      placeholder="Your first name"
                      name="firstName"
                      {...register("firstName", {
                        required: "First name is required",
                      })}
                      disabled={!verifyOtpMutation.isSuccess}
                    />
                  </div>
                  {errors.firstName && (
                    <p className="text-danger">{errors.firstName.message}</p>
                  )}
                </div>
                <div className="input-container">
                  <div className="input-title">
                    <p>
                      <label>
                        Last name<span>*</span>
                      </label>
                    </p>
                  </div>
                  <div
                    className={
                      verifyOtpMutation.isSuccess
                        ? "input-field"
                        : "input-field disable-input"
                    }
                  >
                    <input
                      placeholder="Your last name"
                      name="lastName"
                      {...register("lastName", {
                        required: "Last name is required",
                      })}
                      disabled={!verifyOtpMutation.isSuccess}
                    />
                  </div>
                  {errors.lastName && (
                    <p className="text-danger">{errors.lastName.message}</p>
                  )}
                </div>
              </div>
              <div className="input-container">
                <div className="input-title">
                  <p>
                    <label>
                      Email ID<span>*</span>
                    </label>
                  </p>
                </div>
                <div
                  className={
                    verifyOtpMutation.isSuccess
                      ? "input-field"
                      : "input-field disable-input"
                  }
                >
                  <input
                    type={"email"}
                    placeholder="Your email"
                    name="email"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: "Invalid email address",
                      },
                    })}
                    disabled={!verifyOtpMutation.isSuccess}
                  />
                </div>
                {errors.email && (
                  <p className="text-danger">{errors.email.message}</p>
                )}
              </div>
              <div className="input-container">
                <div className="input-title">
                  <p>
                    <label>
                      Password<span>*</span>
                    </label>
                  </p>
                </div>
                <div
                  className={
                    verifyOtpMutation.isSuccess
                      ? "input-field"
                      : "input-field disable-input"
                  }
                >
                  <div className="relative w-full">
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Create your password"
                      name="password"
                      {...register("password", {
                        required: "Password is required",
                        minLength: {
                          value: 8,
                          message:
                            "Password must be at least 8 characters long",
                        },
                      })}
                      disabled={!verifyOtpMutation.isSuccess}
                      className="pl-8 pr-4 py-2 w-full rounded border border-gray-300 focus:outline-none focus:border-indigo-500"
                    />
                    <button
                      type="button"
                      className="absolute inset-y-0 right-0 flex items-center px-4 bg-transparent text-gray-500"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <FaRegEye /> : <FaEyeSlash />}
                    </button>
                  </div>
                </div>
                {errors.password && (
                  <p className="text-danger">{errors.password.message}</p>
                )}
              </div>
              <div className="input-title">
                <p>
                  <label>
                    Confirm Password<span>*</span>
                  </label>
                </p>
              </div>
              <div
                className={
                  verifyOtpMutation.isSuccess
                    ? "input-field"
                    : "input-field disable-input"
                }
              >
                <div className="relative w-full">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Confirm your password"
                    name="confirmPassword"
                    {...register("confirmPassword", {
                      required: "Confirm Password is required",
                      validate: {
                        matchesPassword: (value) =>
                          value === getValues("password") ||
                          "The confirm password does not match the password",
                      },
                    })}
                    disabled={!verifyOtpMutation.isSuccess}
                    className="pl-8 pr-4 py-2 w-full rounded border border-gray-300 focus:outline-none focus:border-indigo-500"
                  />
                  {/* <button
                    type="button"
                    className="absolute inset-y-0 right-0 flex items-center px-4 bg-transparent text-gray-500"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FaRegEye /> : <FaEyeSlash />}
                  </button> */}
                </div>
                {errors.confirmPassword && (
                  <p className="text-danger">
                    {errors.confirmPassword.message}
                  </p>
                )}
              </div>

              <div className="input-container">
                <div className="input-field">
                  <input
                    id="terms_condition"
                    type={"checkbox"}
                    placeholder="Create your password"
                    name="registerTermsCondition"
                    {...register("registerTermsCondition", {
                      required: "Please agree with terms and conditions",
                    })}
                  />
                  <label htmlFor="terms_condition">
                    I accept the <a href="#">terms and conditions</a> for
                    jewecle.
                  </label>
                </div>
                {errors.registerTermsCondition && (
                  <p className="text-danger">
                    {errors.registerTermsCondition.message}
                  </p>
                )}
              </div>
              <div>
                <button
                  type="submit"
                  className={
                    verifyOtpMutation.isSuccess &&
                    !submitBasicInfoMutation.isLoading
                      ? "primary-btn w-100 p-lg "
                      : "primary-btn w-100 p-lg disable-button"
                  }
                  disabled={
                    submitBasicInfoMutation.isLoading ||
                    !verifyOtpMutation.isSuccess
                  }
                >
                  {submitBasicInfoMutation.isLoading ? (
                    <>
                      <Spinner animation="border" size="sm" className="me-2" />
                      Loading...
                    </>
                  ) : (
                    <>
                      Next
                      <img src={Arrow} alt="arrow-right" />
                    </>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>

        <div className="form-poster-container d-none d-md-flex">
          <img src={RegImgs} alt="registration-poster" />
          <h5>We Are Jewecle</h5>
          <p>Register Now on our platform with zero commission</p>
        </div>
      </div>
    </>
  );
}

export default Register;
