import React, { useEffect, useState } from "react";
import upload_option_arrow from "../../assets/images/icons/upload_option_arrow.svg";
import option_ic_close_arrow from "../../assets/images/icons/close_arrow.svg";
import right_arrow_option from "../../assets/images/icons/right_arrow_option.svg";
import upload_file_ic from "../../assets/images/icons/upload_file_ic.svg";
import not_uploaded from "../../assets/images/icons/not_uploaded.svg";
import { useGetProducts } from "../../hooks/uploadJewellery";
import MultipleProduct from "./UploadedProducts/MultipleProduct";
import femail_ic from "../../assets/images/icons/femail_ic.png";
import { useNavigate } from "react-router-dom";
import { useImageUpload } from "../../hooks/common";
import { useAppContext } from "../../appContext";
import Loader from "../Loader/Loader";
import { FaPlusCircle } from "react-icons/fa";
import looks_empty from "../../assets/images/icons/looks_empty.svg";
import DeleteConfirmationModal from "../../Components/DeleteConfirmationModal";
import { capitalizeFirstLetter } from "../../helper/common";
import { toast } from "react-toastify";
import ToastFailed from "../../Components/Toaster/Failed";
import Toast from "../Toaster/Successed";

function UploadSection({
  selectedOption,
  selectedProduct,
  setSelectedProduct,
}) {
  const navigate = useNavigate();
  const { dispatch } = useAppContext();
  const [uploadedLink, setUploadedLink] = useState([]);
  const imageUpload = useImageUpload();
  const getProductsMutation = useGetProducts();
  const [products, setProducts] = useState([]);
  const [genderWise, setGenderWise] = useState([]);
  const [catToggle, setCatToggle] = useState(false);
  // const [selectedProduct, setSelectedProduct] = useState({});
  const [selectedImages, setSelectedImages] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [genderIndex, setGenderIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [editProduct, setEditProduct] = useState();
  const [index, setIndex] = useState();
  const [isDragging, setIsDragging] = useState();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const data = await getProductsMutation.mutateAsync({ gender: "" });
        const customOrder = { Male: 1, Female: 2, Kids: 3, Couple: 4 };
        // setProducts(data.responseData);
        const result = data.responseData.reduce((acc, curr) => {
          const index = acc.findIndex(
            (obj) =>
              obj.gender.charAt(0).toUpperCase() + obj.gender.slice(1) ===
              curr.gender.charAt(0).toUpperCase() + curr.gender.slice(1)
          );
          if (index !== -1) {
            acc[index].categories.push({
              id: curr._id,
              name: curr.name,
              subcategories: curr.jewelleryType,
              length: curr.length,
              lengthValue: curr.lengthValue,
              sampleImage: curr.sampleImage,
            });
          } else {
            acc.push({
              gender:
                curr.gender.charAt(0).toUpperCase() + curr.gender.slice(1),
              categories: [
                {
                  id: curr._id,
                  name: curr.name,
                  subcategories: curr.jewelleryType,
                  length: curr.length,
                  lengthValue: curr.lengthValue,
                  sampleImage: curr.sampleImage,
                },
              ],
            });
          }
          return acc;
        }, []);
        const sortedData = result.sort(
          (a, b) => customOrder[a.gender] - customOrder[b.gender]
        );
        setProducts(sortedData);
      } catch (error) {
        // Handle error here
      }
    };
    fetchProducts();
  }, []);

  const handleSaveProduct = () => {
    console.log("hey");
    if (index !== -1) {
      const newData = [...finalData];
      newData[index] = {
        ...newData[index],
        selectedProduct: editProduct.selectedProduct,
        selectedImages: editProduct.selectedImages,
      };
      setFinalData(newData);
      setEditProduct();
      toast(<Toast message="Product edit successfully" />);
    }
  };

  const filterGendderWise = (gender, close, index) => {
    const filteredData = products.filter(
      (category) => category.gender === gender
    );
    setGenderWise(filteredData);
    setGenderIndex(index);
    setCatToggle(close ? false : true);
  };

  const selectProduct = (
    gender,
    category,
    subcategory,
    subCategoryId,
    id,
    lengthValue,
    length,
    sampleImage
  ) => {
    setSelectedProduct({
      product_id: id,
      subCategoryId: subCategoryId,
      title: `${capitalizeFirstLetter(category)}  ${capitalizeFirstLetter(
        subcategory
      )}`,
      category: category,
      subcategory: subcategory,
      lengthValue,
      length,
      sampleImage,
    });
    let updatedProduts = [];
    products.map((product) => {
      if (product.gender == gender) {
        product["isTag"] = true;
        updatedProduts.push(product);
      } else {
        product["isTag"] = false;
        updatedProduts.push(product);
      }
    });
    setProducts(updatedProduts);
    setCatToggle(!catToggle);
  };
  useEffect(() => {}, [selectedImages]);
  const handleFileInputChange = async (event) => {
    const files = event.target.files;
    if (selectedImages.length >= 5) {
      toast(<ToastFailed message="Only 5 photos are allowed to upload." />);
      return; // Stop further execution
    }

    let imgs = [];
    for (let i = 0; i < files.length; i++) {
      const formData = new FormData();
      formData.append("file", files[i]);
      let url = await imageUpload.mutateAsync(formData, setUploadedLink);
      imgs.push({
        name: files[i].name,
        url: URL.createObjectURL(event.target.files[i]),
        uploadedUrl: url.responseData,
      });
    }

    // Ensure the total number of images does not exceed 5
    const remainingSlots = 5 - selectedImages.length;
    const imagesToAdd = imgs.slice(0, remainingSlots);
    setSelectedImages([...selectedImages, ...imagesToAdd]);
  };

  const handleEditFileInputChange = async (event) => {
    const files = event.target.files;
    if (editProduct.selectedImages.length >= 5) {
      toast(<ToastFailed message="Only 5 photos are allowed to upload." />);
      return; // Stop further execution
    }

    let imgs = [];
    for (let i = 0; i < files.length; i++) {
      const formData = new FormData();
      formData.append("file", files[i]);
      let url = await imageUpload.mutateAsync(formData, setUploadedLink);
      imgs.push({
        name: files[i].name,
        url: URL.createObjectURL(event.target.files[i]),
        uploadedUrl: url.responseData,
      });
    }

    // Ensure the total number of images does not exceed 5
    const remainingSlots = 5 - editProduct.selectedImages.length;
    const imagesToAdd = imgs.slice(0, remainingSlots);

    // Merge new images with existing images in editProduct
    const updatedSelectedImages = [
      ...editProduct.selectedImages,
      ...imagesToAdd,
    ];

    // Update editProduct state
    setEditProduct({ ...editProduct, selectedImages: updatedSelectedImages });
  };

  const handleRemoveImage = (index) => {
    let newArr = selectedImages;
    let uploadedImages = uploadedLink;
    newArr.splice(index, 1);
    uploadedImages.splice(index, 1);
    setSelectedImages([...newArr]);
    setUploadedLink([...uploadedImages]);
  };

  const handleEditRemoveImage = (index) => {
    let newArr = editProduct.selectedImages;
    let uploadedImages = uploadedLink;
    newArr.splice(index, 1);
    uploadedImages.splice(index, 1);
    setEditProduct({ ...editProduct, selectedImages: newArr });
    setUploadedLink([...uploadedImages]);
  };

  const handleUploadImages = () => {
    if (selectedOption === 1) {
      setFinalData([...finalData, { selectedProduct, selectedImages }]);
      setSelectedImages([]);
    } else if (selectedOption === 2) {
      setFinalData([...finalData, { selectedProduct, selectedImages }]);
      dispatch({ type: "UPDATE_UPLOADED_LINK", payload: uploadedLink });
      navigate("/dashboard/jewellery-info", {
        state: { data: [{ selectedProduct, selectedImages }] },
      });
    }
  };
  const handleNext = () => {
    navigate("/dashboard/jewellery-info", { state: { data: finalData } });
  };

  const handleRemoveisTag = () => {
    // setIsOpen(true)
    let updatedProduts = [];
    products.map((product) => {
      product["isTag"] = false;
      updatedProduts.push(product);
    });
    setProducts(updatedProduts);
  };
  const renderGenderSection = () => {
    return (
      <ul>
        {products.map((item, index) => (
          <li className="gender-type" key={index}>
            <span>
              <button
                className={`${selectedProduct.title && "cursor-not-allowed"}`}
                onClick={() => filterGendderWise(item.gender, null, index)}
                // disabled={selectedProduct.title ? true : false}
              >
                <span
                  className={`${
                    item.isTag && "text-jw-primary text-xl"
                  } text-lg font-semibold`}
                >
                  {item?.gender}
                </span>
                <img src={upload_option_arrow} alt="" />
              </button>
              {item.isTag && (
                <div className="flex gap-2">
                  <span className="font-bold text-sm">
                    {selectedProduct.title}
                  </span>
                  <img
                    className="cursor-pointer"
                    width={10}
                    onClick={() => handleRemoveisTag()}
                    src={option_ic_close_arrow}
                    alt=""
                  />
                </div>
              )}
            </span>
          </li>
        ))}
      </ul>
    );
  };

  const renderDeleteModal = () => {
    return (
      isOpen && (
        <DeleteConfirmationModal
          isOpen={isOpen}
          title={`Do you really wish to remove the selected category?`}
          closeModal={() => setIsOpen(false)}
        />
      )
    );
  };

  const handleDelete = (index) => {
    const updatedProducts = [...finalData];
    updatedProducts.splice(index, 1);
    setFinalData(updatedProducts);
  };

  const handleEdit = (index) => {
    setEditProduct(finalData[index]);
    setIndex(index);
    const editSection = document.getElementById("edit-section");
    if (editSection) {
      editSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("index", index);
    setIsDragging(true)
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
  };

  const handleDrop = (e, dropIndex) => {
    setIsDragging(false)
    const dragIndex = e.dataTransfer.getData("index");
    const newImages = [...editProduct.selectedImages];
    const draggedImage = newImages[dragIndex];

    newImages.splice(dragIndex, 1);
    newImages.splice(dropIndex, 0, draggedImage);

    setEditProduct({
      ...editProduct,
      selectedImages: newImages,
    });
  };

  const handleNewDrop = (e, dropIndex) => {
    setIsDragging(false)
    const dragIndex = e.dataTransfer.getData("index");
    const newImages = [...selectedImages];
    const draggedImage = newImages[dragIndex];

    newImages.splice(dragIndex, 1);
    newImages.splice(dropIndex, 0, draggedImage);

    setSelectedImages(newImages)
  };

  

  return (
    <>
      {getProductsMutation.isLoading ? (
        <div className="top-10 md:top-3 left-0 right-0 bottom-0 w-full overflow-hidden  flex flex-col items-center justify-center ">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-32 mt-32"></div>
        </div>
      ) : (
        <div className="upload-section">
          <div className="upload">
            <div className="upload-action">
              <div className="upload-toggle">
                <div className="upload-header">
                  {renderGenderSection()}
                  {catToggle && (
                    <div className="relative z-10">
                      {/* Main dropdown */}
                      <div
                        className={`origin-top-right absolute ${
                          genderIndex === 0
                            ? "md:left-48"
                            : genderIndex === 1
                            ? "right-1/2"
                            : "right-48"
                        } mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5`}
                      >
                        <div
                          className={`py-1 ${
                            genderWise[0]?.categories?.length > 8 ? "" : ""
                          } `}
                          role="menu"
                          aria-orientation="vertical"
                          aria-labelledby="options-menu"
                        >
                          {genderWise[0]?.gender && (
                            <div
                              className="relative px-4 py-2 text-lg text-gray-700 font-extrabold  flex justify-between cursor-pointer"
                              role="menuitem"
                            >
                              <span>{genderWise[0]?.gender}</span>
                              <img
                                className="cursor-pointer"
                                width={10}
                                onClick={() => setCatToggle(false)}
                                src={option_ic_close_arrow}
                                alt=""
                              />
                            </div>
                          )}
                          {genderWise.map((item) =>
                            item.categories.map((cat, index) => (
                              // This div needs to be relative for the child absolute positioning to work correctly
                              <div
                                key={index}
                                className={`relative  px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex justify-between cursor-pointer`}
                                role="menuitem"
                                onMouseEnter={() => setHoveredItem(index)}
                                onMouseLeave={() => setHoveredItem(null)}
                              >
                                <span>{cat.name}</span>
                                {/* Hover arrow */}
                                <span>{hoveredItem === index ? "→" : ""}</span>

                                {/* Submenu positioned absolute to the right of the hovered item */}
                                {hoveredItem === index && (
                                  <div className="absolute left-full top-0 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-20">
                                    <div
                                      className={`py-1 ${
                                        cat.subcategories.length > 6
                                          ? "max-h-48 overflow-y-scroll"
                                          : ""
                                      }`}
                                      role="menu"
                                      aria-orientation="vertical"
                                      aria-labelledby="options-menu"
                                    >
                                      {cat.subcategories.map(
                                        (subItem, subIndex) => (
                                          <div
                                            key={subIndex}
                                            className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                            role="menuitem"
                                            onClick={() =>
                                              selectProduct(
                                                item.gender,
                                                cat.name,
                                                subItem.name,
                                                subItem._id,
                                                cat.id,
                                                cat.lengthValue,
                                                cat.length,
                                                cat.sampleImage
                                              )
                                            }
                                          >
                                            {subItem.name}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {finalData.length > 0 &&
                  finalData.map((data, index) => (
                    <div
                      key={index}
                      className="shadow-md rounded-md p-4 mb-4 bg-white border border-gray-300 m-4 "
                    >
                      <div className="flex justify-between items-center">
                        <p className="text-black font-bold text-px-2 py-1 text-xl">
                          Product {index + 1}
                        </p>
                        <div className="flex items-center text-white mr-4">
                          <button
                            className="text-lg mr-2 bg-primary-pink px-2 py-1 rounded-md"
                            onClick={() => {
                              handleEdit(index);
                            }}
                          >
                            Edit
                          </button>
                          <button
                            className="text-lg bg-primary-pink px-2 py-1 rounded-md"
                            onClick={() => handleDelete(index)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                      <MultipleProduct product={data} />
                      {/* {index !== finalData.length - 1 && (
                        <hr className="mx-8 mb-2 text-gray-500" />
                      )} */}
                    </div>
                  ))}

                {selectedProduct.product_id ? (
                  imageUpload.isLoading ? (
                    <Loader />
                  ) : (
                    <>
                      {!editProduct ? (
                        <div className="upload-input-section">
                          <div className="input-upload">
                            {selectedProduct.product_id && (
                              <div className={`upload-area select-catogery`}>
                                <h2 className="text-lg font-bold mb-4">
                                  Upload your jewellery photos here
                                </h2>
                                <div
                                  className={`flex w-10/12 overflow-x-auto ${
                                    selectedImages.length === 0 &&
                                    "justify-center"
                                  }`}
                                  style={{ scrollbarWidth: "thin" }}
                                >
                                  <div className="flex items-center justify-center">
                                    <div className="bg-gray-100 p-6 mx-2 my-1 rounded-lg shadow-md flex justify-center items-center h-36 w-32 relative">
                                      <label className="cursor-pointer flex justify-center items-center w-full h-full">
                                        <input
                                          type="file"
                                          name=""
                                          id=""
                                          onChange={handleFileInputChange}
                                          multiple
                                          className="pointer-events-none" // Disable pointer events
                                        />
                                        <div>
                                          <FaPlusCircle
                                            size={30}
                                            className="text-gray-400"
                                          />
                                        </div>
                                      </label>
                                    </div>
                                    {selectedImages.map((img, index) => (
                                        <div
                                        key={index}
                                        draggable
                                        onDragStart={(e) =>
                                          handleDragStart(e, index)
                                        }
                                        onDragOver={(e) =>
                                          handleDragOver(e, index)
                                        }
                                        onDrop={(e) =>
                                          handleNewDrop(e, index)
                                        }
                                        className="bg-gray-100 p-0 mx-2 my-1 rounded-lg shadow-md flex justify-center items-center h-36 w-32 relative"
                                        style={{
                                          opacity: isDragging ? 0.5 : 1,
                                        }} // Adjust opacity when dragging
                                      >
                                      <div
                                        key={index}
                                        className="bg-gray-100 p-0 mx-2 my-1 rounded-lg shadow-md flex justify-center items-center h-36 w-32 relative"
                                      >
                                        <div className="cursor-pointer flex justify-center items-center w-full h-full">
                                          <img
                                            src={img.url}
                                            alt=""
                                            className="w-full h-full object-cover rounded-lg"
                                          />
                                          <button className="absolute top-2 right-2 rounded-lg">
                                            <img
                                              onClick={() =>
                                                handleRemoveImage(index)
                                              }
                                              src={option_ic_close_arrow}
                                              alt=""
                                            />
                                          </button>
                                        </div>
                                      </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>

                                <div className="flex items-center justify-end mt-2">
                                  {selectedImages.length >= 1 && (
                                    <div className="action-img">
                                      <button
                                        className="btn-dashboard outline-btn px-3 py-2 text-sm"
                                        onClick={handleUploadImages}
                                      >
                                        Next
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                            {!selectedProduct.product_id && (
                              <div className="not-uploaded">
                                <p>
                                  <img src={not_uploaded} alt="" /> Select a
                                  category to upload an image
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <>
                          <div
                            className="upload-input-section"
                            id="edit-section"
                          >
                            <div className="input-upload">
                              <>
                                {editProduct.selectedProduct.product_id && (
                                  <div
                                    className={`upload-area select-catogery`}
                                  >
                                    <h2 className="text-lg font-bold mb-4">
                                      Edit your jewellery photos here
                                    </h2>
                                    <div
                                      className={`flex w-10/12 overflow-x-auto ${
                                        editProduct.selectedImages.length ===
                                          0 && "justify-center"
                                      }`}
                                      style={{ scrollbarWidth: "thin" }}
                                    >
                                      <div className="flex items-center justify-center">
                                        <div className="bg-gray-100 p-6 mx-2 my-1 rounded-lg shadow-md flex justify-center items-center h-36 w-32 relative">
                                          <label className="cursor-pointer flex justify-center items-center w-full h-full">
                                            <input
                                              type="file"
                                              name=""
                                              id=""
                                              onChange={
                                                handleEditFileInputChange
                                              }
                                              multiple
                                              className="pointer-events-none"
                                            />
                                            <div>
                                              <FaPlusCircle
                                                size={30}
                                                className="text-gray-400"
                                              />
                                            </div>
                                          </label>
                                        </div>
                                        {editProduct.selectedImages.map(
                                          (image, index) => (
                                            <div
                                              key={index}
                                              draggable
                                              onDragStart={(e) =>
                                                handleDragStart(e, index)
                                              }
                                              onDragOver={(e) =>
                                                handleDragOver(e, index)
                                              }
                                              onDrop={(e) =>
                                                handleDrop(e, index)
                                              }
                                              className="bg-gray-100 p-0 mx-2 my-1 rounded-lg shadow-md flex justify-center items-center h-36 w-32 relative"
                                              style={{
                                                opacity: isDragging ? 0.5 : 1,
                                              }} // Adjust opacity when dragging
                                            >
                                              <div className="cursor-pointer flex justify-center items-center w-full h-full">
                                                <img
                                                  src={image.url}
                                                  alt=""
                                                  className="w-full h-full object-cover rounded-lg"
                                                />
                                                <button className="absolute top-2 right-2 rounded-lg">
                                                  <img
                                                    onClick={() =>
                                                      handleEditRemoveImage(
                                                        index
                                                      )
                                                    }
                                                    src={option_ic_close_arrow}
                                                    alt=""
                                                  />
                                                </button>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                    <div className="flex items-center justify-end mt-2">
                                      <div className="action-img gap-3">
                                        <button
                                          className="btn-dashboard outline-btn px-3 py-2 text-sm lg:mr-3"
                                          onClick={handleSaveProduct}
                                          disabled={
                                            editProduct.selectedImages
                                              .length === 0
                                          }
                                        >
                                          Save
                                        </button>
                                        <button
                                          className="btn-dashboard outline-btn px-3 py-2 text-sm "
                                          onClick={() => {
                                            setEditProduct();
                                          }}
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>
                            </div>
                            {editProduct.selectedImages.length === 0 && (
                              <p className="text-red-500 font-bold">
                                Please upload atlest one photo
                              </p>
                            )}
                          </div>
                        </>
                      )}
                    </>
                  )
                ) : (
                  <div className="flex justify-center">
                    <img className="w-52 h-52" src={looks_empty} alt="" />
                  </div>
                )}
                {selectedProduct.product_id &&
                  finalData.length >= 1 &&
                  selectedOption === 1 && (
                    <div className="flex justify-end items-center px-3 py-2 text-base">
                      <button
                        className="btn-dashboard outline-btn"
                        onClick={handleNext}
                      >
                        Proceed
                      </button>
                    </div>
                  )}
              </div>
            </div>
          </div>
          {renderDeleteModal()}
        </div>
      )}
    </>
  );
}
export default UploadSection;
