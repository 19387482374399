import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import MobileVerify from '../../../Components/MobileVerification/index'
export  function Banner(props) {
  const token = localStorage.getItem('access_token')
  return (
        <section className='banner'>
            <Container>
                <Row>
                    <Col md={6}>
                        <div className='banner-content'>
                            <div className='banner-title'>
                                <h1>{props?.Pinkfirst &&< span className='pink-text'>{props?.Pinkfirst}</span>} {props?.title} <br/> {props?.Breaktitle} <span className='pink-text'>{props?.Pinktitle}</span> {props?.lasttitle}</h1>
                            </div>
                            <div className='banner-desc'>
                                <h5>{props?.desc} <br/> {props?.breakDesc}</h5>
                                <h5 className='mt-3'>{props?.twoDesc}</h5>
                            </div>
                            {
                                !token && <MobileVerify />
                            }
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className='banner-poster'>
                           {props?.bannerImage && <img src={props?.bannerImage} alt='banner-poster'/>}
                        </div>
                    </Col>
                </Row>
            </Container>  
        </section>    
  )
}
 export default Banner;