import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import Arrow from "../../assets/images/arrow.png";
import BackArrow from "../../assets/images/icons/back_arrow.svg";
import { useSubmitBankInfo } from "../../hooks/register";
import { Spinner } from "react-bootstrap";

function BankDetails() {

  //Mutation hooks
const SubmitBankInfoMutation = useSubmitBankInfo();

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    SubmitBankInfoMutation.mutate(data)
  };

  return (
    <>
      <div className="register-form form">
        <div className="form-content-container">
          <button className="back-btn" onClick={() => {
            navigate('/address')
          }}>
            {" "}
            <img src={BackArrow} alt="" />
          </button>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-content">
              <div className="form-heading">
                <h3>Bank Details</h3>
                {/* <ul className='form_step'>
                <li ></li>
                <li></li>
                <li className='active'></li>
             </ul> */}
              </div>

              <div className="input-container">
                <div className="input-title">
                  <p>
                    <label>Bank Name<span>*</span></label>
                  </p>
                </div>
                <div className="input-field">
                  <input placeholder="Enter Bank Name" name="bank_name"
                    {...register("bank_name", { required: "Bank name is required" })} 
                     Value={localStorage.getItem('bank_name')}
                    />
                </div>
              {errors.bank_name && <p className="text-danger">{errors.bank_name.message}</p>}
              </div>
              <div className="input-container">
                <div className="input-title">
                  <label>Account Number<span>*</span></label>
                </div>
                <div className="input-field">
                  <input type="text" placeholder="Enter Your Account Number" name="account_no"
                    {...register("account_no", { required: "Account number is required",  pattern: {
                      value: /^[0-9]{9,18}$/,
                      message: "Invalid Account number"
                    } })} 
                    Value={localStorage.getItem('account_no')}
                    />
                </div>
              {errors.account_no && <p className="text-danger">{errors.account_no.message}</p>}
              </div>
              <div className="input-container">
                <div className="input-title">
                  <label>IFSC Code<span>*</span></label>
                </div>
                <div className="input-field">
                  <input type="text" placeholder="Enter Your IFSC Code" name="ifsc_code"
                    {...register("ifsc_code", { required: "IFSC code is required",  pattern: {
                      value: /^[A-Z]{4}0[A-Z0-9]{6}$/,
                      message: "Invalid IFSC code"
                    } })}
                    Value={localStorage.getItem('ifsc_code')}
                    />
                </div>
              {errors.ifsc_code && <p className="text-danger">{errors.ifsc_code.message}</p>}
              </div>
              <div className="input-container">
                <div className="input-title">
                  <p>
                    <label>Branch<span>*</span></label>
                  </p>
                </div>
                <div className="input-field">
                  <input type="text" placeholder="Your branch name" name="branch"
                    {...register("branch", { required: "Branch name is required" })}
                    Value={localStorage.getItem('branch')}
                    />
                </div>
              {errors.branch && <p className="text-danger">{errors.branch.message}</p>}
              </div>
              <div className="input-container">
                <div className="input-title">
                  <p>
                    <label>Account Holder Name<span>*</span></label>
                  </p>
                </div>
                <div className="input-field">
                  <input
                    type="text"
                    placeholder="Enter your account holder name"
                    name="account_holder_name"
                    {...register("account_holder_name", { required: "Account holder name is required" })}
                    Value={localStorage.getItem('accountName')}

                  />
                </div>
              {errors.account_holder_name && <p className="text-danger">{errors.account_holder_name.message}</p>}
              </div>
              <div>
                <button
                  type='submit'
                  className={!SubmitBankInfoMutation.isLoading  ? 'primary-btn p-lg w-100' : 'primary-btn p-lg disable-button w-100'}
                  disabled={SubmitBankInfoMutation.isLoading }
                >
                  {SubmitBankInfoMutation.isLoading ? (
                    <>
                      <Spinner animation="border" size="sm" className="me-2" />
                      Loading...
                    </>
                  ) : (
                    <>
                      Done
                    </>
                  )}
                </button>
                <a className="outline-btn p-lg mt-3">
                  Skip
                </a>
              </div>
            </div>
          </form>
        </div>
        <div className="form-poster-container form_bg bank-details d-none d-md-flex"></div>
      </div>
    </>
  );
}

export default BankDetails;
