import React from "react";
import thumb from "./../../assets/images/thumb.jpg";
function ProdcutPreview({ selectedProduct }) {
  const renderYourSelectedCategory = () => {
    if (!selectedProduct?.title) {
      return;
    }
    return (
      <div className="product-proview">
        <h5>your selected category</h5>
        <div className="thumbnail">
          <img
            className="object-contain	"
            src={
              selectedProduct?.sampleImage
                ? selectedProduct?.sampleImage
                : thumb
            }
            alt=""
          />
        </div>
        <p className="p-lg">{selectedProduct?.title}</p>
        <p className="text-center desc">
          Refer to this sample image for uploading to avoid faulty images
        </p>
        <p className="note">
          <span className="pink-color-text">Note:</span> Upload your image in
          png/jpg/jpeg format named as, Initial_initial_2.png. You can upload
          maximum 5 images for a product. For more details please visit our{" "}
          <a href="" className="pink-colo-text">
            jewecle guidelines
          </a>
          .
        </p>
      </div>
    );
  };
  return <>{renderYourSelectedCategory()}</>;
}

export default ProdcutPreview;
