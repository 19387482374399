import React from 'react'
import StepIcon1 from '../../assets/images/icons/upload_ic.svg'
import StepIcon2 from '../../assets/images/icons/order_ic.svg'
import StepIcon3 from '../../assets/images/icons/payment_ic.svg'


function ThreeSimpleStep() {
    return (
        <>
            <section className="common-section threeSimple-step">
                <div className="container">
                    <div className="content-section">
                    <h1 className='text-3xl lg:text-4xl'>sell your <span className='pink-color-text'>Gold jewelry</span> stock with <span className='pink-color-text'>3 simple steps</span></h1>
                        <h5 className='text-2xl'>Showcase and sell your gold jewelry to retailers and make your </h5>
                        <h5 className='dark-word text-2xl'>dukan a brand.</h5>
                        <div className="three-step">
                            <div className="row">
                            <div className="col-md-4 mb-4 mb-md-0">
                                    <div className="step-box flex flex-col justify-center items-center">
                                        <img src={StepIcon1} alt="" />
                                        <h5 className='text-xl'>upload jewellery</h5>
                                        <p>upload unlimited gold jewelleries. <br />
                                            showcase to each retailer.<br />
                                            get your first order.</p>                    </div>
                                </div>
                                <div className="col-md-4 mb-4 mb-md-0">
                                    <div className="step-box flex flex-col justify-center items-center"> {/* Added flex utility classes here */}
                                        <img src={StepIcon2} alt="" />
                                        <h5 className='text-xl'>real time jewellery try on</h5>
                                        <p>Now Retailers can not only see but also try out the jewellery stock on real time woth our AR technology</p>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-4 mb-md-0">
                                    <div className="step-box flex flex-col justify-center items-center">
                                        <img src={StepIcon3} alt="" />
                                        <h5 className='text-xl'>connect with retailers</h5>
                                        <p>With advance features and data analytics get your connection with genuine retailers</p>                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ThreeSimpleStep
