import React from 'react'
import { Container,Col,Row } from 'react-bootstrap'
import Register from '../../assets/images/register.png';
import Jwellery from '../../assets/images/jwellery.png';
import Orders from '../../assets/images/orders.png';
import Shipping from '../../assets/images/shipping.png';
import Money from '../../assets/images/money.png';

function List() {
  return (
    <div className='list'>
        <Container>
            <Row>
                <Col md={5}>
                    <div className='list-content'>
                        <ul>
                            <li>
                                <div className='list-icon'>
                                    <img src={Register} alt='register-icon'/>
                                </div>
                                <div className='list-details'>
                                    <h5>Register With Jewecle</h5>
                                    <p>register your <span>dukan</span> with us on zero fees! No hustle in joining.</p>
                                </div>
                            </li>
                            <li>
                                <div className='list-icon'>
                                    <img src={Jwellery} alt='register-icon'/>
                                </div>
                                <div className='list-details'>
                                    <h5>Upload unlimited jewelleries</h5>
                                    <p>add <span>variety of stock</span> and showcase your <span>all designs</span>  to every retailer.
    get orders in single click.</p>
                                </div>
                            </li>
                            <li>
                                <div className='list-icon'>
                                    <img src={Orders} alt='register-icon'/>
                                </div>
                                <div className='list-details'>
                                    <h5>get new orders</h5>
                                    <p>make your single design available to <span>each retailer</span> and get <span>new orders</span> from them.
    grab the opportunity to <span>earn more.</span></p>
                                </div>
                            </li>
                            <li>
                                <div className='list-icon'>
                                    <img src={Shipping} alt='register-icon'/>
                                </div>
                                <div className='list-details'>
                                    <h5>Shipping the orders</h5>
                                    <p>make your orders ready with <span>attractive jewecle packaging.</span> allow our <span>delivery partner</span> to deliver your order.</p>
                                </div>
                            </li>
                            <li>
                                <div className='list-icon'>
                                    <img src={Money} alt='register-icon'/>
                                </div>
                                <div className='list-details'>
                                    <h5>get payment</h5>
                                    <p>get <span>7 days instant payment</span> in your bank. your payment cycle will continue with each order. <span>no need of udhar.</span></p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </Col>
                <Col md={6}>
                    <div className='list-poster'>

                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default List
