import { useQuery, useMutation } from 'react-query';
import axios from 'axios';
import { toast } from 'react-toastify';
import ToastFailed from '../Components/Toaster/Failed';
import Toast from '../Components/Toaster/Successed';

const API_URL = process.env.REACT_APP_BASE_URL;

const fetchDailyFeed = async () => {
    const response = await fetch(`${API_URL}/daily-feed`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    });
    return response.json();
};

export const useGetDailyFeed = () => {
    return useQuery(['DailyFeed'], () => fetchDailyFeed());
};

export const useAddDailyFeed = () => {

    const addDailyFeedMutation = useMutation(
        async (data) => {

            const response = await axios.post(`${API_URL}/daily-feed/`, data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json',
                    'accept': '*/*'
                },
            });
            return response.data;
        },
        {
            onSuccess: async (data) => {
                // navigate('/dashboard/home');
                // enqueueSnackbar('Product added successfully!', { variant: 'success',autoHideDuration: 3000 });
                // Handle success here
                toast(<Toast message={data.message} />)
            },
            onError: (error) => {
                // Handle error here
                if (Array.isArray(error.response.data.message)) {
                    error.response.data.message.forEach(element => {
                        toast(<ToastFailed message={element} />)
                    });
                } else {
                    toast(<ToastFailed message={error.response.data.message} />)
                }
            },
        }
    );

    return addDailyFeedMutation;
}

export const useEditDailyFeed = () => {

    const editDailyFeedMutation = useMutation(
        async (data) => {
            const{id,...rest} = data
            console.log('rest :>> ', rest);
            const response = await axios.patch(`${API_URL}/daily-feed/${id}`,rest, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json',
                    'accept': '*/*'
                },
            });
            return response.data;
        },
        {
            onSuccess: async (data) => {
                // navigate('/dashboard/home');
                // enqueueSnackbar('Product added successfully!', { variant: 'success',autoHideDuration: 3000 });
                // Handle success here
                toast(<Toast message={data.message} />)
            },
            onError: (error) => {
                // Handle error here
                if (Array.isArray(error.response.data.message)) {
                    error.response.data.message.forEach(element => {
                        toast(<ToastFailed message={element} />)
                    });
                } else {
                    toast(<ToastFailed message={error.response.data.message} />)
                }
            },
        }
    );

    return editDailyFeedMutation;
}

export const useDeleteDailyFeed = () => {

    const deleteDailyFeedMutation = useMutation(
        async (id) => {
            const response = await axios.delete(`${API_URL}/daily-feed/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json',
                    'accept': '*/*'
                },
            });
            return response.data;
        },
        {
            onSuccess: async (data) => {
                // navigate('/dashboard/home');
                // enqueueSnackbar('Product added successfully!', { variant: 'success',autoHideDuration: 3000 });
                // Handle success here
                toast(<Toast message={data.message} />)
            },
            onError: (error) => {
                // Handle error here
                if (Array.isArray(error.response.data.message)) {
                    error.response.data.message.forEach(element => {
                        toast(<ToastFailed message={element} />)
                    });
                } else {
                    toast(<ToastFailed message={error.response.data.message} />)
                }
            },
        }
    );

    return deleteDailyFeedMutation;
}