import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ArrowBack from '../../../assets/images/icons/arrow_back.svg'
import TutorialVideo from '../../../Components/Dashboard/TutorialVideo';
import { useGetJewellery } from '../../../hooks/uploadJewellery';
import { useAddCatalogue } from '../../../hooks/catalogue';
import JewelleryCard from '../../../Components/JewelleryCard/JewelleryCard';
import SelectedJewellery from '../../../Components/Dashboard/Catelogue/selectedJewelleryModal'
import Toast from '../../../Components/Toaster/Failed';

export default function AddCatalogue() {
    const [addCataloguePressed, setAddCataloguePressed] = useState(false)
    const [modalIsOpen, setIsOpen] = useState(false);
    const [jewelleryList, setJewelleryList] = useState([])
    const [selectedJewellery, setSelectedJewellery] = useState([])
    const [catalogueName, setCatalogueName] = useState('')
    const GetJewelleryMutation = useGetJewellery()
    const AddCatalogueMutation = useAddCatalogue()

    useEffect(() => {
        const fetchJewellery = async () => {
            const data = await GetJewelleryMutation.mutateAsync({ page: 1, perPage: 20, state: 2, search: '', })
            setJewelleryList(data.responseData.items)
        }
        fetchJewellery()
    }, [])

    const handleOnSaveCatalogue = () => {
        if (!catalogueName) {
            return
        }
        AddCatalogueMutation.mutate({
            "jewellery_id": selectedJewellery.map(item => item._id),
            "name": catalogueName
        })
    }

    const renderAddCatalogueBtn = () => (
        <div className='flex justify-center items-center h-[550px]'>
            <div
                className="cursor-pointer bg-[rgba(255,196,1,0.28)] rounded-[5px] border-solid border-jw-golden border-2 p-2 flex flex-row gap-[15px] items-center justify-center w-[151px] h-[47px] relative"
                style={{ boxShadow: "2px 2px 8px 0px rgba(86, 86, 86, 0.06)" }}
                onClick={() => setAddCataloguePressed(true)}
            >
                <div className="flex flex-row gap-2 items-center justify-start shrink-0 relative">
                    <div className="flex flex-col gap-[3px] items-start justify-start shrink-0 relative">
                        <div
                            className="text-jw-golden text-left relative"
                            style={{ font: "600 16px 'Segoe UI', sans-serif" }}
                        >
                            Add Catalogue
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    const renderAddCatalogueJewellery = () => (
        <>
            <div className="flex flex-row gap-[13px] items-start justify-start relative pl-4 pt-6">
                <input
                    placeholder='Type a catalogue name'
                    className="text-left relative w-[552px] rounded-[5px] border-solid border-gray-300 border-2 p-2.5"
                    onChange={(e) => setCatalogueName(e.target.value)}
                />
                <button
                    className="rounded-[5px] border-2 border-[#eda039] p-2.5 flex flex-row gap-[15px] items-center justify-center shrink-0 w-[93px] relative"
                    style={{ boxShadow: "2px 2px 8px 0px rgba(86, 86, 86, 0.06)" }}
                    onClick={handleOnSaveCatalogue}
                >
                    <div className="flex flex-row gap-2 items-center justify-start shrink-0 relative">
                        <div className="flex flex-col gap-[3px] items-start justify-start shrink-0 relative">
                            <div
                                className="text-[#eda039] text-left relative"
                                style={{ font: "600 16px 'Segoe UI', sans-serif" }}
                            >
                                save
                            </div>
                        </div>
                    </div>
                </button>
            </div>
            {
                selectedJewellery.length === 0 &&
                <div className='flex justify-center items-center h-[550px]'>
                    <div
                        className="cursor-pointer rounded-[10px] border-[transparent] p-2 flex flex-row gap-[15px] items-center justify-center w-[170px] h-[47px] relative"
                        style={{ boxShadow: "2px 2px 8px 0px rgba(86, 86, 86, 0.06)" }}
                    >
                        <div className="flex flex-row gap-2 items-center justify-start shrink-0 relative" onClick={openModal}>
                            <svg
                                className="shrink-0 relative overflow-visible"
                                style={{}}
                                width="14"
                                height="15"
                                viewBox="0 0 14 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M6 14.5V8.5H0V6.5H6V0.5H8V6.5H14V8.5H8V14.5H6Z"
                                    fill="#939393"
                                />
                            </svg>

                            <div className="flex flex-col gap-[3px] items-start justify-start shrink-0 relative">
                                <div
                                    className="text-[#939393] text-left relative"
                                    style={{ font: "600 16px 'Segoe UI', sans-serif" }}
                                >
                                    Add Jewelry
                                </div>
                            </div>

                            <div className="shrink-0 w-6 h-6 relative overflow-hidden"></div>
                        </div>
                    </div>
                </div>
            }

        </>
    )

    const handleSelectJewellery = (jewelleryData) => {
        const isThere = selectedJewellery.find((item) => item._id === jewelleryData._id)
        if (!isThere) {
            setSelectedJewellery([...selectedJewellery, jewelleryData])
        } else {
            // const temp = [...selectedJewellery]
            // temp.splice(index, 1)
            const temp = selectedJewellery.filter(obj => obj._id !== jewelleryData._id)
            setSelectedJewellery(temp)
        }
    }

    function openModal() {
        if (!catalogueName) {
            toast(<Toast message='Catalogue name is required' />)
            return
        }
        setIsOpen(true);
    }

    let subtitle;

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    const addJewellery = () => {
        setIsOpen(false);
    }

    const handleDelete = (id) => {
        setSelectedJewellery(selectedJewellery.filter(jewellery => jewellery._id !== id))

    }

    return (<>
        <div className='common-layout'>
            <div className='flex flex-col md:flex-row justify-between items-start'>
                <div className='flex justify-start items-center my-3'>
                    {/* <img src={ArrowBack} /> */}
                    <h1 className='text-lg md:text-2xl ml-2 font-bold'>Add Catalogue</h1>
                </div>
            </div>
            {/* <div className='flex justify-between w-full mt-6'>
                <div className='pr-96'>
                    <p className='text-base'>Personalised catalogues helps you to arrange your uploaded jewellery and to manage your stock in systematic manner.</p>
                </div>
                <TutorialVideo />
            </div> */}
            <div className='w-full bg-white min-h-[650px] mt-2 '>
                {renderAddCatalogueJewellery()}
                {selectedJewellery.length > 0 && <JewelleryCard catalogueName={catalogueName} jewelleries={selectedJewellery} handleDelete={handleDelete} />}

            </div>
        </div>
        <SelectedJewellery modalIsOpen={modalIsOpen}
            addJewellery={addJewellery} handleSelectJewellery={handleSelectJewellery}
            selectedJewellery={selectedJewellery} afterOpenModal={afterOpenModal}
            jewelleryList={jewelleryList} closeModal={closeModal} catalogueName={catalogueName} />
    </>)
} 