import React from "react";

const HeaderTabs = ({ tabs, onSelectTab, selectedTab }) => {
  return (
    <div className="flex gap-16 bg-white w-full overflow-x-auto shadow-md">
      {/* header tabs */}
      {tabs.map((tab) => (
        <div
          className={`flex items-center p-3 ml-4 ${
            selectedTab === tab.title && "border-b-4 border-jw-primary"
          } font-bold cursor-pointer`}
          onClick={() => onSelectTab(tab.title, tab.value)}
        >
          <span className="w-auto whitespace-nowrap">{tab.title}</span>
          <div
            className={`w-auto h-5 ml-2 px-1 ${
              selectedTab === tab.title ? "bg-jw-primary" : "bg-jw-badge-grey"
            } flex justify-center items-center text-white`}
          >
            {tab.count}
          </div>
        </div>
      ))}
    </div>
  );
};

export default HeaderTabs;
