import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import group_img1 from "../../assets/images/group_img1.jpg";
import group_img2 from "../../assets/images/group_img2.jpg";
import group_img3 from "../../assets/images/group_img3.jpg";
import group_img4 from "../../assets/images/group_img4.jpg";
import group_img5 from "../../assets/images/group_img5.jpg";
import group_img6 from "../../assets/images/group_img6.jpg";
import group_img7 from "../../assets/images/group_img7.jpg";
import group_img8 from "../../assets/images/group_img8.jpg";
import wave_banner from "../../assets/images/wave_banner.svg";
import MobileVerify from "../../Components/MobileVerification";

function MainBanner() {
  const token = localStorage.getItem("access_token");
  return (
    <>
      <section className="banner main-banner pb-0 pt-0">
        <Container>
          <Row>
            <Col md={6}>
              <div className="banner-content">
                <div className="banner-title">
                  <h1 className="text-3xl lg:text-5xl">
                    Jo stock <span className="pink-color-text">dikhta</span>{" "}
                    hain, wahi stock{" "}
                    <span className="pink-color-text">bikta</span> hain
                  </h1>
                </div>
                <div className="banner-desc">
                  <h5 className="text-xl lg:text-2xl">
                    now showcase your unlimited{" "}
                    <span className="pink-color-text">gold jewelleries</span> to
                    each jewecle retailer and connect with them.
                  </h5>
                </div>
                {/* {
                                !token && <MobileVerify />
                            } */}
              </div>
            </Col>
            <Col md={6}></Col>
          </Row>
        </Container>

        <div className="group-img-container">
          <ul className="img-column">
            <li className="column1">
              <div className="img-wrap">
                <img src={group_img1} alt="" />
              </div>
            </li>
            <li className="column2">
              <div className="img-wrap">
                <img src={group_img2} alt="" />
              </div>
              <div className="img-wrap">
                <img src={group_img3} alt="" />
              </div>
              <div className="img-wrap">
                <img src={group_img4} alt="" />
              </div>
            </li>
            <li className="column3">
              <div className="img-wrap">
                <img src={group_img5} alt="" />
              </div>
              <div className="img-wrap">
                <img src={group_img6} alt="" />
              </div>
              <div className="img-wrap">
                <img src={group_img7} alt="" />
              </div>
            </li>
            <li className="column4">
              <div className="img-wrap">
                <img src={group_img8} alt="" />
              </div>
            </li>
          </ul>
        </div>
        <div className="wave">
          <img src={wave_banner} alt="" />
        </div>
      </section>
    </>
  );
}

export default MainBanner;
