import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Loader from "../../Components/Loader/Loader";
import { useImageUpload } from "../../hooks/common";
import upload_file_ic from "../../assets/images/icons/upload_file_ic.svg";
import option_ic_close_arrow from "../../assets/images/icons/option_ic_close_arrow.svg";
import {
  useAddDailyFeed,
  useDeleteDailyFeed,
  useEditDailyFeed,
} from "../../hooks/dailyFeed";
import DeleteConfirmationModal from "../../Components/DeleteConfirmationModal";
import { Spinner } from "react-bootstrap";

const customStyles = {
  content: {
    top: "55%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%", // For mobile by default
    height: "auto",
    backgroundColor: "white", // Change this to a solid color
  },
  contentMobile: {
    width: "80%",
    height: "60%",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // This is the backdrop
  },
};
const EditDailyFeedModal = ({
  feedData,
  isOpen,
  closeModal,
  dailyFeedLoading,
}) => {
  const imageUpload = useImageUpload();
  const editDailyFeedMutation = useEditDailyFeed();
  const deleteDailyFeedMutation = useDeleteDailyFeed();
  const [selectedImages, setSelectedImages] = useState(
    feedData.jewellery_images.map((item) => {
      return { uploadedUrl: item };
    })
  );
  const [deleteModal, setDeleteModal] = useState(false);
  const [caption, setCaption] = useState(feedData.caption);
  const isMobile = window.innerWidth <= 768;
  const appliedStyles = isMobile
    ? { ...customStyles.content, ...customStyles.contentMobile }
    : customStyles.content;

  const handleFileInputChange = async (event) => {
    const files = event.target.files;
    let imgs = [];
    for (let i = 0; i < files.length; i++) {
      const formData = new FormData();
      formData.append("file", files[i]);
      let url = await imageUpload.mutateAsync(formData);
      imgs.push({
        name: files[i].name,
        url: URL.createObjectURL(event.target.files[i]),
        uploadedUrl: url.responseData,
      });
    }
    setSelectedImages([...selectedImages, ...imgs]);
  };
  const handleRemoveImage = (index) => {
    let newArr = selectedImages;
    newArr.splice(index, 1);
    setSelectedImages([...newArr]);
  };

  const editDailyFeed = () => {
    if (!caption || selectedImages.length === 0) {
      return;
    }
    editDailyFeedMutation.mutateAsync({
      jewellery_images: selectedImages.map((item) => {
        let urlBreak = item.uploadedUrl.split("/");
        return item.uploadedUrl.includes("http")
          ? urlBreak[3] + "/" + urlBreak[4]
          : item.uploadedUrl;
      }),
      caption: caption,
      id: feedData._id,
    });
    closeModal();
  };

  const handleOnChange = (e) => {
    setCaption(e.target.value);
  };
  const handleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };
  const handleDeleteConfirmation = (id) => {
    deleteDailyFeedMutation.mutateAsync(id);
    setDeleteModal(!deleteModal);
    closeModal();
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={{ ...customStyles, content: appliedStyles }}
    >
      <div className="overflow-hidden">
        <div className="flex justify-between items-center mb-4">
          <h3 className="font-bold">Ready Stock</h3>
          <button onClick={closeModal} className="text-pink-600">
            {/* You can use your cross button icon here */}
            <img src={option_ic_close_arrow} alt="Close" className="w-4 h-4" />
          </button>
        </div>
        <div className="flex gap-2 w-full  h-44 border-2 border-dashed border-[#F64668] mt-4 p-2 overflow-x-auto overflow-y-hidden">
          <label
            className={`flex flex-col items-center px-12 py-10 tracking-wide uppercase transition-colors duration-200 bg-white border border-blue-400 rounded-md shadow-md cursor-pointer hover:bg-blue-50 text-blue-400 ease select-none focus:outline-none`}
          >
            {imageUpload.isLoading || dailyFeedLoading ? (
              <Spinner animation="border" size="lg" className="" />
            ) : (
              <>
                <img
                  className="upload-ic"
                  src={upload_file_ic}
                  alt=""
                  style={{ width: "24px", height: "24px" }}
                />
                <span className="mt-1 text-sm  leading-normal text-pink-600">
                  Select
                </span>
                <input
                  type="file"
                  className="hidden"
                  onChange={handleFileInputChange}
                  multiple
                />
              </>
            )}
          </label>
          <div className="relative z-20">
            <ul className="flex gap-8">
              {selectedImages.map((img, index) => (
                <li className="w-44 aspect-w-1 aspect-h-1 relative bg-opacity-54 border-dashed border-[#B0B0B0] rounded-xl overflow-hidden">
                  <button className="absolute top-2 right-2 bg-transparent border-none">
                    <img
                      onClick={() => handleRemoveImage(index)}
                      src={option_ic_close_arrow}
                      alt=""
                      className="max-w-1 filter hover:invert-[56%] hover:sepia-[58%] hover:saturate-[7007%] hover:hue-rotate-[324deg] hover:brightness-[100%] hover:contrast-[93%]"
                    />
                  </button>
                  <img
                    className="w-full h-36 object-cover"
                    src={
                      img.uploadedUrl.includes("http")
                        ? img.uploadedUrl
                        : img.url
                    }
                    alt=""
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="mt-4 flex gap-4">
          <h6 className="flex gap-2">
            Likes : <span className="text-primary-pink">{feedData.likes}</span>
          </h6>
          <h6 className="flex gap-2">
            Views : <span className="text-primary-pink">{feedData.views}</span>
          </h6>
        </div>
        <div className="w-full mt-4">
          <label
            htmlFor="note"
            className="block text-lg font-bold text-gray-700"
          >
            Caption
          </label>
          <textarea
            id="note"
            rows="2"
            className="mt-1 p-1 w-full border rounded-md"
            onChange={handleOnChange}
            value={caption}
          ></textarea>
        </div>
        <div className="flex justify-between mt-4">
          <div className="action-img" onClick={handleDeleteModal}>
            <button className="bg-primary-pink text-white px-3 py-3 rounded-md whitespace-nowrap">
              Delete
            </button>
          </div>
          <div className="action-img" onClick={editDailyFeed}>
            <button className="btn-dashboard outline-btn">Edit</button>
          </div>
        </div>
      </div>
      <DeleteConfirmationModal
        isOpen={deleteModal}
        closeModal={handleDeleteModal}
        id={feedData._id}
        handleDeleteConfirmation={handleDeleteConfirmation}
        title={`Do you really wish to ignore this enquiry?`}
      />
    </Modal>
  );
};

export default EditDailyFeedModal;
