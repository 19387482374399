import { useState } from 'react'
import { useForm } from 'react-hook-form';
// import { useSendQuotationMutation } from '../../../hooks/enquiry'
import upload_file_ic from '../../../../assets/images/icons/upload_file_ic.svg'
import { RxCross2 } from "react-icons/rx";
import { useJewelleryInquiry, useSendQuotationMutation } from '../../../../hooks/enquiry';
const API_URL = process.env.REACT_APP_BASE_URL;

const InquiryForm = ({ inquiry }) => {
    const sendQuotation = useSendQuotationMutation()
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const [isPreviewOpen, setPreviewOpen] = useState(false);
    // const { data, error, isLoading, refetch } = useJewelleryInquiry(,1);
  
    const {
        register,
        handleSubmit,
        reset,
        trigger,
        formState: { errors }
    } = useForm();

    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append("inquiry_id", inquiry._id);
        formData.append("jewellery_images", data.photo[0]);
        formData.append("jewellery_wastage", data.wastage);
        formData.append("charges", data.charges);
        formData.append("delivery_time", data.delivery);
        formData.append("note", data.note);
        formData.append("weight", data.weight);

        await sendQuotation.mutate(formData);
        // refetch()
        reset()
    };

    function handleImageChange(e) {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                setImagePreviewUrl(reader.result);
            };

            reader.readAsDataURL(file);
        } else {
            setImagePreviewUrl(null);
        }
        trigger("photo");
    }
    const togglePreview = () => {
        setImagePreviewUrl(imagePreviewUrl);
        setPreviewOpen(!isPreviewOpen);
    };
    const clearImage = () => {
        setImagePreviewUrl(null);
    }
    console.log('inquiry', inquiry)
    return (
        <form className="lg:flex gap-2 w-full" onSubmit={handleSubmit(onSubmit)} key={inquiry.id}>
            <div className='w-full md:w-3/4'>
                {/* <h4 className="text-lg font-semibold">Send Quotation</h4> */}
                <div className='md:flex gap-2'>
                    {
                        inquiry.is_ask_weight &&
                        <div className='flex-1'>
                            <label htmlFor="weight" className="block text-base font-bold text-gray-700  whitespace-nowrap">
                                Weight
                            </label>
                            <input
                                type="text"
                                id="weight"
                                className={`mt-1 h-8 p-1 w-full cursor-not-allowed rounded-md ${errors.weight ? 'border-2 border-red-700' : 'border'}`}
                                {...register("weight", { required: inquiry.is_ask_weight && true, value: inquiry?.weight })}
                                disabled
                            />
                            {errors.weight && <p className='text-red-600 text-sm'>Weight is required</p>}
                        </div>
                    }
                    <div className='flex-1'>
                        <label htmlFor="wastage" className="block text-base font-bold text-gray-700">
                            Wastage
                        </label>
                        <input
                            type="text"
                            id="wastage"
                            className={`mt-1 h-8 p-1 w-full cursor-not-allowed  rounded-md ${errors.wastage ? 'border-2 border-red-700' : 'border'}`}
                            {...register("wastage", { required: true })}
                            disabled
                        />
                        {errors.wastage && <p className='text-red-600 text-sm'>Wastage is required</p>}
                    </div>
                    <div className='flex-1'>
                        <label htmlFor="otherCharges" className="block text-base font-bold text-gray-700  whitespace-nowrap">
                            Other Charges
                        </label>
                        <input
                            type="text"
                            id="otherCharges"
                            className={`mt-1 h-8 p-1 w-full cursor-not-allowed rounded-md  ${errors.charges ? 'border-2 border-red-700' : 'border'}`}
                            {...register("charges", { required: true, value: inquiry?.quotation?.charges })}
                            disabled
                        />
                        {errors.charges && <p className='text-red-600 text-sm'>Charges is required</p>}
                    </div>
                    <div className='flex-1'>
                        <label htmlFor="deliveryDays" className="block text-base font-bold  text-gray-700 whitespace-nowrap">
                            Delivery in Days
                        </label>
                        <input
                            type="text"
                            id="deliveryDays"
                            className={`mt-1 h-8 p-1 w-full rounded-md cursor-not-allowed ${errors.delivery ? 'border-2 border-red-700' : 'border'}`}
                            {...register("delivery", { required: true,value: inquiry?.quotation?.delivery_time  })}
                            disabled
                        />
                        {errors.delivery && <p className='text-red-600 text-sm'>Delivery in days is required</p>}
                    </div>
                </div>
                <div className='w-full mt-2'>
                    <label htmlFor="note" className="block text-base font-bold text-gray-700">
                        Note
                    </label>
                    <textarea
                        id="note"
                        rows="2"
                        className="mt-1 p-1 w-full border cursor-not-allowed rounded-md "
                        {...register("note",{value: inquiry?.quotation?.note})}
                        disabled
                    ></textarea>
                </div>
            </div>
            {/* <div className='flex-grow'>
                <div className="flex items-center justify-center">
                    <div className="flex flex-row items-center p-3 space-x-4 rounded-md">
                        {imagePreviewUrl ? (
                            <div className="relative w-32 h-32 border rounded-lg">
                                <img src={imagePreviewUrl} onClick={togglePreview} className="w-full h-full object-cover object-center" alt="Photo Preview" />
                                <div className="absolute top-0 cursor-pointer right-0 bg-gray-400 rounded-full p-2"
                                    onClick={clearImage}
                                >
                                    <RxCross2 />
                                </div>
                            </div>
                        ) : (
                            <>
                                <label
                                    className={`flex flex-col items-center p-2 tracking-wide uppercase transition-colors duration-200 bg-white border border-blue-400 rounded-md shadow-md cursor-pointer hover:bg-blue-50 text-blue-400 ease select-none focus:outline-none`}
                                >
                                    <img className='upload-ic' src={upload_file_ic} alt="" style={{ width: '24px', height: '24px' }} />
                                    <span className="mt-1 text-sm font-bold leading-normal text-pink-600">
                                        Select a file
                                    </span>
                                    <input type='file' className="hidden"
                                        {...register("photo")}
                                        onChange={handleImageChange}
                                    />
                                </label>
                            </>
                        )}
                    </div>
                </div>

                <div className='flex justify-center items-center'>
                    <button className='bg-primary-pink text-white px-2 py-1 rounded-md mt-4 whitespace-nowrap'>Send Quotation</button>
                </div>
            </div>
            {isPreviewOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50" onClick={togglePreview}>
                    <img src={imagePreviewUrl} className="object-contain max-w-4/5 max-h-4/5" alt="Full Screen Preview" />
                </div>
            )} */}
        </form>
    );
}

export default InquiryForm;