import React from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FAILED_IC from '../../assets/images/icons/failed_ic.svg'

// function Failed() {
//  const Toast = ()=>{
//     return(
//        <p className='text-danger'><span><img src={FAILED_IC} alt="" /></span> Your attempt to login failed due to unpredictable circumstances please try again later!</p>
//     )
//  }
//     const notify = () => toast(<Toast/>, {closeOnClick: false});
//   return (
//     <div className='toaster danger'>
//         <button onClick={notify}>Notify!</button>
//         <ToastContainer
//        autoClose={1000}
//        hideProgressBar

//         />
//       </div>
//   )
// }

const Toast = ({message})=>{
   return(
      <p className='text-danger flex items-center'>
      <img src={FAILED_IC} alt="" className="align-middle" />
      <span className="align-middle ml-2">{message}</span>
  </p>
   )
}

export default Toast
