import React, { createContext, useContext, useReducer } from 'react';

// Create a context
const AppContext = createContext();

// Create a provider component
const AppProvider = ({ children }) => {
  const initialState = {
    data: null,
    uploadedLink: null,
  };

  const [state, dispatch] = useReducer((prevState, action) => {
    switch (action.type) {
      case 'UPDATE_DATA':
        return { ...prevState, data: action.payload };
      case 'UPDATE_UPLOADED_LINK':
        return { ...prevState, uploadedLink: action.payload };
      default:
        return prevState;
    }
  }, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

// Create a custom hook to use the context
const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};

export { AppProvider, useAppContext };