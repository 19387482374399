import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import AccordionProduct from '../../Components/AccordionProduct'
import { useAppContext } from '../../appContext';
function InfoProduct() {
  const location = useLocation();
 
  return (
    <div className='common-layout'>
      <AccordionProduct productData={location?.state?.data} isView={location?.state?.isView}  />
    </div>
  )
}

export default InfoProduct
