import React, { useState } from "react";
import Orders from "./index";
import CompleteOrders from "./completed/index";

function OrderTabsCustomAnimation() {
  const data = [
    {
      label: "Pending",
      value: "pending",
      desc: <Orders />,
    },
    {
      label: "Completed",
      value: "completed",
      desc: <CompleteOrders />,
    },
  ];

  const [activeTab, setActiveTab] = useState("pending");

  return (
    <div className="common-layout">
      <div className="flex flex-col md:flex-row justify-between items-start mt-4">
        <div className="flex justify-between w-full items-center my-3">
          {/* <img src={ArrowBack} /> */}
          <h1 className="text-lg md:text-2xl ml-2 font-bold">Orders</h1>
          {/* <div className='flex justify-end px-8 cursor-pointer'> */}
          {/* <h3 className='mt-4'>All Catalogues</h3> */}

          {/* </div> */}
        </div>
      </div>
      <div className="border-b border-gray-200 ">
        {/* Tabs Header */}
        <div className="flex py-3">
          {data.map(({ label, value }) => (
            <button
              key={value}
              onClick={() => setActiveTab(value)}
              className={`w-1/2 md:w-1/3 inline-block px-4 py-2 text-gray-600 rounded shadow ${
                activeTab === value
                  ? "font-bold border-b-2 bg-primary-pink text-white"
                  : ""
              }`}
            >
              {label}
            </button>
          ))}
        </div>

        {/* Tabs Body */}
        <div className=" bg-white">
          {data.map(
            ({ value, desc }) =>
              activeTab === value && (
                <div key={value} className="tab-content">
                  {desc}
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );
}

export default OrderTabsCustomAnimation;
