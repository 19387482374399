import Modal from "react-modal";
import option_ic_close_arrow from "../assets/images/icons/option_ic_close_arrow.svg";
import { useForm } from "react-hook-form";
import { useSupplierRegister } from "../hooks/register";
const customStyles = {
  content: {
    top: "55%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%", // For mobile by default
    height: "auto",
    backgroundColor: "white", // Change this to a solid color
  },
  contentMobile: {
    width: "80%",
    height: "60%",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // This is the backdrop
  },
};

const RegisterModal = ({ isOpen, closeModal }) => {
  const isMobile = window.innerWidth <= 768;
  const registerMutation = useSupplierRegister();
  const appliedStyles = isMobile
    ? { ...customStyles.content, ...customStyles.contentMobile }
    : customStyles.content;

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const handleSubmitForm = async (data) => {
    if(!data) return
    await registerMutation.mutateAsync({
      business_name: data.business_name,
      phone: data.phone,
      email: data.email,
      city: data.city,
    });
    closeModal()
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={{ ...customStyles, content: appliedStyles }}
    >
      <form
        className="overflow-hidden"
        onSubmit={handleSubmit(handleSubmitForm)}
      >
        <div className="flex justify-between items-center mb-4">
          <h3 className="font-bold">Register</h3>
          <button onClick={closeModal} className="text-pink-600">
            {/* You can use your cross button icon here */}
            <img src={option_ic_close_arrow} alt="Close" className="w-4 h-4" />
          </button>
        </div>
        <div className="w-full">
          <div className="input-container">
            <div className="input-title">
              <p>
                <label>Business Name</label>
              </p>
            </div>
            <div className="input-field">
              <input
                type="text"
                name={"business_name"}
                placeholder="Please enter business name"
                {...register("business_name", {
                  required: "Business name is required",
                })}
                className={`${
                  errors.business_name ? "border-red-500" : "border-gray-300"
                } border-2 p-2 rounded-md w-full`}
              />
            </div>
            {errors.business_name && (
              <p className="text-danger">{errors.business_name.message}</p>
            )}
          </div>
        </div>
        <div className="w-full mt-4">
          <div className="input-container">
            <div className="input-title">
              <p>
                <label>Email</label>
              </p>
            </div>
            <div className="input-field">
              <input
                type="text"
                name={"email"}
                placeholder="Please enter email"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: "Invalid email address",
                  },
                })}
                className={`${
                  errors.business_name ? "border-red-500" : "border-gray-300"
                } border-2 p-2 rounded-md w-full`}
              />
            </div>
            {errors.email && (
              <p className="text-danger">{errors.email.message}</p>
            )}
          </div>
        </div>
        <div className="w-full mt-4">
          <div className="input-container">
            <div className="input-title">
              <p>
                <label>Mobile No</label>
              </p>
            </div>
            <div className="input-field">
              <input
                type="text"
                name={"phone"}
                placeholder="Please enter mobile no"
                {...register("phone", {
                  required: "Mobile number is required",
                  minLength: {
                    value: 10,
                    message: "Mobile number must have at least 10 digits",
                  },
                })}
                className={`${
                  errors.business_name ? "border-red-500" : "border-gray-300"
                } border-2 p-2 rounded-md w-full`}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </div>
            {errors.phone && (
              <p className="text-danger">{errors.phone.message}</p>
            )}
          </div>
        </div>
        <div className="w-full mt-4">
          <div className="input-container">
            <div className="input-title">
              <p>
                <label>City</label>
              </p>
            </div>
            <div className="input-field">
              <input
                type="text"
                name={"city"}
                placeholder="Please enter city"
                className={`${
                  errors.city ? "border-red-500" : "border-gray-300"
                } border-2 p-2 rounded-md w-full`}
                {...register("city", {
                  required: "City is required",
                })}
              />
            </div>
            {errors.city && (
              <p className="text-danger">{errors.city.message}</p>
            )}
          </div>
        </div>
        <div className="flex justify-center items-center mt-4 w-full">
          <button
            id="submit1"
            className="ml-2 px-2 py-2 md:px-4 md:py-3 border-2 border-jw-primary rounded-md text-jw-primary  flex justify-center items-center"
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default RegisterModal;
