import React from 'react'

const SummaryCard = ({ title, subtitle, Icon, className }) => {
    return (
        <div className={`border border-gray-300 md:mr-4 rounded-lg p-3 mt-3  ${className}`}>
          <div className="flex items-center">
            {Icon && <Icon className="w-8 h-8 mr-2 text-primary-pink" />}
            <h2 className="text-xl font-bold ml-5 md:ml-3">{title}</h2>
          </div>
          <div className="mt-2">
            <p className="text-gray-600 text-lg md:text-base whitespace-nowrap">{subtitle}</p>
          </div>
        </div>
      );
  };

export default SummaryCard