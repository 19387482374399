import React, { useState } from "react";
import CardSelectJwellery from "../../Components/Dashboard/CardSelectJwellery";
import looks_empty from "../../assets/images/icons/looks_empty.svg";
import UploadSection from "../../Components/Dashboard/UploadSection";
import ProdcutPreview from "../../Components/Dashboard/ProdcutPreview";
function UploadJwellery() {
  const [optionClicked, setOptionClicked] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState({});
  const handleDataReceived = (activeCard) => {
    setOptionClicked(activeCard);
  };
  return (
    <>
      <div className={`common-layout ${optionClicked ? "clicked-card" : ""}`}>
        <div className="upload-jwellery mt-10">
          <div className="upload-section-map md:mt-10 lg:mt-5">
            <span className="map-line"></span>
            <h5>Choose between below two option according to your need</h5>
            <CardSelectJwellery onDataReceived={handleDataReceived} />
            {optionClicked && <h5>Select category</h5>}
          </div>
        </div>
        <div className="upload-section-component">
          {optionClicked ? (
            <div className="row">
              <div className={`col-12 col-lg-8 ${!selectedProduct.title && 'col-lg-12'}`}>
                <div className="upload-jwellery">
                  <UploadSection
                    selectedOption={optionClicked}
                    selectedProduct={selectedProduct}
                    setSelectedProduct={setSelectedProduct}
                  />
                </div>
              </div>
                <div className="col-12 col-sm-6 col-lg-4 pt-4 pt-lg-0">
                  <ProdcutPreview selectedProduct={selectedProduct} />
                </div>
            </div>
          ) : (
            <div className="flex justify-center items-center">
               <img className="w-96 h-96" src={looks_empty} alt="" />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default UploadJwellery;
