import { useQuery, useMutation } from "react-query";
import axios from "axios";
import { toast } from "react-toastify";
import ToastFailed from "../Components/Toaster/Failed";
import Toast from "../Components/Toaster/Successed";

const API_URL = process.env.REACT_APP_BASE_URL;
export const useAddOrderMutation = (onSuccessCallback) => {
  const sendQuotation = useMutation(
    async (data) => {
      const response = await axios.post(`${API_URL}/order`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      return response.data;
    },
    {
      onSuccess: (data) => {
        toast(<Toast message={data.message} />);
        onSuccessCallback();
      },
      onError: (error) => {
        if (Array.isArray(error.response.data.message)) {
          error.response.data.message.forEach((element) => {
            toast(<ToastFailed message={element} />);
          });
        } else {
          toast(<ToastFailed message={error.response.data.message} />);
        }
      },
    }
  );

  return sendQuotation;
};

export const useUpdateOrderStatusMutation = (onSuccessCallback) => {
  const sendQuotation = useMutation(
    async ({ orderId, data }) => {
      const response = await axios.patch(
        `${API_URL}/order/status/${orderId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.data;
    },
    {
      onSuccess: (data) => {
        toast(<Toast message={data.message} />);
        onSuccessCallback();
      },
      onError: (error) => {
        if (Array.isArray(error.response.data.message)) {
          error.response.data.message.forEach((element) => {
            toast(<ToastFailed message={element} />);
          });
        } else {
          toast(<ToastFailed message={error.response.data.message} />);
        }
      },
    }
  );

  return sendQuotation;
};

export const useGetOrders = () => {
  const getOrdersMutation = useMutation(
    async ({ perPage, page, status }) => {
      const response = await axios.get(`${API_URL}/order`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
          accept: "*/*",
        },
        params: {
          status,
          page,
          perPage,
        },
      });
      return response.data;
    },
    {
      onSuccess: async (data) => {
        // navigate('/dashboard/home');
        // enqueueSnackbar('Product added successfully!', { variant: 'success',autoHideDuration: 3000 });
        // Handle success here
      },
      onError: (error) => {
        // Handle error here
        if (Array.isArray(error.response.data.message)) {
          error.response.data.message.forEach((element) => {
            toast(<ToastFailed message={element} />);
          });
        } else {
          toast(<ToastFailed message={error.response.data.message} />);
        }
      },
    }
  );

  return getOrdersMutation;
};
