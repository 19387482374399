import React from 'react'
import Modal from 'react-modal';
import { MdClose } from 'react-icons/md';

const SelectedJewelleryModal = ({ modalIsOpen, catalogueName, addJewellery, jewelleryList, afterOpenModal, closeModal, handleSelectJewellery, selectedJewellery }) => {
    const customStyles = {
        content: {
            top: '55%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            // width: '68%', // Set width to 100%
            height: '80%'

        },
    };

    const renderAddJewelleryList = (data) => (
        <div className={`flex flex-col md:flex-row bg-white shadow-md p-4 mb-4 cursor-pointer transition duration-300 ease-in-out transform ${selectedJewellery.findIndex(item => item._id === data._id) > -1 ? '' : 'hover:border hover:border-transparent hover:shadow-xl hover:cursor-pointer hover:border-jw-primary'} ${selectedJewellery.findIndex(item => item._id === data._id) > -1 ? 'border-2 border-jw-primary' : ''}`} onClick={() => handleSelectJewellery(data)}>
            <div className={`md:w-1/3 mb-4 md:mb-0 `} >
                <img
                    src={data.images[0]}
                    alt={data.basic_info.name}
                    className="w-full h-full md:h-24 object-cover rounded-md"
                />
            </div>
            <div className="md:w-2/3 md:ml-4">
                <h2 className="text-xl font-semibold text-gray-800 mb-2">{data.basic_info.name}</h2>

                <div className="flex flex-wrap mb-2">
                    <div className="flex items-center mr-4 mb-2">
                        <p className="font-bold mr-2">Jewecle Id:</p>
                        <p>{data.jewecle_id}</p>
                    </div>

                    <div className="flex items-center mr-4 mb-2">
                        <p className="font-bold mr-2">Tag ID:</p>
                        <p>{data.tag_id}</p>
                    </div>

                    <div className="flex items-center mb-2">
                        <p className="font-bold mr-2">Catalogue Name:</p>
                        <p>{catalogueName || '-'}</p>
                    </div>
                </div>

                <div className="flex flex-wrap mb-2">
                    <div className="flex items-center mr-4 mb-2">
                        <p className="font-bold mr-2">Weight:</p>
                        <p>{data.product_details.net_weight} grams</p>
                    </div>

                    <div className="flex items-center mr-4 mb-2">
                        <p className="font-bold mr-2">Wastage:</p>
                        <p>{data.wastage_details.wastage}</p>
                    </div>

                    <div className="flex items-center mr-4 mb-2">
                        <p className="font-bold mr-2">Size:</p>
                        <p>{data.product_details.size}</p>
                    </div>

                    <div className="flex items-center mb-2">
                        <p className="font-bold mr-2">Purity:</p>
                        <p>{data.product_details.purity}</p>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
        >
            <>
                <div className='sticky z-10 bg-white border-b-2'>
                    <div className='flex justify-between'>
                        <p className='text-2xl font-bold '>Selected Jewellery</p>
                        <div className='flex'>
                            <div
                                className="cursor-pointer bg-[rgba(236,143,138,0.21)] rounded-full border-solid border-[rgba(255,59,71,0.47)] border-2 p-1 w-20 flex flex-row  items-center justify-center"
                                style={{ boxShadow: "2px 2px 8px 0px rgba(86, 86, 86, 0.06)" }}
                                onClick={addJewellery}
                            >
                                <div
                                    className="cursor-pointer"
                                    onClick={closeModal}
                                >
                                    <MdClose size={24} color="#333" />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='mb-3'>
                        <input type="text" className='border-2 rounded-md p-2' placeholder='Search' />
                    </div>
                </div>
                <div className='flex flex-col justify-center gap-3 overflow-y-auto '>
                    {jewelleryList?.map((item) => (
                        renderAddJewelleryList(item)
                    ))}
                </div>
            </>
        </Modal>
    )
}

export default SelectedJewelleryModal