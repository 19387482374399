import React,{useContext,createContext, useState} from 'react'
import ThreeSteps from '../../Components/FrontView/ThreeSimpleStep'
import ZeroCharges from '../../Components/FrontView/ZeroCharges'
import WhyJwecle from '../../Components/FrontView/WhyJwecle'
import Learn from '../../Components/Learn/Learn'
import Support from '../../Components/Support/Support'
import Morquee from '../../Components/FrontView/Marquee'
import MainBanner from '../../Components/FrontView/MainBanner'

function Home() {
  return (
    <>
      {/* <Loader/> */}
      <MainBanner />
      <ThreeSteps />
      <ZeroCharges />
      <Morquee />
      <WhyJwecle />
      <Learn />
      <Support />
    </>
  )
}

export default Home
