import React, { useState } from "react";
import Logo from "../../assets/images/jeweclelogo.png";
import Arrow from "../../assets/images/arrow.png";
import BackArrow from "../../assets/images/icons/back_arrow.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useVerifyOtp, useVerifyPhone } from "../../hooks/register";
import { Spinner, Toast } from "react-bootstrap";
import { toast } from "react-toastify";

function LoginPhone() {
  const navigate = useNavigate();
  const dd = useLocation();
  // Handle Back Arrow
  const handleBackArrow = () => {
    navigate("/login");
  };

  const [phone, setPhone] = useState("");
  const [isMobileError, setIsMobileError] = useState("");

  //Mutation hooks
  const verifyPhoneMutation = useVerifyPhone();
  const verifyOtpMutation = useVerifyOtp();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    let res = await verifyOtpMutation.mutateAsync({
      otp: data.registerOTP,
      phone: data.phone,
    });
    localStorage.setItem("access_token", res.responseData.access_token);
    localStorage.setItem(
      "registration_step",
      res.responseData.registration_step
    );
    if (res.responseData.registration_step == 1) {
      navigate("/admin/supplier/sign-up-business");
    } else if (res.responseData.registration_step == 2) {
      navigate("/admin/supplier/address");
    } else if (res.responseData.registration_step == 3) {
      navigate("/dashboard");
    } else {
      navigate("/dashboard");
    }
  };

  //Handle send otp
  const handleSendOtp = () => {
    if (!phone) {
      setIsMobileError("Phone number is required");
    } else if (phone.length !== 10) {
      setIsMobileError("Phone number length should be 10");
    } else {
      verifyPhoneMutation.mutate({ phone: phone, is_login: true });
      setIsMobileError("");
    }
  };

  return (
    <>
      <div className="register-form form">
        <div className="form-content-container">
          <button className="back-btn" onClick={() => handleBackArrow()}>
            {" "}
            <img src={BackArrow} alt="" />
          </button>
          <div className="flex flex-col items-start">
            <img className="w-32 lg:w-48 mb-4" src={Logo} alt="site-logo" />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-content">
              <div className="form-heading">
                <h3>Login</h3>
              </div>
              <div className="input-container">
                <div className="input-title">
                  <p>
                    <label>
                      Mobile Number<span>*</span>
                    </label>
                  </p>
                </div>
                <div className="input-field">
                  <input
                    type="tel"
                    maxLength="10"
                    placeholder="Your mobile number"
                    name="phone"
                    {...register("phone", {
                      required: "Mobile number is required",
                      minLength: {
                        value: 10,
                        message: "Mobile number must have at least 10 digits",
                      },
                    })}
                    onChange={(e) => setPhone(e.target.value)}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <button
                    type="button"
                    className={
                      verifyPhoneMutation.isLoading
                        ? "primary-btn disable-button"
                        : "primary-btn"
                    }
                    onClick={handleSendOtp}
                    disabled={verifyPhoneMutation.isLoading ? true : false}
                  >
                    {verifyPhoneMutation.isLoading ? (
                      <>
                        <Spinner
                          animation="border"
                          size="sm"
                          className="me-2"
                        />
                        Loading...
                      </>
                    ) : (
                      "Send OTP"
                    )}
                  </button>
                </div>
                {isMobileError && (
                  <span className="text-danger">{isMobileError}</span>
                )}
              </div>
              <div className="input-container">
                <div className="input-title">
                  <p>
                    <label>
                      OTP<span>*</span>
                    </label>
                  </p>
                </div>
                <div
                  className={
                    verifyPhoneMutation.isSuccess
                      ? "input-field link"
                      : "input-field link disable-input"
                  }
                >
                  <input
                    placeholder="Enter OTP"
                    name="registerOTP"
                    {...register("registerOTP", {
                      required: "OTP is required",
                      minLength: {
                        value: 4,
                        message: "OTP must have at least 4 digits",
                      },
                    })}
                    disabled={!verifyPhoneMutation.isSuccess}
                  />
                  {/* <a href='#'>Resend OTP</a> */}
                </div>
                {errors.registerOTP && (
                  <p className="text-danger">{errors.registerOTP.message}</p>
                )}
              </div>
              <Link to="/login" className="normal-link p-lg mt-2 mb-2">
                {" "}
                <strong>Login with Email</strong>{" "}
              </Link>
              <div className="flex justify-center">
                <div>
                  <button
                    type="submit"
                    className={` mt-0
                  ${
                    verifyPhoneMutation.isSuccess
                      ? "primary-btn p-lg"
                      : "primary-btn p-lg disable-button"
                  }
                   `}
                    disabled={
                      verifyPhoneMutation.isLoading ||
                      !verifyPhoneMutation.isSuccess ||
                      verifyOtpMutation.isLoading
                    }
                  >
                    {verifyOtpMutation.isLoading ? (
                      <>
                        <Spinner
                          animation="border"
                          size="sm"
                          className="me-2"
                        />
                        Loading...
                      </>
                    ) : (
                      <>
                        Login
                        <img src={Arrow} alt="arrow-right" />
                      </>
                    )}
                  </button>
                  {/* <p className="mt-3">
                    New Member? <a href="#">Sign up</a>
                  </p> */}
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="form-poster-container form_bg login-poster d-none d-md-flex"></div>
      </div>
    </>
  );
}

export default LoginPhone;
