import { useMutation } from "react-query";
import axios from "axios";
import Successed from "../Components/Toaster/Successed";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Toast from "../Components/Toaster/Successed";
import ToastFailed from "../Components/Toaster/Failed";
const API_URL = process.env.REACT_APP_BASE_URL;

export const useVerifyPhone = () => {
  const navigate = useNavigate();
  const verifyPhoneMutation = useMutation(
    async (data) => {
      const response = await axios.post(`${API_URL}/user/verifyPhone`, data);
      return response.data;
    },
    {
      onSuccess: (data) => {
        if (data.statusCode === 201) {
          navigate("/login");
          toast(<Toast message={data.message} />);
        } else {
          toast(<Toast message={data.message} />);
        }
      },
      onError: (error) => {
        // Handle error here
        if (Array.isArray(error.response.data.message)) {
          error.response.data.message.forEach((element) => {
            toast(<ToastFailed message={element} />);
          });
        } else {
          toast(<ToastFailed message={error.response.data.message} />);
        }
      },
    }
  );

  return verifyPhoneMutation;
};

export const useVerifyOtp = () => {
  const verifyOtpMutation = useMutation(
    async (reqData) => {
      const response = await axios.post(`${API_URL}/user/verifyotp`, {
        otp: reqData.otp,
        phone: reqData.phone,
      });
      return response.data;
    },
    {
      onSuccess: (data) => {
        toast(<Toast message={data.message} />);
      },
      onError: (error) => {
        // Handle error here
        if (Array.isArray(error.response.data.message)) {
          error.response.data.message.forEach((element) => {
            toast(<ToastFailed message={element} />);
          });
        } else {
          toast(<ToastFailed message={error.response.data.message} />);
        }
      },
    }
  );

  return verifyOtpMutation;
};

export const useSubmitBasicInfo = () => {
  const navigate = useNavigate();
  const submitBasicInfoMutation = useMutation(
    async (data) => {
      const response = await axios.post(`${API_URL}/user/basicInfo`, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      return response.data;
    },
    {
      onSuccess: (data) => {
        localStorage.setItem("access_token", data.responseData.access_token);
        navigate("/admin/supplier/sign-up-business");
      },
      onError: (error) => {
        // Handle error here
        if (Array.isArray(error.response.data.message)) {
          error.response.data.message.forEach((element) => {
            toast(<ToastFailed message={element} />);
          });
        } else {
          toast(<ToastFailed message={error.response.data.message} />);
        }
      },
    }
  );

  return submitBasicInfoMutation;
};

export const useSubmitGSTInfo = () => {
  const navigate = useNavigate();
  const submitGSTInfoMutation = useMutation(
    async (data) => {
      const response = await axios.post(`${API_URL}/user/gstInfo`, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      return response.data;
    },
    {
      onSuccess: (data) => {
        if (data) {
          // localStorage.setItem('bussinessName', data?.responseData?.business_name)
          // localStorage.setItem('bussinessLogo', data?.responseData?.business_logo)
          // localStorage.setItem('bussinessCard', data?.responseData?.business_card)
          // localStorage.setItem('gstNo', data?.responseData?.gstin)
          // localStorage.setItem('hallMarkNo', data?.responseData?.hallmark_number)
          // localStorage.setItem('website', data?.responseData?.website)
          navigate("/admin/supplier/address");
        }
      },
      onError: (error) => {
        // Handle error here
        if (Array.isArray(error.response.data.message)) {
          error.response.data.message.forEach((element) => {
            toast(<ToastFailed message={element} />);
          });
        } else {
          toast(<ToastFailed message={error.response.data.message} />);
        }
      },
    }
  );

  return submitGSTInfoMutation;
};

export const useSubmitPickupAddress = () => {
  const navigate = useNavigate();
  const submitPickupAddressMutation = useMutation(
    async (data) => {
      const response = await axios.post(`${API_URL}/user/pickupAddress`, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      return response.data;
    },
    {
      onSuccess: (data) => {
        navigate("/preference");
      },
      onError: (error) => {
        // Handle error here
        if (Array.isArray(error.response.data.message)) {
          error.response.data.message.forEach((element) => {
            toast(<ToastFailed message={element} />);
          });
        } else {
          toast(<ToastFailed message={error.response.data.message} />);
        }
      },
    }
  );

  return submitPickupAddressMutation;
};

export const useSubmitBankInfo = () => {
  const navigate = useNavigate();
  const submitBankInfoMutation = useMutation(
    async (data) => {
      const response = await axios.post(`${API_URL}/user/bankInfo`, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      return response.data;
    },
    {
      onSuccess: (data) => {
        // navigate("/bankdetails");
      },
      onError: (error) => {
        // Handle error here
        if (Array.isArray(error.response.data.message)) {
          error.response.data.message.forEach((element) => {
            toast(<ToastFailed message={element} />);
          });
        } else {
          toast(<ToastFailed message={error.response.data.message} />);
        }
      },
    }
  );

  return submitBankInfoMutation;
};

export const useSubmitPreferenceInfo = () => {
  const navigate = useNavigate();
  const submitPreferenceInfoMutation = useMutation(
    async (data) => {
      const response = await axios.post(
        `${API_URL}/user/save-preferences`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      return response.data;
    },
    {
      onSuccess: (data) => {
        navigate("/dashboard");
      },
      onError: (error) => {
        // Handle error here
        if (Array.isArray(error.response.data.message)) {
          error.response.data.message.forEach((element) => {
            toast(<ToastFailed message={element} />);
          });
        } else {
          toast(<ToastFailed message={error.response.data.message} />);
        }
      },
    }
  );

  return submitPreferenceInfoMutation;
};


export const useSupplierRegister = () => {
  const navigate = useNavigate();
  const supplierRegisterMutation = useMutation(
    async (data) => {
      const response = await axios.post(`${API_URL}/supplier-inquiry`, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          // Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      return response.data;
    },
    {
      onSuccess: (data) => {
        toast(<Toast message={data.message} />);
      },
      onError: (error) => {
        // Handle error here
        if (Array.isArray(error.response.data.message)) {
          error.response.data.message.forEach((element) => {
            toast(<ToastFailed message={element} />);
          });
        } else {
          toast(<ToastFailed message={error.response.data.message} />);
        }
      },
    }
  );

  return supplierRegisterMutation;
};