import React from 'react'
import { Container,Col,Row } from 'react-bootstrap'
import HeaderTitle from '../HeaderTitle/HeaderTitle';

function DigitalExhibition() {
  return (
    <div className='list join-us digital' >
        <Container>
        <div className="step-desc">
        <h2 className='h2-sm'>Digital Exhibition</h2>
        </div>
            <Row>
            <Col md={6}>
                    <div className='list-poster'>

                    </div>
                </Col>
                <Col md={5}>
                    <div className='list-content'>
                        <ul>
                            <li>
                                <div className='list-icon'>
                                </div>
                                <div className='list-details'>
                                    <h5>huge traffic</h5>
                                    <p>Participate in jewecle digital exhibition, which offers opportunity to showcase and sale your jewelry into huge retailer traffic.</p>
                                </div>
                            </li>
                            <li>
                                <div className='list-icon'>
                                </div>
                                <div className='list-details'>
                                    <h5>personal unique website</h5>
                                  <p>as physical jewelry exhibition, jewecle exhibition also provides you a facility to appear with unique personalize website and content that makes you more attractive as a brand.</p>
                                </div>
                            </li>
                            <li>
                                <div className='list-icon'>
                                </div>
                                <div className='list-details'>
                                    <h5>increase your turnover</h5>
                                  <p>grab the benefits of exhibition traffic and fulfill your orders. exhibition leads you to more sales and directly more turnover.</p>
                                </div>
                            </li>
                            <li>
                                <div className='list-icon'>
                                </div>
                                <div className='list-details'>
                                    <h5>500+ daily connections</h5>
                                    <p>more than 500+ active retailers can see your products in exhibition. that means more closer to every retailer.</p>
                                </div>
                            </li>
                            <li>
                                <div className='list-icon'>
                                </div>
                                <div className='list-details'>
                                    <h5>lower cost - higher marketing</h5>
                                    <p>digital exhibition participation is cheaper than physical exhibition. that means lower cost branding and higher traffic marketing.</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </Col>
               
            </Row>
        </Container>
    </div>
  )
}

export default DigitalExhibition
