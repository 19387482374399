import React, { useState, useEffect } from 'react'

const Dropdown = ({ isOpen, onClose, onMyProfileClick, onLogoutClick }) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
          if (isOpen && !event.target.closest('.dropdown-options')) {
            onClose();
          }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [isOpen, onClose]);
    
  return (
    <div className={`dropdown-options ${isOpen ? 'block' : 'hidden'} absolute right-0 mt-2 bg-white border border-gray-300 rounded shadow-sm`}>
      <button className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100" onClick={onMyProfileClick}>
        My Profile
      </button>
      <button className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100" onClick={onLogoutClick}>
        Logout
      </button>
    </div>
  )
}

export default Dropdown