import { useQuery, useMutation } from 'react-query';
import axios from 'axios';
import { toast } from 'react-toastify';
import ToastFailed from '../Components/Toaster/Failed';


const API_URL = process.env.REACT_APP_BASE_URL;

export const useGetUserProfile = () => {
    const getProfile = useMutation(
        async () => {
            const response = await axios.post(`${API_URL}/user/profile`,{}, {
                headers: {
                    'Authorization': `bearer ${localStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json',
                    'accept': '*/*'
                }
            });
            return response.data;
        },
        {
            onSuccess: async (data) => {
                // navigate('/dashboard/home');
                // enqueueSnackbar('Product added successfully!', { variant: 'success',autoHideDuration: 3000 });
                // Handle success here
            },
            onError: (error) => {
                // Handle error here
                if (Array.isArray(error.response.data.message)) {
                    error.response.data.message.forEach(element => {
                        toast(<ToastFailed message={element} />)
                    });
                } else {
                    toast(<ToastFailed message={error.response.data.message} />)
                }
            },
        }
    );

    return getProfile;
};
