import React, { useState } from "react";
import minThumb from "../../../assets/images/minThumb.jpg";
import dot_toggle from "../../../assets/images/icons/dot_toggle.png";
import replace_ic from "../../../assets/images/icons/replace_ic.svg";
import delete_ic from "../../../assets/images/icons/delete_ic.svg";

function MultipleProduct({ product }) {
  const [toggle, setToggle] = useState(false);
  const [toggleId, setToggleId] = useState(null);
  const toggleHandle = (id) => {
    setToggle(toggleId == id ? !toggle : true);
    setToggleId(id);
  };
  return (
    <div className="Uploadedproduct">
      <div className="row">
        <div className="col-12">
          <div className="multiple">
            <div className="product-detail">
              <div className="grid grid-cols-2 md:grid-cols-5 gap-3">
                {product?.selectedImages?.map((image, index) => (
                  <>
                    <div
                      key={index}
                      className="relative bg-gray-200 p-0 mx-2 my-1 rounded-lg shadow-md flex justify-center items-center h-36 w-32"
                    >
                      <div className="cursor-pointer flex flex-col justify-center items-center w-full h-full">
                        <img
                          src={image.url}
                          alt=""
                          className="w-full h-full object-cover rounded-t-lg"
                        />
                        {/* <div className="flex p-1">
                          <button className="flex items-center hover:bg-gray-100 rounded-t-lg">
                            <img src={replace_ic} alt="" className="mr-2" />
                          </button>
                          <button className="flex items-center hover:bg-gray-100 rounded-b-lg">
                            <img src={delete_ic} alt="" className="mr-2" />
                          </button>
                        </div> */}
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MultipleProduct;

{
  /* Buttons Container */
}
{
  /* <div className="absolute bottom-0 right-0">
                      <button
                        onClick={() => toggleHandle(index)}
                        className="relative"
                      >
                        <img src={dot_toggle} alt="" />
                        {toggle && index === toggleId && (
                          <div className="toggle-action absolute bottom-full right-0 bg-white border border-gray-200 rounded-lg shadow-md mt-2">
                            <button className="flex items-center p-2 hover:bg-gray-100 rounded-t-lg">
                              <img src={replace_ic} alt="" className="mr-2" />
                              Replace
                            </button>
                            <button className="flex items-center p-2 hover:bg-gray-100 rounded-b-lg">
                              <img src={delete_ic} alt="" className="mr-2" />
                              Delete
                            </button>
                          </div>
                        )}
                      </button>
                    </div> */
}
