import React from 'react'
import MorqueeIMG1 from '../../assets/images/jwl_img1.jpg'
import MorqueeIMG2 from '../../assets/images/jwl_img2.jpg'

import MorqueeIMG3 from '../../assets/images/jwl_img3.jpg'


function Morquee() {
  return (
   <>
   <div className='morquee'>
    <div className="container">
     <marquee width='100%' behavior="scroll" direction="left">
     <div className="morquee-wraper">
        <div className="marquee-item">
          <img src={MorqueeIMG1} alt="" />
        </div>
        <div className="marquee-item">
          <img src={MorqueeIMG2} alt="" />
        </div>
        <div className="marquee-item">
          <img src={MorqueeIMG3} alt="" />
        </div>
        <div className="marquee-item">
          <img src={MorqueeIMG1} alt="" />
        </div>
       </div>
     </marquee>
    </div>
   </div>
   </>
  )
}

export default Morquee
