import React from "react";
import { useForm } from "react-hook-form";
import { useLoginUser } from "../../hooks/login";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/jeweclelogo.png";
import Arrow from "../../assets/images/arrow.png";
import BackArrow from "../../assets/images/icons/back_arrow.svg";
import Spinner from "react-bootstrap/Spinner";
import { useForgotPassword } from "../../hooks/common";

function ForgotPassword() {
  //Mutation hooks
  const ForgotPasswordMutation = useForgotPassword();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const onSubmit = (data) => {
    ForgotPasswordMutation.mutate(data);
  };

  // Handle Back Arrow
  const handleBackArrow = () => {
    navigate("/login");
  };
  return (
    <>
      <div className="register-form form">
        <div className="form-content-container">
          <button className="back-btn" onClick={() => handleBackArrow()}>
            {" "}
            <img src={BackArrow} alt="" />
          </button>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="">
              <div className="flex flex-col items-start">
                <img className="w-32 lg:w-48 mb-4" src={Logo} alt="site-logo" />
              </div>
              <div className="form-heading ">
                <h3>Forgot Password</h3>
              </div>
              <div className="input-container mt-4 mb-5">
                <div className="input-title">
                  <p>
                    <label>Email</label>
                  </p>
                </div>
                <div className="input-field">
                  <input
                    type="email"
                    placeholder="Enter Registered Email"
                    name="email"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: "Invalid email address",
                      },
                    })}
                  />
                </div>
                {errors.email && (
                  <p className="text-danger">{errors.email.message}</p>
                )}
              </div>
              <div>
                {/* <button className='primary-btn ' type='submit'>Login
              </button> */}
                <button
                  type="submit"
                  className={
                    ForgotPasswordMutation.isLoading
                      ? "primary-btn disable-button p-lg w-100"
                      : "primary-btn p-lg w-100"
                  }
                  disabled={ForgotPasswordMutation.isLoading ? true : false}
                >
                  {ForgotPasswordMutation.isLoading ? (
                    <>
                      <Spinner animation="border" size="sm" className="me-2" />
                      Loading...
                    </>
                  ) : (
                    "Send"
                  )}
                </button>
                {/* <p className="text-center mt-3">
                    New Member? <Link to="/register">Sign up</Link>
                  </p> */}
              </div>
            </div>
          </form>
        </div>
        <div className="form-poster-container form_bg login-poster d-none d-md-flex"></div>
      </div>
    </>
  );
}

export default ForgotPassword;
