import React from 'react'
import ads_ic1 from '../../assets/images/icons/ads_ic1.svg'
import ads_ic2 from '../../assets/images/icons/ads_ic2.svg'
import ads_ic3 from '../../assets/images/icons/ads_ic3.svg'
import ads_ic4 from '../../assets/images/icons/ads_ic4.svg'
import ads_ic5 from '../../assets/images/icons/ads_ic5.svg'
import ads_ic6 from '../../assets/images/icons/ads_ic6.svg'

function Advertisements() {
  return (
    <>
      <section className='advertisements'>
        <div className="container">
          <div className="ad-description">
            <h1>Making brand with jewecle in <br />
              <span className="pink-color-text">2 simple steps</span></h1>
            <p><span className="pink-color-text">jewecle digital exhibition</span> is the opportunity to every wholeseller to market their jewelry in huge retailer traffic. this exhibition will change <span className="pink-color-text"> everyone’s perception</span> about the whole jewelry market. with <span className="pink-color-text">personalize digital website appearance</span>, one can connect with more retailers.
            </p>
            <p>now everyone should know, <span className="pink-color-text">who you are</span> and <span className="pink-color-text">what you offer.</span></p>
          </div>
          <h2 className="h2-sm">Advertisements</h2>
          <div className="row">
            <div className="col-sm-6 col-lg-4">
              <div className="ad-box">
                <div className="icon-box">
                  <img src={ads_ic1} alt="" />

                </div>
                <h5>Boost your visibility</h5>
                <p>advertising on jewecle boosts your jewelry catalogue, and make it visible to more number of retailers, that leads to more clicks and more sales.</p>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="ad-box">
                <div className="icon-box">
                  <img src={ads_ic2} alt="" />

                </div>
                <h5>increase your sales</h5>
<p>Advertising builds you as a brand that leads to higher number of orders and indirectly higher sales.</p>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="ad-box">
                <div className="icon-box">
                  <img src={ads_ic3} alt="" />

                </div>
                <h5>build digital brand</h5>
                <p>Advertising will convert your dukan to brand as it appears as top leading seller on jewecle . It’s time to go digitally.</p>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="ad-box">
                <div className="icon-box">
                  <img src={ads_ic4} alt="" />

                </div>
                <h5>More Likes</h5>
                <p>Higher visibility of jewelry can attract new customers and their interest in you. you will get more social media presence by it.</p>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="ad-box">
                <div className="icon-box">
                  <img src={ads_ic5} alt="" />

                </div>
                <h5>control costs</h5>
                <p>jewecle is the platform of smart advertising where you can eventually control your advertisement cost. that means more trusted bond between us.</p>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="ad-box">
                <div className="icon-box">
                  <img src={ads_ic6} alt="" />

                </div>
                <h5>attract new customers</h5>
                <p>advertising and more visibility of your products can attract unlimited traffic on your catalogues, that creates opportunity to build new customers.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Advertisements
