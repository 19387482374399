import { LuUpload } from "react-icons/lu";
import { HiMiniUserGroup } from "react-icons/hi2";
import { BsCartCheckFill } from "react-icons/bs";
import { FaHeart } from "react-icons/fa6";
import { FaEye } from "react-icons/fa";
import { LuMousePointerClick } from "react-icons/lu";

export const PERFORMANCE_DETAILS = [
  {
    icon: "/tick.svg",
    title: "75%",
    subtitle: "Total Completed Orders",
  },
  {
    icon: "/percentage.svg",
    title: "50%",
    subtitle: "Last Month Sales",
  },
  {
    icon: "/star.svg",
    title: "500",
    subtitle: "Total Ratings",
  },
];

export const COLUMNS = [
  { name: "JEWELLERY", uid: "jewellery" },
  { name: "WEIGHT", uid: "weight" },
  { name: "RETAILER", uid: "jeweller" },
  { name: "STATUS", uid: "status" },
  { name: "ACTIONS", uid: "actions" },
];

export const TOP_CLICKED_JEWELLERY = [
  { name: "IMAGE", uid: "image" },
  { name: "JEWELLERY NAME", uid: "jewellery_name" },
  { name: "JEWECLE ID", uid: "jewecle_id" },
  { name: "NET WEIGHT", uid: "net_weight" },
];

export const TOP_CLICKED_JEWELLERY_DATA = [
  {
    id: "1",
    image:
      "https://images-cdn.ubuy.co.in/65ba09a4a1a9475552347fa3-women-s-18-k-rose-gold-micro-inlaid.jpg",
    jewellery_name: "Ring",
    tag_id: "CHG001",
    jewecle_id: "B2BYR",
    net_weight: "5200 GRMS",
  },
  {
    id: "2",
    image: "https://www.candere.com/media/jewellery/images/C008923__1.jpeg",
    jewellery_name: "Ring",
    tag_id: "CHG001",
    jewecle_id: "B2BYR",
    net_weight: "5200 GRMS",
  },
  {
    id: "3",
    image:
      "https://assets.ajio.com/medias/sys_master/root/20210810/JHp8/61127ee17cdb8cb824f27c5f/-473Wx593H-460963492-yellowgold-MODEL.jpg",
    jewellery_name: "Ring",
    tag_id: "CHG001",
    jewecle_id: "B2BYR",
    net_weight: "5200 GRMS",
  },
  {
    id: "4",
    image:
      "https://www.sparklejewels.in/wp-content/uploads/2022/12/RI-23005-Yellow1-600x600.jpg",
    jewellery_name: "Ring",
    tag_id: "CHG001",
    jewecle_id: "B2BYR",
    net_weight: "5200 GRMS",
  },
];

export const USERS = [
  {
    id: 1,
    name: "Ladies Ring",
    weight: "1.5",
    team: "Management",
    status: "active",
    age: "29",
    avatar:
      "https://freepngimg.com/download/jewellery/155811-necklace-jewellery-free-hq-image.png",
    purity: "22k916",
    color: "Gold",
  },
  {
    id: 2,
    name: "Ladies Ring",
    weight: "5",
    team: "Development",
    status: "paused",
    age: "25",
    avatar:
      "https://freepngimg.com/download/jewellery/155811-necklace-jewellery-free-hq-image.png",
    purity: "22k916",
    color: "Gold",
  },
  {
    id: 3,
    name: "Ladies Ring",
    weight: "6",
    team: "Development",
    status: "active",
    age: "22",
    avatar:
      "https://freepngimg.com/download/jewellery/155811-necklace-jewellery-free-hq-image.png",
    purity: "22k916",
    color: "Gold",
  },
  {
    id: 4,
    name: "Ladies Ring",
    weight: "3",
    team: "Marketing",
    status: "vacation",
    age: "28",
    avatar:
      "https://freepngimg.com/download/jewellery/155811-necklace-jewellery-free-hq-image.png",
    purity: "22k916",
    color: "Gold",
  },
  {
    id: 5,
    name: "Ladies Ring",
    weight: "4",
    team: "Sales",
    status: "active",
    age: "24",
    avatar:
      "https://freepngimg.com/download/jewellery/155811-necklace-jewellery-free-hq-image.png",
    purity: "22k916",
    color: "Gold",
  },
];

export const summaryDetails = (totalCounts) => {
  return [
    {
      icon: LuUpload,
      title: totalCounts?.uploaded_Jewellery || "0",
      subtitle: "Uploaded Jewellery",
    },
    {
      icon: HiMiniUserGroup,
      title: totalCounts?.retailer_count || "0",
      subtitle: "Connected Retailers",
    },
    {
      icon: BsCartCheckFill,
      title: totalCounts?.order_count || "0",
      subtitle: "Completed Orders",
    },
    {
      icon: FaHeart,
      title: totalCounts?.likes_count || "0",
      subtitle: "Total Likes",
    },
    {
      icon: FaEye,
      title: totalCounts?.views_count || "0",
      subtitle: "Total Views",
    },
    {
      icon: LuMousePointerClick,
      title: totalCounts?.click_count || "0",
      subtitle: "Total Clicks",
    },
  ];
};

export const percentages = [
  {
    id: 1,
    title: "50%",
    subtile: "Jadtar Mangalsutra",
  },
  {
    id: 2,
    title: "30%",
    subtile: "Handmade Chain",
  },
  {
    id: 3,
    title: "10%",
    subtile: "Ladies Ring",
  },
];

export const segments = [
  { value: 80, color: "#3498db" },
  { value: 20, color: "#f39c12" },
];
