import { useMutation } from 'react-query';
import axios from 'axios';
import Successed from '../Components/Toaster/Successed';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Toast from '../Components/Toaster/Successed';
import ToastFailed from '../Components/Toaster/Failed';


const API_URL = process.env.REACT_APP_BASE_URL;

export const useAddCatalogue = () => {
    const navigate = useNavigate();
    const AddCatalogueMutation = useMutation(
        async (data) => {
            const response = await axios.post(`${API_URL}/catalogue`, data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            });
            return response.data;
        },
        {
            onSuccess: (data) => {
                // Handle successful login here
                navigate('/dashboard/catalogue')
                
            },
            onError: (error) => {
                console.error(error);
                // Handle error here
                if (Array.isArray(error.response.data.message)) {
                    error.response.data.message.forEach(element => {
                        toast(<ToastFailed message={element} />)
                    });
                } else {
                    toast(<ToastFailed message={error.response.data.message} />)
                }
            },
        }
    );

    return AddCatalogueMutation;
};

export const useGetCatalogue = () => {
    const navigate = useNavigate();
    const GetCatalogueMutation = useMutation(
        async (data) => {
            const response = await axios.get(`${API_URL}/catalogue`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            });
            return response.data;
        },
        {
            onSuccess: (data) => {
                // Handle successful login here
            },
            onError: (error) => {
                console.error(error);
                // Handle error here
                if (Array.isArray(error.response.data.message)) {
                    error.response.data.message.forEach(element => {
                        toast(<ToastFailed message={element} />)
                    });
                } else {
                    toast(<ToastFailed message={error.response.data.message} />)
                }
            },
        }
    );

    return GetCatalogueMutation;
};

export const useGetCatalogueJewellery = () => {
    const navigate = useNavigate();
    const GetCatalogueMutation = useMutation(
        async ({id,perPage,page}) => {
            const response = await axios.get(`${API_URL}/catalogue/${id}/${perPage}/${page}`,{
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            });
            return response.data;
        },
        {
            onSuccess: (data) => {
                // Handle successful login here
            },
            onError: (error) => {
                console.error(error);
                // Handle error here
                if (Array.isArray(error.response.data.message)) {
                    error.response.data.message.forEach(element => {
                        toast(<ToastFailed message={element} />)
                    });
                } else {
                    toast(<ToastFailed message={error.response.data.message} />)
                }
            },
        }
    );

    return GetCatalogueMutation;
};

export const useDeleteCatalogue = () => {
   
    const GetCatalogueMutation = useMutation(
        async ({id}) => {
            const response = await axios.delete(`${API_URL}/catalogue/${id}`,{
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            });
            return response.data;
        },
        {
            onSuccess: (data) => {
                // Handle successful login here
            },
            onError: (error) => {
                console.error(error);
                // Handle error here
                if (Array.isArray(error.response.data.message)) {
                    error.response.data.message.forEach(element => {
                        toast(<ToastFailed message={element} />)
                    });
                } else {
                    toast(<ToastFailed message={error.response.data.message} />)
                }
            },
        }
    );

    return GetCatalogueMutation;
};

export const useDeleteCatalogueJewellery = () => {
    const navigate = useNavigate();
    const DeleteCatalogueMutation = useMutation(
        async ({id,jewelleryId}) => {
            const response = await axios.delete(`${API_URL}/catalogue/${id}/${jewelleryId}`,{
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            });
            return response.data;
        },
        {
            onSuccess: (data) => {
                // Handle successful login here
            },
            onError: (error) => {
                console.error(error);
                // Handle error here
                if (Array.isArray(error.response.data.message)) {
                    error.response.data.message.forEach(element => {
                        toast(<ToastFailed message={element} />)
                    });
                } else {
                    toast(<ToastFailed message={error.response.data.message} />)
                }
            },
        }
    );

    return DeleteCatalogueMutation;
};

export const useAddCatalogueJewellery = () => {
    const navigate = useNavigate();
    const  AddCatalogueJewelleryMutation = useMutation(
        async ({id,jewelleryIds}) => {
            const response = await axios.post(`${API_URL}/catalogue/${id}/add-jewelleries`,{ jewelleryIds:jewelleryIds },{
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            });
            return response.data;
        },
        {
            onSuccess: (data) => {
                // Handle successful login here
            },
            onError: (error) => {
                console.error(error);
                // Handle error here
                if (Array.isArray(error.response.data.message)) {
                    error.response.data.message.forEach(element => {
                        toast(<ToastFailed message={element} />)
                    });
                } else {
                    toast(<ToastFailed message={error.response.data.message} />)
                }
            },
        }
    );

    return AddCatalogueJewelleryMutation;
};


export const useGetJewelleryNotInCatalogue = () => {
    const navigate = useNavigate();
    const  AddCatalogueJewelleryMutation = useMutation(
        async ({id,search}) => {
            const response = await axios.get(`${API_URL}/catalogue/${id}/jewelleries`,{
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                params:{
                    search:search
                }
            });
            return response.data;
        },
        {
            onSuccess: (data) => {
                // Handle successful login here
            },
            onError: (error) => {
                console.error(error);
                // Handle error here
                if (Array.isArray(error.response.data.message)) {
                    error.response.data.message.forEach(element => {
                        toast(<ToastFailed message={element} />)
                    });
                } else {
                    toast(<ToastFailed message={error.response.data.message} />)
                }
            },
        }
    );

    return AddCatalogueJewelleryMutation;
};