import React, { useState, useEffect } from 'react'
import { useGetUserProfile } from '../../hooks/profile'

const Profile = () => {
  const profile = useGetUserProfile();
  const [profileData, setProfileData] = useState()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await profile.mutateAsync();
        setProfileData(data.responseData)
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    fetchData();
  }, []);
  console.log('profileData', profileData)
  return (
    <div className="p-2 md:p-8 md:mt-8">
      <div className="bg-white shadow-md rounded-md p-4">
        <h2 className="text-2xl font-bold mb-4 text-pink-600 ">Your Profile</h2>
        {profileData ? (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="mb-2">
                <label htmlFor="full_name" className="text-gray-600 block mb-1">
                  Full Name:
                </label>
                <input
                  type="text"
                  id="full_name"
                  name="full_name"
                  value={`${profileData.firstName} ${profileData.lastName}`}
                  className="w-full p-2 border border-gray-300 rounded-md"
                  readOnly
                />
              </div>
              <div className="mb-2">
                <label htmlFor="phone" className="text-gray-600 block mb-1">
                  Phone:
                </label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  value={profileData.phone}
                  className="w-full p-2 border border-gray-300 rounded-md"
                  readOnly
                />
              </div>
              <div className="mb-2">
                <label htmlFor="email" className="text-gray-600 block mb-1">
                  Email:
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={profileData.email}
                  className="w-full p-2 border border-gray-300 rounded-md"
                  readOnly
                />
              </div>
              <div className="mb-2">
                <label htmlFor="business_name" className="text-gray-600 block mb-1">
                  Business Name:
                </label>
                <input
                  type="text"
                  id="business_name"
                  name="business_name"
                  value={profileData.business_name}
                  className="w-full p-2 border border-gray-300 rounded-md"
                  readOnly
                />
              </div>
              {/* Add more profile data fields as needed */}
            </div>
            <hr className='mt-4'></hr>
            {profileData?.pickup_details && (
              <>
                <h6 className='text-pink-600 mt-3'>Pickup Details</h6>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
                  <div className="mb-2">
                    <label htmlFor="shop_no" className="text-gray-600 block mb-1">
                      Shop No:
                    </label>
                    <input
                      type="text"
                      id="shop_no"
                      name="shop_no"
                      value={profileData.pickup_details.shop_no || '-'}
                      className="w-full p-2 border border-gray-300 rounded-md"
                      readOnly
                    />
                  </div>
                  <div className="mb-2">
                    <label htmlFor="street" className="text-gray-600 block mb-1">
                      Street:
                    </label>
                    <input
                      type="text"
                      id="street"
                      name="street"
                      value={profileData.pickup_details.street || '-'}
                      className="w-full p-2 border border-gray-300 rounded-md"
                      readOnly
                    />
                  </div>
                  <div className="mb-2">
                    <label htmlFor="city" className="text-gray-600 block mb-1">
                      City:
                    </label>
                    <input
                      type="text"
                      id="city"
                      name="city"
                      value={profileData.pickup_details.city || '-'}
                      className="w-full p-2 border border-gray-300 rounded-md"
                      readOnly
                    />
                  </div>
                  <div className="mb-2">
                    <label htmlFor="state" className="text-gray-600 block mb-1">
                      State:
                    </label>
                    <input
                      type="text"
                      id="state"
                      name="state"
                      value={profileData.pickup_details.state || '-'}
                      className="w-full p-2 border border-gray-300 rounded-md"
                      readOnly
                    />
                  </div>
                  <div className="mb-2">
                    <label htmlFor="landmark" className="text-gray-600 block mb-1">
                      Landmark:
                    </label>
                    <input
                      type="text"
                      id="landmark"
                      name="landmark"
                      value={profileData.pickup_details.landmark || '-'}
                      className="w-full p-2 border border-gray-300 rounded-md"
                      readOnly
                    />
                  </div>
                  <div className="mb-2">
                    <label htmlFor="pincode" className="text-gray-600 block mb-1">
                      Pincode:
                    </label>
                    <input
                      type="text"
                      id="pincode"
                      name="pincode"
                      value={profileData.pickup_details.pincode || '-'}
                      className="w-full p-2 border border-gray-300 rounded-md"
                      readOnly
                    />
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <div className="flex justify-center items-center p-4 mt-10">
            <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Profile