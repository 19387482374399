import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import vedeo_img from '../../assets/images/video_img.jpg'
import play_ic from '../../assets/images/icons/play_ic.svg'
function Learn() {
  return (
    <div className='learn'>
        <Container>
            <Row className='justify-content-center'>
                <Col md={11}>
                    <Row className='justify-content-between'>
                        <Col md={5}>
<div className="video-section">
<div className="border-box">
<img src={vedeo_img} alt="" />
</div>
    <a href="">
        <img src={play_ic} alt="" />
    </a>
</div>
                        </Col>
                        <Col md={6}>
                            <div className='learn-content'>
                                <h2>learn how to use jewecle and grow your business digitally</h2>
                                <h5>See full video of jewecle shikho for free</h5>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default Learn
