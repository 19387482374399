import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import ArrowBack from "../../../assets/images/icons/arrow_back.svg";
import TutorialVideo from "../../../Components/Dashboard/TutorialVideo";
import {
  useAddCatalogueJewellery,
  useDeleteCatalogue,
  useDeleteCatalogueJewellery,
  useGetCatalogue,
  useGetCatalogueJewellery,
  useGetJewelleryNotInCatalogue,
} from "../../../hooks/catalogue";
import JewelleryCard from "../../../Components/JewelleryCard/JewelleryCard";
import Modal from "react-modal";
import { useGetJewellery } from "../../../hooks/uploadJewellery";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { toast } from "react-toastify";
import ToastFailed from "../../../Components/Toaster/Failed";
const customStyles = {
  content: {
    top: "55%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    // width: '68%', // Set width to 100%
    // height: '80%'
    // border: 'none', // Remove the border around the modal
    background: "transparent", // Make the modal background transparent
    padding: "0", // Remove any default padding
  },
};
const customStylesJewellery = {
  content: {
    top: "55%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    // width: '43.9%', // Set width to 100%
    height: "80%",
  },
};
export default function CatalogueDetail() {
  const location = useLocation();
  const navigate = useNavigate();

  const [catalogueJewellery, setCatalogueJewellery] = useState([]);
  const [IsOpen, setIsOpen] = useState(false);
  const [AddJewelleryOpen, setAddJewelleryIsOpen] = useState(false);
  const [IsOpenCatalogueDelete, setIsOpenCatalogueDelete] = useState(false);
  const [jewelleryList, setJewelleryList] = useState([]);
  const [deleteId, setDeleteId] = useState();
  const [selectedJewellery, setSelectedJewellery] = useState([]);
  const [search, setSearch] = useState("");
  const GetCatalogueJewelleryMutation = useGetCatalogueJewellery();
  const DeleteCatalogueJewellery = useDeleteCatalogueJewellery();
  const DeleteCatalogue = useDeleteCatalogue();
  const AddCatalogueJewellery = useAddCatalogueJewellery();
  const GetJewelleryNotInCatalogueMutation = useGetJewelleryNotInCatalogue();
  useEffect(() => {
    const fetchCatalogueJewelleries = async () => {
      const data = await GetCatalogueJewelleryMutation.mutateAsync({
        id: location.state.id,
        page: 1,
        perPage: 30,
      });
      setCatalogueJewellery(data.responseData);
      // const jewelleryData = await GetJewelleryNotInCatalogueMutation.mutateAsync({id:location.state.id})
      // setJewelleryList(jewelleryData.responseData)
    };
    fetchCatalogueJewelleries();
  }, []);

  useEffect(() => {
    const fetchCatalogueJewelleries = async () => {
      const jewelleryData =
        await GetJewelleryNotInCatalogueMutation.mutateAsync({
          id: location.state.id,
          search: search,
        });
      setJewelleryList(jewelleryData.responseData);
    };
    fetchCatalogueJewelleries();
  }, [search]);
  let subtitle;

  function afterOpenModal() {
    subtitle.style.color = "#f00";
  }

  const handleDelete = async (id) => {
    setIsOpen(true);
    setDeleteId(id);
  };

  const handleDeleteCatalogue = async () => {
    setIsOpen(true);
    setIsOpenCatalogueDelete(true);
  };

  const confirmDelete = async () => {
    if (IsOpenCatalogueDelete) {
      await DeleteCatalogue.mutateAsync({ id: location.state.id });
      navigate("/dashboard/catalogue");
    } else {
      setIsOpen(false);
      await DeleteCatalogueJewellery.mutateAsync({
        id: location.state.id,
        jewelleryId: deleteId,
      });
      const data = await GetCatalogueJewelleryMutation.mutateAsync({
        id: location.state.id,
        page: 1,
        perPage: 30,
      });
      setCatalogueJewellery(data.responseData);
      const jewelleryData =
        await GetJewelleryNotInCatalogueMutation.mutateAsync({
          id: location.state.id,
        });
      setJewelleryList(jewelleryData.responseData);
    }
  };
  function closeModal() {
    setIsOpen(false);
    setAddJewelleryIsOpen(false);
  }

  const addJewellery = async () => {
    await AddCatalogueJewellery.mutateAsync({
      id: location.state.id,
      jewelleryIds: selectedJewellery.map((jewellery) => jewellery._id),
    });
    setAddJewelleryIsOpen(false);
    const data = await GetCatalogueJewelleryMutation.mutateAsync({
      id: location.state.id,
      page: 1,
      perPage: 30,
    });
    setCatalogueJewellery(data.responseData);
    setSelectedJewellery([]);
    const jewelleryData = await GetJewelleryNotInCatalogueMutation.mutateAsync({
      id: location.state.id,
    });
    setJewelleryList(jewelleryData.responseData);
  };

  const handleSelectJewellery = (jewelleryData) => {
    const isThere = selectedJewellery.find(
      (item) => item._id === jewelleryData._id
    );
    if (!isThere) {
      setSelectedJewellery([...selectedJewellery, jewelleryData]);
    } else {
      // const temp = [...selectedJewellery]
      // temp.splice(index, 1)
      const temp = selectedJewellery.filter(
        (obj) => obj._id !== jewelleryData._id
      );
      setSelectedJewellery(temp);
    }
  };
  const renderAddJewelleryList = (data) => (
    <div
      className={`flex flex-col md:flex-row bg-white shadow-md p-4 mb-4 cursor-pointer transition duration-300 ease-in-out transform ${
        selectedJewellery.findIndex((item) => item._id === data._id) > -1
          ? ""
          : "hover:border hover:border-transparent hover:shadow-xl hover:cursor-pointer hover:border-jw-primary"
      } ${
        selectedJewellery.findIndex((item) => item._id === data._id) > -1
          ? "border-2 border-jw-primary"
          : ""
      }`}
      onClick={() => handleSelectJewellery(data)}
    >
      <div className={`md:w-1/3 mb-4 md:mb-0 `}>
        <img
          src={data.images[0]}
          alt={data.basic_info.name}
          className="w-full h-full md:h-24 object-cover rounded-md"
        />
      </div>
      <div className="md:w-2/3 md:ml-4">
        <h2 className="text-xl font-semibold text-gray-800 mb-2">
          {data.basic_info.name}
        </h2>

        <div className="flex flex-wrap mb-2">
          <div className="flex items-center mr-4 mb-2">
            <p className="font-bold mr-2">Jewecle Id:</p>
            <p>{data.jewecle_id}</p>
          </div>

          <div className="flex items-center mr-4 mb-2">
            <p className="font-bold mr-2">Tag ID:</p>
            <p>{data.tag_id}</p>
          </div>

          <div className="flex items-center mb-2">
            <p className="font-bold mr-2">Catalogue Name:</p>
            <p>{catalogueJewellery?.name || "-"}</p>
          </div>
        </div>

        <div className="flex flex-wrap mb-2">
          <div className="flex items-center mr-4 mb-2">
            <p className="font-bold mr-2">Weight:</p>
            <p>{data.product_details.net_weight} grams</p>
          </div>

          <div className="flex items-center mr-4 mb-2">
            <p className="font-bold mr-2">Wastage:</p>
            <p>{data.wastage_details.wastage}</p>
          </div>

          <div className="flex items-center mr-4 mb-2">
            <p className="font-bold mr-2">Size:</p>
            <p>{data.product_details.size}</p>
          </div>

          <div className="flex items-center mb-2">
            <p className="font-bold mr-2">Purity:</p>
            <p>{data.product_details.purity}</p>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="common-layout">
      <div className="flex flex-col md:flex-row justify-between items-start md:mt-10 lg:mt-2">
        <div className="flex flex-col  w-full ">
          {/* <div className="flex flex-col"> */}
            <Link to="/dashboard/catalogue">
              <MdOutlineKeyboardBackspace className="ml-2 text-3xl cursor-pointer" />
            </Link>
            <div className="flex justify-between w-full">
              <h1 className='text-lg md:text-2xl ml-2 font-bold'>Catalogue ({catalogueJewellery.name})</h1>
              <div
                className="cursor-pointer "
                onClick={handleDeleteCatalogue}
              >
                <button className="text-lg text-red-500 hover:opacity-50">
                  <svg
                    aria-hidden="true"
                    fill="none"
                    focusable="false"
                    height="2em"
                    role="presentation"
                    viewBox="0 0 20 20"
                    width="2em"
                  >
                    <path
                      d="M17.5 4.98332C14.725 4.70832 11.9333 4.56665 9.15 4.56665C7.5 4.56665 5.85 4.64998 4.2 4.81665L2.5 4.98332"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                    />
                    <path
                      d="M7.08331 4.14169L7.26665 3.05002C7.39998 2.25835 7.49998 1.66669 8.90831 1.66669H11.0916C12.5 1.66669 12.6083 2.29169 12.7333 3.05835L12.9166 4.14169"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                    />
                    <path
                      d="M15.7084 7.61664L15.1667 16.0083C15.075 17.3166 15 18.3333 12.675 18.3333H7.32502C5.00002 18.3333 4.92502 17.3166 4.83335 16.0083L4.29169 7.61664"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                    />
                    <path
                      d="M8.60834 13.75H11.3833"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                    />
                    <path
                      d="M7.91669 10.4167H12.0834"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                    />
                  </svg>
                </button>
              </div>
            {/* </div> */}
          </div>

          {/* <div className="flex justify-center items-center gap-4"> */}
            {/* <div
                            className="cursor-pointer bg-[rgba(255,196,1,0.28)] rounded-[5px] border-solid border-jw-golden border-2 p-2 flex flex-row gap-[15px] items-center justify-center w-[151px] h-[47px] relative"
                            style={{ boxShadow: "2px 2px 8px 0px rgba(86, 86, 86, 0.06)" }}
                            onClick={() =>{
                                if(!jewelleryList || jewelleryList.length === 0){
                                    toast(<ToastFailed message={'No Jewelleries Found'} />)
                                    return
                                }
                                setAddJewelleryIsOpen(true)
                            }}
                        >
                            <div className="flex flex-row gap-2 items-center justify-start shrink-0 relative">
                                <div className="flex flex-col gap-[3px] items-start justify-start shrink-0 relative">
                                    <div
                                        className="text-jw-golden text-left relative"
                                        style={{ font: "600 16px 'Segoe UI', sans-serif" }}
                                    >
                                        Add Jewellery
                                    </div>
                                </div>
                            </div>
                        </div> */}
          {/* </div> */}
        </div>
      </div>
      {GetCatalogueJewelleryMutation.isLoading ? (
        <div className="flex justify-center items-center p-4">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
        </div>
      ) : (
        <div
          className={`w-full bg-white ${
            catalogueJewellery?.jewellery_id?.length > 0
              ? "min-h-[650px]"
              : "mt-8"
          }`}
        >
          <JewelleryCard
            jewelleries={catalogueJewellery.jewellery_id}
            handleDelete={handleDelete}
          />
        </div>
      )}

      <Modal
        isOpen={IsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div className="flex flex-col items-center ">
          <div className="bg-white rounded-lg shadow-lg p-8 max-w-md">
            <h2 className="text-2xl font-semibold text-gray-800 mb-6">
              Are you sure you want to delete this{" "}
              {IsOpenCatalogueDelete ? "catalogue" : "jewellery"}?
            </h2>
            <div className="flex justify-end">
              <button
                className="px-4 py-2 text-sm font-semibold text-red-600 hover:text-red-800 focus:outline-none"
                onClick={() => setIsOpen(false)}
              >
                No
              </button>
              <button
                className="px-4 py-2 ml-4 bg-red-600 hover:bg-red-800 text-white rounded-lg text-sm font-semibold focus:outline-none "
                onClick={confirmDelete}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={AddJewelleryOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStylesJewellery}
      >
        <>
          <div className="sticky -top-5 z-10 bg-white  border-b-2 ">
            <div className="flex justify-between  ">
              <p className="text-2xl font-bold ">Selected Jewellery</p>
              <div
                className="cursor-pointer bg-[rgba(236,143,138,0.21)] rounded-[5px] border-solid border-[rgba(255,59,71,0.47)] border-2 p-1.5 w-24 flex flex-row  items-center justify-center"
                style={{ boxShadow: "2px 2px 8px 0px rgba(86, 86, 86, 0.06)" }}
                onClick={addJewellery}
              >
                <div className="flex flex-row gap-2 items-center justify-start shrink-0 ">
                  <div className="flex flex-col gap-[3px] items-start justify-start shrink-0 ">
                    <div className="text-jw-primary text-left ml-2">Done</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <input
                type="text"
                className="border-2 rounded-md p-2"
                placeholder="Search"
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-col justify-center gap-3 overflow-y-auto ">
            {jewelleryList?.map((item) => renderAddJewelleryList(item))}
          </div>
        </>
      </Modal>
      {/* <SelectedJewellery modalIsOpen={AddJewelleryOpen}
            addJewellery={addJewellery} handleSelectJewellery={handleSelectJewellery}
            selectedJewellery={selectedJewellery} afterOpenModal={afterOpenModal}
            jewelleryList={jewelleryList} closeModal={closeModal} catalogueName={catalogueJewellery.name} /> */}
    </div>
  );
}
