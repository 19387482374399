import React from 'react';
import Banner from '../../Components/Banner/Brand/Banner';
import HeaderTitle from '../../Components/HeaderTitle/HeaderTitle';
import List from '../../Components/List/List';
import Learn from '../../Components/Learn/Learn';
import Support from '../../Components/Support/Support';
import bannerPoster  from '../../assets/images/work-banner-poster.jpg';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';

function HowItWorks() {
  return (
    <div>
      {/* <Header /> */}
        <Banner
        title='ghum ghum ke kitni'
        Breaktitle='dukane'
        Pinktitle='ghumoge!'
        desc='apne dhandhe ko easy banao jewecle ke sath or'
        breakDesc='apni khurshi pe bethe kamao!'
        twoDesc='aap salesman nahi businessman ho'
        bannerImage={bannerPoster}
        />
        <HeaderTitle/>
        <List/>
        <Learn/>
        <Support/>
      {/* <Footer /> */}
        
    </div>
  )
}

export default HowItWorks
