import React from 'react'
import { Container } from 'react-bootstrap'

function HeaderTitle() {
  return (
      <div className='header-title'>
          <Container>
            <h1>Become A Seller On Jewecle in <br/><span>5 Simple Steps</span></h1>
          </Container>
      </div>
  )
}

export default HeaderTitle
