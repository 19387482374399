import React from "react";
import Slider from "react-slick";

const CarouselComponent = ({ images }) => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };

  return (
    <div>
      <Slider {...settings} className="w-60 md:w-32 mb-5 flex justify-start items-center " >
        {images.map((image, index) => (
          <div key={index} className="w-full md:w-36">
            <img
              src={image}
              alt="Jewellery Image"
            //   width={150}
              height={100}
              className="rounded-lg"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CarouselComponent;
