import React, { useState } from 'react';
import Inquiry from '../Enquiry/index'
import InquirySent from '../Enquiry/Sent/index'

function TabsCustomAnimation() {
    const data = [
        {
          label: "New Enquiries",
          value: "new",
          desc: <Inquiry />
        },
        {
          label: "Sent Responses",
          value: "sent",
          desc: <InquirySent />
        },
    ]

  const [activeTab, setActiveTab] = useState('new');

  return (
    <div className="border-b border-gray-200 mt-10 ">
      {/* Tabs Header */}
      <div className="flex py-3">
        {data.map(({ label, value }) => (
          <button
            key={value}
            onClick={() => setActiveTab(value)}
            className={`w-1/2 md:w-1/3 inline-block px-4 py-2 text-gray-600 rounded shadow ${activeTab === value ? 'font-bold border-b-2 bg-primary-pink text-white' : ''}`}
          >
            {label}
          </button>
        ))}
      </div>

      {/* Tabs Body */}
      <div className=" bg-white">
        {data.map(({ value, desc }) => (
          activeTab === value && (
            <div key={value} className="tab-content">
              {desc}
            </div>
          )
        ))}
      </div>
    </div>
  );
}

export default TabsCustomAnimation;