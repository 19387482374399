import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

// Components
import Spinner from "react-bootstrap/Spinner";
import Loader from "../../Components/Loader/Loader";

// Hooks
import { useVerifyPhone } from "../../hooks/register";

export default function Index() {
  const navigate = useNavigate();
  const verifyPhoneMutation = useVerifyPhone();

  // useStates
  const [mobile, setMobile] = useState("");
  const [isMobileError, setIsMobileError] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const handleMobileNumber = (e) => {
    if (e.target.value === "") {
      setIsMobileError("Phone number is required");
    } else if (e.target.value.length !== 10) {
      setIsMobileError("Phone number length should be 10");
    } else if (e.target.value.length === 10) {
      setIsMobileError("");
      setMobile(e.target.value);
    } else {
      setMobile(e.target.value);
    }
  };

  const onSubmit = (data) => {
    if (!mobile) {
      setIsMobileError("Phone number is required");
    } else if (mobile.length !== 10) {
      setIsMobileError("Phone number length should be 10");
    } else {
      verifyPhoneMutation.mutate({ phone: mobile, is_login: false });
      setIsMobileError("");
    }
  };

  useEffect(() => {
    if (verifyPhoneMutation?.isSuccess === true) {
      navigate("/register", {
        state: { mobileNumber: mobile, type: "home" },
      });
    }
  }, [verifyPhoneMutation?.isSuccess]);
  return (
    <>
      {verifyPhoneMutation?.isLoading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="button-input-field">
              <label>+91-</label>
              {/* <input
            placeholder="Enter your mobile number"
            name="mobileNumber"
            {...register("mobileNumber", {
              required: "Mobile number is required",
              minLength: {
                value: 10,
                message: "Mobile number should be 10 digits",
              },
            })}
            onChange
          /> */}
              <input
                type="tel"
                maxLength="10"
                placeholder="Enter your mobile number"
                name="mobileNumber"
                {...register("mobileNumber", {
                  required: "Mobile number is required",
                  minLength: {
                    value: 10,
                    message: "Mobile number must have at least 10 digits",
                  },
                })}
                onChange={(e) => handleMobileNumber(e)}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
              <button type="submit" className="primary-btn">
                {verifyPhoneMutation.isLoading ? (
                  <>
                    <Spinner animation="border" size="sm" className="me-2" />
                    Loading...
                  </>
                ) : (
                  "Start Selling"
                )}
              </button>
            </div>
          </form>
          {isMobileError && (
            <div className="mobile-verification-msg">
              <p className="text-danger">{isMobileError}</p>
            </div>
          )}
        </>
      )}
    </>
  );
}
