import React,{useState} from 'react'
import { Outlet } from 'react-router-dom'
import DashboardHeader from '../../Components/Dashboard/DashboardHeader'
import SideBar from '../../Components/Dashboard/SideBar'
function Dashboard() {
  const [toggle, setToggle] = useState(false)
  console.log('hello')
  return (
    <div className="dashboard">
      <DashboardHeader />
      <div className="dashboardSection">
        <SideBar menuTogge={toggle} setmenuTogge={setToggle} />
        {/* <div className='page-section'>
          <div className="main-dashboard">
            <Outlet />
          </div>
          <div className="bottom-footer">
            <p>copyright 2023 all rights reserved Jewecle</p>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default Dashboard
