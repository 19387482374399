
import {useState} from 'react'
import { FaEye } from "react-icons/fa";
import { MdOutlineModeEdit } from "react-icons/md";
import ViewProductModal from "../../Components/viewProductModal";
import { FaEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { AiOutlineDeliveredProcedure } from "react-icons/ai";
import {
    useGetJewellery,
    useGetProductById,
  } from "../../hooks/uploadJewellery";
import AddOrderModal from "../../Pages/Dashboard/addOrderModal";
import { GoShare } from "react-icons/go";

const JewelleryCard = ({ jewelleries, handleDelete }) => {
  const [productDetails, setProductDetails] = useState();
  const [isViewProductModal, setIsViewProductModal] = useState();
  const GetProductByIdMutation = useGetProductById();
  const [addOrderModal, setAddOrderModalModal] = useState(false);
  const [addOrderData, setAddOrderData] = useState();
  function capitalizeFirstLetter(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1).toLowerCase();
  }
  const renderTableBody = () => {
    if (!jewelleries || jewelleries.length === 0) {
      return (
        <tr className="flex justify-center items-center bg-am">
          <td
            colSpan="1"
            className="text-center font-bold whitespace-nowrap p-3"
          >
            No Data Found
          </td>
        </tr>
      );
    }

   
    const handleInfo = async (data) => {
      setIsViewProductModal(true);
      const producDetails = await GetProductByIdMutation.mutateAsync({
        id: data.product_id,
      });
      setProductDetails({
        data: [
          {
            selectedProduct: {
              product_id: data.product_id,
              subcategory: data.product_category,
              category: data.basic_info.name,
              length: producDetails.responseData.length,
              lengthValue: producDetails.responseData.lengthValue,
            },
            selectedImages: data.images,
            details: [
              {
                description: data.basic_info.description,
                status: data.product_details.status,
                tag_id: data.tag_id,
                huid: data.product_details.huid,
                gross_weight: data.product_details.gross_weight,
                net_weight: data.product_details.net_weight,
                width: data.product_details.width,
                length: data.product_details.length,
                breadth: data.product_details.breadth,
                measurement_in: data.product_details.measurement_in,
                purity: data.product_details.purity,
                size: data.product_details.size,
                color: data.product_details.color,
                days: data.product_details.days,
                is_customize: data.is_customize,
                wastage: data.wastage_details.wastage,
                discount: data.wastage_details.discount,
                other_amt: data.wastage_details.other_amt,
                total_labour: data.wastage_details.total_labour,
                final_wastage: data.wastage_details.final_wastage,
                _id: data._id,
              },
            ],
          },
        ],
        isView: true,
      });
    };
    const handleEdit = async (data) => {
        setIsViewProductModal(true);
        const producDetails = await GetProductByIdMutation.mutateAsync({
          id: data.product_id,
        });
        setProductDetails({
          data: [
            {
              selectedProduct: {
                product_id: data.product_id,
                subCategoryId: data.product_category._id,
                category: data.basic_info.name,
                length: producDetails.responseData.length,
                lengthValue: producDetails.responseData.lengthValue,
              },
              selectedImages: data.images.map((item) => {
                return {
                  name: item,
                  url: item,
                  uploadedUrl: item.split("/").slice(-2).join("/"),
                };
              }),
              details: [
                {
                  description: data.basic_info.description,
                  status: data.product_details.status,
                  tag_id: data.tag_id,
                  huid: data.product_details.huid,
                  gross_weight: data.product_details.gross_weight,
                  net_weight: data.product_details.net_weight,
                  width: data.product_details.width,
                  length: data.product_details.length,
                  breadth: data.product_details.breadth,
                  measurement_in: data.product_details.measurement_in,
                  purity: data.product_details.purity,
                  size: data.product_details.size,
                  color: data.product_details.color,
                  days: data.product_details.days,
                  is_customize: data.is_customize,
                  wastage: data.wastage_details.wastage,
                  discount: data.wastage_details.discount,
                  other_amt: data.wastage_details.other_amt,
                  total_labour: data.wastage_details.total_labour,
                  final_wastage: data.wastage_details.final_wastage,
                  _id: data._id,
                },
              ],
            },
          ],
        },)
      };
    
    return jewelleries.map((item, index) => (
      <tr key={item.id} className="">
        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
          <div class="flex items-center">
            <div class="flex-shrink-0 h-10 w-10">
              <img
                class="h-10 w-10 rounded-full"
                src={item?.images && item?.images[0]}
                alt={item?.product_name}
                // onClick={() => openGallery(index, item?.images)}
              />
            </div>
          </div>
        </td>
        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-gray-900 text-sm">
          {/* {capitalizeFirstLetter(item?.product_category?.name)}{" "} */}
          {capitalizeFirstLetter(item?.product_name)}
        </td>
        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-gray-900 text-sm">
          {item.tag_id}
        </td>
        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-gray-900 text-sm">
          {item.jewecle_id}
        </td>
        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-gray-900 text-sm">
          {item.product_details.purity}K
        </td>

        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-gray-900 text-sm">
          {item.product_details.net_weight} GRMS
        </td>
        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-gray-900 text-sm">
          {item.product_details.gross_weight} GRMS
        </td>
        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-primary-pink font-bold text-sm">
          {item.wastage_details.wastage} %
        </td>
        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-gray-900 text-sm">
          {item.product_details.size +
            " " +
            item.product_details.measurement_in}
        </td>
        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-gray-900 text-sm">
          {item.product_details.color}
        </td>
        <td className="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
          <div className="flex gap-3">
            <div
              onClick={() => handleInfo(item)}
              className="cursor-pointer text-blue-500 hover:text-blue-700"
            >
              <FaEye className="w-5 h-5" />
            </div>
            <div
                onClick={() => handleEdit(item)}
              className="cursor-pointer text-stone-500 hover:text-stone-700"
            >
              <MdOutlineModeEdit className="w-5 h-5" />
            </div>
           
            <div
              onClick={() => handleAddOrder(item)}
              className="cursor-pointer text-gray-600 hover:text-gray-700"
            >
              <AiOutlineDeliveredProcedure className="w-5 h-5" />
            </div>
            <div className="cursor-pointer text-green-600 hover:text-green-700">
              <GoShare className="w-5 h-5" />
            </div>
            <div
              className="cursor-pointer text-red-500 hover:text-red-700"
              
            >
              <MdDeleteOutline className="w-5 h-5" />
            </div>
          </div>
        </td>
      </tr>
    ));
  };
  const handleAddOrder = async (data) => {
    setAddOrderModalModal(true);
    setAddOrderData(data);
  };
  const closeModal = () => {
    setAddOrderModalModal(false);
  }

  return (
    <>
      <div className="p-3">
        <div className="h-[480px] w-full overflow-x-auto  scrollbar-thumb-gray-300 scrollbar-track-gray-100">
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table class="min-w-full">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Image
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>

                  <th
                    scope="col"
                    className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Tag Id
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Jewecle Id
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Purity
                  </th>

                  <th
                    scope="col"
                    className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Net Weight
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Gross Weight
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Wastage
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Size
                  </th>

                  <th
                    scope="col"
                    className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Color
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 border-b border-gray-200 bg-gray-100 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Action
                  </th>
                </tr>
              </thead>

              <tbody class="bg-white">{renderTableBody()}</tbody>
            </table>
          </div>
        </div>
      </div>
      {/* {
                jewelleries && jewelleries.length > 0 ?
                    <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6 p-3 mt-6'>
                        {jewelleries.map(jewellery => (
                            <div key={jewellery.id} className="w-full bg-white rounded-lg shadow-lg relative cursor-pointer border z-0">
                                <button
                                    className=" absolute top-2 left-2 z-10 w-6 h-6 flex items-center justify-center rounded-full bg-primary-pink text-white focus:outline-none focus:ring focus:ring-primary-pink-dark"
                                    onClick={() => handleDelete(jewellery._id)}
                                >
                                    <svg
                                        className="w-4 h-4"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </button>
                                <div className="relative h-28 sm:h-36 md:h-40 border-b">
                                    <img
                                        className="object-cover w-full h-full"
                                        src={jewellery.images[0]}
                                        alt={jewellery.name}
                                    />
                                </div>
                                <div className="p-2">
                                    <h5 className="text-sm sm:text-base text-gray-600 font-bold">{jewellery.basic_info.name}</h5>
                                    <p className="text-xs sm:text-sm text-gray-400">{jewellery?.basic_info?.description}</p>
                                    <div className="mt-2">
                                      
                                        <div className="flex items-center">
                                            <p className="text-xs sm:text-sm font-bold text-pink-600">Manufactured By: </p>
                                            <p className="text-sm sm:text-base text-gray-500 ml-2">{jewellery?.manufacturer_detail || '-'}</p>
                                        </div>
                                        <div className="flex items-center">
                                            <p className="text-xs sm:text-sm font-bold text-pink-600">Category: </p>
                                            <p className="text-sm sm:text-base text-gray-500 ml-2">{jewellery?.product_category?.name || '-'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    :
                    <div className="flex items-center justify-center w-full h-full">
                        <h3 className="font-bold text-xl py-6">No Data Found</h3>
                    </div>
            } */}
      {isViewProductModal && (
        <ViewProductModal
          isOpen={isViewProductModal}
          productDetails={productDetails}
          closeModal={() => setIsViewProductModal(false)}
          isLoading={GetProductByIdMutation.isLoading}
        />
      )}
       {addOrderModal && (
        <AddOrderModal
          isOpen={addOrderModal}
          closeModal={closeModal}
          jewelleryData={addOrderData}
        />
      )}
    </>
  );
};

export default JewelleryCard;
