import React from 'react'
import LoaderCircle from '../../assets/images/icons/loader.gif'

function Loader() {
  return (
  <>
  <div className="loader-container">
    <div className="loader-circle">
    <div class="loadingio-spinner-spinner-qrvp32pebxr"><div class="ldio-pxh5xu1d1j">
<div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
</div></div>
    </div>
  </div>
  </>
  )
}

export default Loader
