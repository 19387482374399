import React, { useState, useEffect } from 'react';
import TokenContext from './tokenContext';

const TokenProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('access_token'));

  useEffect(() => {
    // Listen for changes to the token in other windows or tabs
    const handleStorageChange = (e) => {
      if (e.key === 'access_token') {
        setToken(e.newValue);
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  return (
    <TokenContext.Provider value={token}>
      {children}
    </TokenContext.Provider>
  );
};

export default TokenProvider;