import React from "react";

// Components
import Footer from "../../Components/Footer/Footer";
import Home from "../Home/Home";
import Header from "../../Components/Header/Header";
import { useLocation } from "react-router";

function Index(props) {
  const location = useLocation();
  return (
    <>
      {(location.pathname === "/" || location.pathname === "/how-it-works" || location.pathname === "/home" || location.pathname === "/our-solution" || location.pathname === "/dukan-to-brand" ) ? <Header /> : null}
      {/* <Home /> */}
      {props.children}
      {(location.pathname === "/" || location.pathname === "/how-it-works" || location.pathname === "/home" || location.pathname === "/our-solution" || location.pathname === "/dukan-to-brand" ) ? <Footer /> : null }
      
    </>
  );
}

export default Index;
