import { useQuery, useMutation } from 'react-query';
import axios from 'axios';
import { toast } from 'react-toastify';
import ToastFailed from '../Components/Toaster/Failed';
import Toast from '../Components/Toaster/Successed';


const API_URL = process.env.REACT_APP_BASE_URL;

export const useGetEnquiry = () => {
    const getEnquiryMutation = useMutation(
        async ({ perPage, page, sent}) => {

            const response = await axios.get(`${API_URL}/jewellery-inquiry?perPage=${perPage}&page=${page}&sent=${sent}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json',
                    'accept': '*/*'
                }
            });
            return response.data;
        },
        {
            onSuccess: async (data) => {
                // navigate('/dashboard/home');
                // enqueueSnackbar('Product added successfully!', { variant: 'success',autoHideDuration: 3000 });
                // Handle success here
            },
            onError: (error) => {
                // Handle error here
                if (Array.isArray(error.response.data.message)) {
                    error.response.data.message.forEach(element => {
                        toast(<ToastFailed message={element} />)
                    });
                } else {
                    toast(<ToastFailed message={error.response.data.message} />)
                }
            },
        }
    );

    return getEnquiryMutation;
};

const fetchJewelleryInquiry = async (perPage, page) => {
    const response = await fetch(`${API_URL}/jewellery-inquiry?perPage=${perPage}&page=${page}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    });
    return response.json();
};

const fetchInquiry = async (perPage, page) => {
    const response = await fetch(`${API_URL}/jewellery-inquiry?perPage=${perPage}&page=${page}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    });
    return response.json();
};

export const useJewelleryInquiry = (perPage, page) => {
    return useQuery(['jewelleryInquiry', perPage, page], () => fetchJewelleryInquiry(perPage, page));
};

export const useSendQuotationMutation = (onSuccessCallback) => {
    const sendQuotation = useMutation(
        async (data) => {
            const response = await axios.post(`${API_URL}/jewellery-quotation`, data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            });
            return response.data;
        },
        {
            onSuccess: (data) => {
                toast(<Toast message={data.message}/>)   
                onSuccessCallback();
            },
            onError: (error) => {
                if (Array.isArray(error.response.data.message)) {
                    error.response.data.message.forEach(element => {
                        toast(<ToastFailed message={element} />)
                    });
                } else {
                    toast(<ToastFailed message={error.response.data.message} />)
                }
            },
        }
    );

    return sendQuotation;
};

export const useDeleteEnquiry = (onSuccessCallback) => {
    const GetDeleteMutation = useMutation(
        async ({id}) => {
            const response = await axios.put(`${API_URL}/jewellery-inquiry/${id}`,{},{
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            });
            return response.data;
        },
        {
            onSuccess: (data) => {
                toast(<Toast message={data.message}/>)  
                onSuccessCallback()
            },
            onError: (error) => {
                if (Array.isArray(error.response.data.message)) {
                    error.response.data.message.forEach(element => {
                        toast(<ToastFailed message={element} />)
                    });
                } else {
                    toast(<ToastFailed message={error.response.data.message} />)
                }
            },
        }
    );

    return GetDeleteMutation;
};