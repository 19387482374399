import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Loader from "../../Components/Loader/Loader";
import { useImageUpload } from "../../hooks/common";
import upload_file_ic from "../../assets/images/icons/upload_file_ic.svg";
import option_ic_close_arrow from "../../assets/images/icons/option_ic_close_arrow.svg";
import {
  useAddDailyFeed,
  useDeleteDailyFeed,
  useEditDailyFeed,
} from "../../hooks/dailyFeed";
import DeleteConfirmationModal from "../../Components/DeleteConfirmationModal";
import { Spinner } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useGetProductById } from "../../hooks/uploadJewellery";
import { useAddOrderMutation } from "../../hooks/order";

const customStyles = {
  content: {
    top: "55%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "61%", // For mobile by default
    height: "auto",
    backgroundColor: "white", // Change this to a solid color
  },
  contentMobile: {
    width: "80%",
    height: "60%",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // This is the backdrop
  },
};
const AddOrderModal = ({ isOpen, closeModal, jewelleryData }) => {
  const [lengthValues, setLengthValues] = useState([]);
  const GetProductByIdMutation = useGetProductById();
  const addOrderMutation = useAddOrderMutation();
  useEffect(() => {
    const getProductData = async () => {
      const producDetails = await GetProductByIdMutation.mutateAsync({
        id: jewelleryData?.product_id,
      });
      setLengthValues(producDetails.responseData.lengthValue);
    };
    getProductData();
  }, []);
  const defaultValues = {
    forms: {
      gross_weight: jewelleryData?.product_details?.gross_weight,
      net_weight: jewelleryData?.product_details?.net_weight,
      purity: jewelleryData?.product_details?.purity,
      size: jewelleryData?.product_details?.size,
      color: jewelleryData?.product_details?.color,
      is_customize: jewelleryData?.is_customize,
      note: jewelleryData?.note
    },
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    getValues,
  } = useForm({ defaultValues });
  const isMobile = window.innerWidth <= 768;
  const appliedStyles = isMobile
    ? { ...customStyles.content, ...customStyles.contentMobile }
    : customStyles.content;

  const getColor = (color) => {
    if (color === "Yellow") {
      return "#FAE270";
    } else if (color === "Rose Gold") {
      return "#B18A6F";
    } else {
      return "#9D9EA3";
    }
  };

  const handleSubmitForm = async (data, event) => {
    console.log('data', data)
    closeModal();
    await addOrderMutation.mutateAsync({
      ...data.forms,
      jewellery_id: jewelleryData._id,
      measurement_in: jewelleryData.product_details.measurement_in,
      is_customize: jewelleryData.is_customize ? true : false,
    });
  };

  //   const handleAdd = async () => {
  //     await addOrderMutation.mutateAsync({
  //       jewellery_id: jewelleryData._id,
  //       is_customize: false,
  //     });
  //   };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={{ ...customStyles, content: appliedStyles }}
    >
      <div className="overflow-hidden">
        <div className="flex justify-between items-center mb-1">
          <h3 className="font-bold">Add Order</h3>
          <button className="text-pink-600">
            <img
              onClick={closeModal}
              src={option_ic_close_arrow}
              alt="Close"
              className="w-4 h-4"
            />
          </button>
        </div>
        <div
          className={`flex flex-col md:flex-row bg-white shadow-md cursor-pointer transition duration-300 ease-in-out transform `}
        >
          <div className={`md:w-1/5  md:mb-0 `}>
            <img
              src={jewelleryData?.images[0]}
              alt={jewelleryData?.basic_info.name}
              className="w-40 h-40 object-fill rounded-md"
            />
          </div>
          <div className="md:w-4/5 md:ml-4">
            <h2 className="text-xl font-bold text-gray-800 mb-2">
              {jewelleryData?.product_category?.name}{" "}
              {jewelleryData?.basic_info.name}
            </h2>

            <div className="flex flex-wrap">
              <div className="flex items-center mr-4 md:mb-2">
                <p className="font-bold mr-2 text-base">Jewecle Id:</p>
                <p className="text-sm">{jewelleryData?.jewecle_id}</p>
              </div>

              <div className="flex items-center mr-4 md:mb-2">
                <p className="font-bold mr-2 text-base">Tag ID:</p>
                <p className="text-sm">{jewelleryData?.tag_id}</p>
              </div>
            </div>
            <div className="flex flex-wrap  flex-col md:flex-row">
              <div className="flex items-center mr-4 ">
                <p className="font-bold mr-2 text-base">Gross Weight:</p>
                <p className="text-sm text-pin">
                  {jewelleryData?.product_details?.gross_weight}
                </p>
              </div>
              <div className="flex items-center mr-4 ">
                <p className="font-bold mr-2 text-base">Net Weight:</p>
                <p className="text-sm">
                  {jewelleryData?.product_details?.net_weight}
                </p>
              </div>

              <div className="flex items-center mr-4 ">
                <p className="font-bold mr-2 text-base">Purity:</p>
                <p className="text-sm">
                  {jewelleryData?.product_details?.purity} grams
                </p>
              </div>

              <div className="flex items-center mr-4 ">
                <p className="font-bold mr-2 text-base">Size:</p>
                <p className="text-sm">
                  {jewelleryData?.product_details?.size}
                </p>
              </div>
              <div className="flex items-center mr-4 ">
                <p className="font-bold mr-2 text-base">Color:</p>
                <p className="text-sm">
                  {jewelleryData?.product_details?.color}
                </p>
              </div>
            </div>
            <div className="flex flex-wrap flex-col md:flex-row">
              <div className="flex items-center mr-4 md:mt-2">
                <p className="font-bold mr-2 text-base">Wastage:</p>
                <p className="text-sm text-primary-pink font-bold ">
                  {jewelleryData?.wastage_details?.wastage} %
                </p>
              </div>

              <div className="flex items-center mr-4 md:mt-2 ">
                <p className="font-bold mr-2 text-base">Customize Available</p>
                <p className="text-sm">
                  {jewelleryData?.is_customize ? "Yes" : "No"}
                </p>
              </div>

              {/* <div className="flex items-center mr-4 mb-2">
                  <p className="font-bold mr-2 text-base">Size:</p>
                  <p className="text-sm">{jewelleryData?.product_details?.size}</p>
                </div> */}

              {/* <div className="flex items-center mr-4 mb-2">
                  <p className="font-bold mr-2 text-base">Purity:</p>
                  <p className="text-sm">{jewelleryData?.product_details?.purity}</p>
                </div> */}
              {/* <div className="flex items-center gap-2 mb-2  mr-4">
                  <h5 className="font-bold text-base">Color:</h5>
                  {jewelleryData.product_details?.color
                    ? getColor(jewelleryData?.product_details?.color) && (
                        <p
                          className="whitespace-nowrap rounded-full border p-3 text-sm"
                          style={{
                            backgroundColor: getColor(
                              jewelleryData.product_details.color
                            ),
                          }}
                        ></p>
                      )
                    : "-"}
                </div> */}
              {/* <div className="flex items-center mr-4 mb-2">
                  <p className="font-bold mr-2 text-base">Size</p>
                  <p className="text-sm">
                    {jewelleryData?.product_details?.size +
                      " " +
                      jewelleryData?.product_details?.measurement_in}
                  </p>
                </div> */}
            </div>
          </div>
        </div>
        {jewelleryData.is_customize && (
          <form onSubmit={handleSubmit(handleSubmitForm)}>
            <div className="flex flex-wrap gap-4 w-full mt-4">
              {/* <div className="col-sm-6 col-md-6 col-lg-2">
              
            </div> */}
              <div className="input-container w-28">
                <label className="font-bold text-base">
                  Purity<span>*</span>
                </label>
                <div className="input-field">
                  <select
                    {...register(`forms.purity`, {
                      required: "Please select a purity",
                    })}
                    className="w-full p-1 border border-gray-400 rounded-md text-sm"
                    disabled={!jewelleryData?.is_customize}
                  >
                    <option value="18">18K</option>
                    <option value="20">20K</option>
                    <option value="22">22K</option>
                    <option value="24">24K</option>
                  </select>
                </div>
              </div>
              {errors.forms?.purity && (
                <p className="text-red-500 text-lg">
                  {errors.forms?.purity?.message}
                </p>
              )}
              <div className="input-container w-28">
                <label className="font-bold text-sm">
                  Color
                  <span className="text-red-600 ml-1">*</span>
                </label>
                <div className="color-wrapper">
                  <div className="input-field">
                    <select
                      // disabled={isView}
                      name={`forms.color`}
                      {...register(`forms.color`, {
                        required: "Please enter color",
                      })}
                      className="w-full p-1 border border-gray-400 rounded-md text-sm" // Same class applied here
                    >
                      <option value="Rose Gold">Rose Gold</option>
                      <option value="White Gold">Platinum</option>
                      <option value="Yellow">Gold</option>
                    </select>
                  </div>
                </div>
              </div>
              {errors.forms?.color && (
                <p className="text-red-500 text-lg">{errors?.color?.message}</p>
              )}
              {/* <div className="col-sm-6 col-md-6 col-lg-2">
              <div className="input-container">
                <div className="input-title">
                  <p>
                    <label>
                      color
                      <span className="pink-color-text">*</span>
                    </label>
                  </p>
                </div>

                <div className="color-wrapper ">
                  <ul className="flex gap-4">
                    <li
                      className={
                        watch(`forms.color`) === "Yellow"
                          ? "selected-option flex flex-col gap-2"
                          : "flex gap-2 flex-col"
                      }
                    >
                      <input
                        id={`color_1`}
                        type="radio"
                        value="Yellow"
                        name={`forms.color`}
                        {...register(`forms.color`, {})}
                        disabled={!jewelleryData?.is_customize}
                      />
                      <p
                        className="whitespace-nowrap rounded-full border p-3"
                        style={{
                          backgroundColor: getColor("Yellow"),
                        }}
                      ></p>
                    </li>
                    <li
                      className={
                        watch(`forms.color`) === "Rose Gold"
                          ? "selected-option flex gap-2 flex-col"
                          : "flex gap-2 flex-col"
                      }
                    >
                      <input
                        s
                        id={`color_2`}
                        type="radio"
                        value="Rose Gold"
                        name={`forms.color`}
                        {...register(`forms.color`, {})}
                        disabled={!jewelleryData?.is_customize}
                      />
                      <p
                        className="whitespace-nowrap rounded-full border p-3"
                        style={{
                          backgroundColor: getColor("Rose Gold"),
                        }}
                      ></p>
                    </li>
                    <li
                      className={
                        watch(`forms.color`) === "White Gold"
                          ? "selected-option flex gap-2 flex-col"
                          : "flex gap-2 flex-col"
                      }
                    >
                      <input
                        type="radio"
                        id={`color_3`}
                        value="White Gold"
                        name={`forms.color`}
                        {...register(`forms.color`, {})}
                        disabled={!jewelleryData?.is_customize}
                      />
                      <p
                        className="whitespace-nowrap rounded-full border p-3"
                        style={{
                          backgroundColor: getColor("White Gold"),
                        }}
                      ></p>
                    </li>
                  </ul>
                </div>
              </div>
              {errors.forms?.color && (
                <p className="text-red-500 text-lg">
                  {errors.forms?.color?.message}
                </p>
              )}
            </div> */}
              <div className="">
                <div className="input-container">
                  <label className="font-bold text-sm">
                    Gross Weight
                    <span className="pink-color-text ml-2">*</span>
                  </label>
                  <div className="input-field w-28">
                    {/* Register the input field with the Controller and update the value based on the calculation */}
                    <Controller
                      name={`forms.gross_weight`}
                      control={control}
                      rules={{
                        required: "Please enter gross weight",
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "Please enter a valid number",
                        },
                      }}
                      render={({ field }) => (
                        <input
                          disabled={!jewelleryData?.is_customize}
                          type="text"
                          {...field}
                          className="w-full p-1 border border-gray-400 rounded-md text-sm"
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                {errors.forms?.gross_weight && (
                  <p className="text-red-500 text-lg">
                    {errors.forms?.gross_weight?.message}
                  </p>
                )}
              </div>
              <div className="">
                <div className="input-container">
                  <label className="font-bold text-base">
                    Net weight
                    <span className="pink-color-text">*</span>
                  </label>
                  <div className="input-field w-28">
                    <input
                      disabled={!jewelleryData?.is_customize}
                      type="text"
                      className="w-full p-1 border border-gray-400 rounded-md text-sm"
                      name={`forms.net_weight`}
                      {...register(`forms.net_weight`, {
                        required: "Please enter net weight",
                      })}
                    />
                  </div>
                </div>
                {errors.forms?.net_weight && (
                  <p className="text-red-500 text-lg">
                    {errors.forms?.net_weight?.message}
                  </p>
                )}
              </div>
              <div className="col-sm-6 col-md-6 col-lg-2">
                <div className="input-container w-28">
                  <label className="font-bold text-base">
                    Size<span>*</span>
                  </label>
                  <div className="input-field">
                    <select
                      value={`${jewelleryData?.product_details?.size}`}
                      name={`forms.size`}
                      {...register(`forms.size`, {
                        required: "Please enter size",
                      })}
                      className="w-full p-1 border border-gray-400 rounded-md text-sm"
                      disabled={!jewelleryData.is_customize}
                    >
                      {lengthValues?.map((value) => (
                        <option value={`${value}`}>
                          {value +
                            " " +
                            jewelleryData?.product_details?.measurement_in}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {errors.forms?.size && (
                  <p className="text-red-500 text-lg">
                    {errors.forms?.size?.message}
                  </p>
                )}
              </div>
              <div className="">
                <div className="input-container">
                  <label className="font-bold text-base">
                    Note
                    {/* <span className="pink-color-text">*</span> */}
                  </label>
                  <div className="input-field w-28">
                    <input
                      // disabled={!jewelleryData?.is_customize}
                      type="text"
                      className="w-full p-1 border border-gray-400 rounded-md text-sm"
                      name={`forms.note`}
                      {...register(`forms.note`)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-end mt-4">
              <div className="action-img">
                <button
                  type="submit"
                  className="btn-dashboard outline-btn px-5 py-2"
                >
                  Add
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </Modal>
  );
};

export default AddOrderModal;
