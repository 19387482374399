import React, { useContext } from "react";
import Logo from "../../assets/images/jeweclelogo.png";

import menu_toggle from "../../assets/images/icons/menu_toggle.svg";
import { contextData } from "../../context/ContextState";
import DashHeaderAction from "./DashHeaderAction";
function DashboardHeader() {
  const {  handleToggle } = useContext(contextData);

  return (
    <>
      <div className="dash-header ">
        <div className="header">
          <div className="logo">
            <img className="w-28" src={Logo} alt=""  />
          </div>
          <div className="header-toggle">
            <DashHeaderAction />
            <button onClick={handleToggle} className="menu-toggle">
              <img src={menu_toggle} alt="" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardHeader;
