import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Logo from "../assets/images/logo192.png";
import { usePasswordReset } from "../hooks/common";

const ChangePassword = () => {
  const { token } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const resetPassword = usePasswordReset();
  const onSubmit = async (data) => {
    try {
      // Simulate API call
      //   const response = await fakeApiCall(token, data.newPassword);
      let response = await resetPassword.mutateAsync({
        token: token,
        password: data.newPassword,
      });
      console.log("response :>> ", response);
      if (response.statusCode === 200) {
        setIsSuccess(true);
        reset(); // Optionally reset the form after success
      }
    } catch (error) {
      setIsError(true);
    }
  };

  const fakeApiCall = (token, password) => {
    // Simulate an API call with a timeout
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (token && password) {
          resolve({ success: true });
        } else {
          reject({ success: false });
        }
      }, 1000);
    });
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center px-4">
      <div className="w-full max-w-md bg-white shadow-lg rounded-lg p-6 sm:p-8 md:max-w-lg">
        {/* Logo */}
        <div className="flex justify-center mb-6">
          <img
            src={Logo}
            alt="Logo"
            className="w-20 h-20 object-contain sm:w-20 sm:h-20"
          />
        </div>

        {/* Card Header */}
        <h2 className="text-2xl sm:text-3xl font-semibold text-center mb-6">
          Change Your Password
        </h2>

        {/* Success Message */}
        {isSuccess && (
          <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4 text-center">
            Password changed successfully!
          </div>
        )}

        {/* Error Message */}
        {isError && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
            There was an error changing your password.
          </div>
        )}

        {/* Form (Only Show if Not Success) */}
        {!isSuccess && (
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* New Password Input */}
            <div className="mb-4">
              <label
                htmlFor="new-password"
                className="block text-sm font-medium text-gray-700"
              >
                New Password
              </label>
              <input
                type="password"
                id="new-password"
                {...register("newPassword", {
                  required: "New Password is required",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters",
                  },
                })}
                className={`mt-1 block w-full px-4 py-2 border ${
                  errors.newPassword ? "border-red-500" : "border-gray-300"
                } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500`}
              />
              {errors.newPassword && (
                <span className="text-red-500 text-sm">
                  {errors.newPassword.message}
                </span>
              )}
            </div>

            {/* Confirm New Password Input */}
            <div className="mb-6">
              <label
                htmlFor="confirm-password"
                className="block text-sm font-medium text-gray-700"
              >
                Confirm New Password
              </label>
              <input
                type="password"
                id="confirm-password"
                {...register("confirmPassword", {
                  required: "Please confirm your password",
                  validate: (value) =>
                    value === watch("newPassword") || "Passwords do not match",
                })}
                className={`mt-1 block w-full px-4 py-2 border ${
                  errors.confirmPassword ? "border-red-500" : "border-gray-300"
                } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500`}
              />
              {errors.confirmPassword && (
                <span className="text-red-500 text-sm">
                  {errors.confirmPassword.message}
                </span>
              )}
            </div>

            {/* Change Password Button */}
            <button
              type="submit"
              className="w-full bg-secondary-violet text-white py-2 px-4 rounded-md hover:bg-indigo-700 transition duration-200"
            >
              Change Password
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default ChangePassword;
