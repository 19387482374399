import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Email from '../../assets/images/email.png';
import MobileVerify from '../../Components/MobileVerification/index'

function Support() {
    const token = localStorage.getItem('access_token')

    return (
        <div className='support pb-5 pt-5'>
            <Container>
                <Row className='justify-content-center'>
                    <Col md={8}>
                        <div className='support-content'>
                            <h2> <span>Jewecle</span> Supplier Support Available <span>24/7</span></h2>
                            {
                                !token && <MobileVerify />
                            }
                            <div className={`email ${token && 'mt-3'}`}>
                                <span>
                                    <img src={Email} alt='email-icon' />
                                </span>
                                <a href='mailto:hello@jewecle.com'>
                                    <h6>jeweclebusiness@gmail.com</h6></a>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Support
