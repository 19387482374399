import React, { useEffect, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import ArrowBack from '../../../assets/images/icons/arrow_back.svg'
import TutorialVideo from '../../../Components/Dashboard/TutorialVideo';
import { useGetCatalogue } from '../../../hooks/catalogue';
import group_img3 from '../../../assets/images/group_img5.jpg'

export default function CatalogueHome() {
    const navigate = useNavigate();

    const [catalogue, setCatalogue] = useState([])

    const GetCatalogueMutation = useGetCatalogue()


    useEffect(() => {
        const fetchCatalogue = async () => {
            const data = await GetCatalogueMutation.mutateAsync()
            setCatalogue(data.responseData.catalogue)
        }
        fetchCatalogue()
    }, [])

    const NoCatalogueFoundIcon = () => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-12 h-12 text-red-400"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
            />
        </svg>
    );

    const renderCatalogue = (item) => (
        <div key={item._id} className="w-full">
            <div className="w-full bg-white rounded-lg shadow-lg overflow-hidden">
                <div className="relative h-40 sm:h-56 md:h-40 lg:h-52 xl:h-56">
                    <img
                        className="object-cover w-full h-full"
                        src={group_img3}
                        alt={item.name}
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent"></div>
                </div>
                <div className="px-4 py-2">

                    <p className="text-black font-bold text-lg sm:text-xl">{item.name}</p>
                    <div className="flex justify-between items-center">
                        <div className='flex items-center'>
                            <p className="text-gray-600 font-semibold text-xs sm:text-sm">Total Item:</p>
                            <p className="text-primary-pink text-lg font-bold ml-1">{item.jewellery_id.length}</p>
                        </div>
                        <div className="flex items-center">
                            <Link to={'/dashboard/catalogue-detail'} state={{ id: item._id }}>
                                <button
                                    className="bg-primary-pink text-white text-xs sm:text-sm font-semibold px-3 py-1 sm:px-4 sm:py-2 rounded-lg shadow-md hover:bg-primary-pink-dark focus:outline-none focus:ring focus:ring-primary-pink-dark"
                                // onClick={() => handleViewMore(item.id)}
                                >
                                    View More
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    const renderCatalogueHome = () => {
        return (
            <>
                {GetCatalogueMutation.isLoading ? (
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
                    </div>
                ) : (
                    <>
                        {catalogue.length > 0 ? (
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 md:p-4">
                                {catalogue &&
                                    catalogue.map((item) => renderCatalogue(item))}
                            </div>
                        ) : (
                            <div className="flex justify-center items-center pt-12">
                                <NoCatalogueFoundIcon />
                                <p className="text-gray-400 text-xl ml-2">No catalogues found</p>
                            </div>
                        )}
                    </>
                )}
            </>
        );
    };
    return (
        <div className='common-layout'>
            <div className='flex flex-col md:flex-row justify-between items-start'>
                <div className='flex justify-between w-full items-center my-3'>
                    {/* <img src={ArrowBack} /> */}
                    <h1 className='text-lg md:text-2xl ml-2 font-bold'>My Catalogues</h1>
                    {/* <div className='flex justify-end px-8 cursor-pointer'> */}
                    {/* <h3 className='mt-4'>All Catalogues</h3> */}
                    {/* <Link to='/dashboard/add-catalogue'>
                        <div
                            className="bg-[rgba(255,196,1,0.28)] rounded-[5px]  border-solid border-jw-golden border-2 p-2 flex flex-row  items-center justify-center"
                            style={{ boxShadow: "2px 2px 8px 0px rgba(86, 86, 86, 0.06)" }}
                        >
                            <div className="flex flex-row gap-2 items-start justify-start shrink-0">
                                <svg
                                    className="shrink-0 overflow-visible"
                                    style={{}}
                                    width="24"
                                    height="25"
                                    viewBox="0 0 24 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M11 19.5V13.5H5V11.5H11V5.5H13V11.5H19V13.5H13V19.5H11Z"
                                        fill="#EDA039"
                                    />
                                </svg>

                                <div className="flex text-[#eda039] font-semibold  items-center justify-start shrink-0 ">
                                    Add new catalogue
                                </div>
                            </div>
                        </div>
                    </Link> */}
                    {/* </div> */}
                </div>
            </div>
            {/* <div className='flex justify-between w-full mt-2'>
                <div className='pr-96'>
                    <p className='text-base'>Personalised catalogues helps you to arrange your uploaded jewellery and to manage your stock in systematic manner.</p>
                </div>
                <TutorialVideo />
            </div> */}
            <div className='w-full bg-white min-h-[650px] mt-2'>
                {renderCatalogueHome()}
                {/* {renderAddCatalogueBtn()} */}
                {/* {renderAddCatalogueJewellery()} */}
                {/* {renderCatalogueJewelleryCard()} */}

            </div>
        </div>
    )
}