import { useMutation } from "react-query";
import axios from "axios";
import Successed from "../Components/Toaster/Successed";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Toast from "../Components/Toaster/Successed";
import ToastFailed from "../Components/Toaster/Failed";

const API_URL = process.env.REACT_APP_BASE_URL;

export const useLoginUser = () => {
  const navigate = useNavigate();
  const loginUserMutation = useMutation(
    async (formData) => {
      const response = await axios.post(`${API_URL}/user/login`, formData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      return response.data;
    },
    {
      onSuccess: (data) => {
        // Handle successful login here
        localStorage.setItem("access_token", data.responseData.access_token);
        localStorage.setItem(
          "registration_step",
          data.responseData.registration_step
        );
        localStorage.removeItem("impersonate");
        if (data.responseData.registration_step == 1) {
          navigate("/admin/supplier/sign-up-business");
        } else if (data.responseData.registration_step == 2) {
          navigate("/admin/supplier/address");
        } else if (data.responseData.registration_step == 3) {
          navigate("/dashboard");
          toast(<Toast message={data.message} />);
        } else {
          navigate("/dashboard");
          toast(<Toast message={data.message} />);
        }
      },
      onError: (error) => {
        console.error(error);
        // Handle error here
        if (Array.isArray(error.response.data.message)) {
          error.response.data.message.forEach((element) => {
            toast(<ToastFailed message={element} />);
          });
        } else {
          toast(<ToastFailed message={error.response.data.message} />);
        }
      },
    }
  );

  return loginUserMutation;
};
