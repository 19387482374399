import React, { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import DashboardHeader from "../Components/Dashboard/DashboardHeader";
import SideBar from "../Components/Dashboard/SideBar";
const ProtectedRoute = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const access_token = queryParams.get("access_token");
  const impersonate = queryParams.get("impersonate");
  if (access_token && impersonate) {
    localStorage.setItem("access_token", access_token);
    localStorage.setItem("impersonate", impersonate);
  }
  const token = localStorage.getItem("access_token");
  const [toggle, setToggle] = useState(false);
  if (!token) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="dashboard">
      <DashboardHeader />
      <div className="dashboardSection">
        <SideBar menuTogge={toggle} setmenuTogge={setToggle} />
        <div className="page-section">
          <div className="main-dashboard">
            <Outlet />
          </div>
          {/* <div className="bottom-footer">
                    <p>copyright 2023 all rights reserved Jewecle</p>
                </div> */}
        </div>
      </div>
    </div>
  );
};

export default ProtectedRoute;
