import { useState } from "react";
import multi_upload from '../../assets/images/icons/multi_upload.svg'
import single_upload from '../../assets/images/icons/single_upload.svg'

function CardSelectJwellery(props) {
  const [activeCard, setActiveCard] = useState(null);
  const cards = [
    { id: 1, icon: multi_upload, title: 'Multiple Jewelry', content: 'Multiple jewelry option will help you to upload more than one item with same category.I.e cZ ladies ring five pieces)' },
    { id: 2, icon: single_upload, title: 'Single Jewelry', content: 'Single jewelry option will help you upload single item with single category (i.e gents bracelet single piece)' },
  ];

  const handleClick = (data, id) => {
    props.onDataReceived(id);
    setActiveCard(id);
  };

  return (
    <div className="upload-option">
      <div className="row">
        {cards.map((card) => (
          <div
            key={card.id}
            className='col-sm-6 '
            onClick={() => handleClick(true, card.id)}
          >
            <div className={`upload-card ${activeCard === card.id ? 'active-upload-option' : ''} `}>
              <div className="flex">
                <img src={card.icon} className="pl-2" alt="" />
                <h6 className="whitespace-nowrap ml-3 text-lg font-bold ">{card.title}</h6>
              </div>
              <p className="pb-2 pl-2">{card.content}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CardSelectJwellery;