import axios from "axios";
import ToastFailed from "../Components/Toaster/Failed";
import { toast } from "react-toastify";
import { useQuery, useMutation } from "react-query";

const API_URL = process.env.REACT_APP_BASE_URL;

export const useGetPerformance = () => {
  const getPerformanceDetails = useMutation(
    async () => {
      const response = await axios.get(
        `${API_URL}/jewellery/jewelleryPerformance`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
            accept: "*/*",
          },
        }
      );
      return response.data;
    },
    {
      onSuccess: async (data) => {
        // navigate('/dashboard/home');
        // enqueueSnackbar('Product added successfully!', { variant: 'success',autoHideDuration: 3000 });
        // Handle success here
      },
      onError: (error) => {
        // Handle error here
        if (Array.isArray(error.response.data.message)) {
          error.response.data.message.forEach((element) => {
            toast(<ToastFailed message={element} />);
          });
        } else {
          toast(<ToastFailed message={error.response.data.message} />);
        }
      },
    }
  );

  return getPerformanceDetails;
};

export const useGetJewelleryFameTopCities = () => {
  const getPerformanceDetails = useMutation(
    async () => {
      const response = await axios.get(
        `${API_URL}/jewellery/jewelleryFameCity`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
            accept: "*/*",
          },
        }
      );
      return response.data;
    },
    {
      onSuccess: async (data) => {
        // navigate('/dashboard/home');
        // enqueueSnackbar('Product added successfully!', { variant: 'success',autoHideDuration: 3000 });
        // Handle success here
      },
      onError: (error) => {
        // Handle error here
        if (Array.isArray(error.response.data.message)) {
          error.response.data.message.forEach((element) => {
            toast(<ToastFailed message={element} />);
          });
        } else {
          toast(<ToastFailed message={error.response.data.message} />);
        }
      },
    }
  );

  return getPerformanceDetails;
};

export const useGetMostClickedJewellery = () => {
  const getPerformanceDetails = useMutation(
    async () => {
      const response = await axios.get(
        `${API_URL}/jewellery/mostClickedJewellery`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
            accept: "*/*",
          },
        }
      );
      return response.data;
    },
    {
      onSuccess: async (data) => {
        // navigate('/dashboard/home');
        // enqueueSnackbar('Product added successfully!', { variant: 'success',autoHideDuration: 3000 });
        // Handle success here
      },
      onError: (error) => {
        // Handle error here
        if (Array.isArray(error.response.data.message)) {
          error.response.data.message.forEach((element) => {
            toast(<ToastFailed message={element} />);
          });
        } else {
          toast(<ToastFailed message={error.response.data.message} />);
        }
      },
    }
  );

  return getPerformanceDetails;
};

export const useGetMostConnectedRetailer = () => {
  const getPerformanceDetails = useMutation(
    async () => {
      const response = await axios.get(
        `${API_URL}/jewellery/mostConnectedRetailers`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
            accept: "*/*",
          },
        }
      );
      return response.data;
    },
    {
      onSuccess: async (data) => {
        // navigate('/dashboard/home');
        // enqueueSnackbar('Product added successfully!', { variant: 'success',autoHideDuration: 3000 });
        // Handle success here
      },
      onError: (error) => {
        // Handle error here
        if (Array.isArray(error.response.data.message)) {
          error.response.data.message.forEach((element) => {
            toast(<ToastFailed message={element} />);
          });
        } else {
          toast(<ToastFailed message={error.response.data.message} />);
        }
      },
    }
  );

  return getPerformanceDetails;
};
