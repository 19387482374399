import React from "react";
import Banner from "../../Components/Banner/Brand/Banner";
import bannerPoster from "../../assets/images/banner_img.jpg";
import Support from "../../Components/Support/Support";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import JoinUs from "../../Components/FrontView/JoinUs";

function OurSolution() {
  return (
    <>
    {/* <Header /> */}
      <Banner
        title="Ab lakho ka maal leke risky "
        Breaktitle="travelling"
        Pinktitle="mat karo!"
        desc="risky travelling karke kitna tension loge, phir bhi"
        breakDesc="phir bhi pura maal to koi nai dekhta."
        bannerImage={bannerPoster}
      />
      <JoinUs/>
      <Support />
      {/* <Footer /> */}
    </>
  );
}

export default OurSolution;
