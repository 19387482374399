import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import AWS from "aws-sdk";

import Logo from "../../assets/images/logo.svg";
import Arrow from "../../assets/images/arrow.png";
import BackArrow from "../../assets/images/icons/back_arrow.svg";
import PlusiC from "../../assets/images/icons/plusIc.svg";
import { useSubmitGSTInfo } from "../../hooks/register";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import Toast from "../../Components/Toaster/Failed";
import { useImageUpload } from "../../hooks/common";
import Loader from "../../Components/Loader/Loader";

AWS.config.update({
  accessKeyId: "AKIA3IYKJBSGDM66IEXJ",
  secretAccessKey: "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",
  region: "us-east-1",
  signatureVersion: "v4",
});

function SignUpbusiness() {
  const s3 = new AWS.S3();

  //Mutation hooks
  const SubmitGSTInfoMutation = useSubmitGSTInfo();

  //Use State
  const [businessLogo, setBusinessLogo] = useState(null);
  const [businessCard, setBusinessCard] = useState(null);
  const imageUpload = useImageUpload();

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const { onChange } = register();

  const onSubmit = async (data) => {
    if (!businessLogo || !businessCard) {
      toast(<Toast message={"Please upload Business Logo and Card"} />);
      return;
    }
    SubmitGSTInfoMutation.isLoading = true;
    const body = {
      business_name: data?.business_name,
      business_logo: businessLogo.url,
      business_card: businessCard.url,
      gstin: data?.gstin,
      hallmark_number: data?.hallmark_number,
      website: data?.website ? data?.website : "",
      business_address: "5454",
    };
    SubmitGSTInfoMutation.mutate(body);
  };
  const handleBusinessLogoSelect = async (e) => {
    // setBusinessLogo(e.target.files[0]);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    let link = await imageUpload.mutateAsync(formData);
    setBusinessLogo({ url: link.responseData, name: e.target.files[0].name });
  };

  const handleBusinessCardSelect = async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    let link = await imageUpload.mutateAsync(formData);
    setBusinessCard({ url: link.responseData, name: e.target.files[0].name });
  };
  return (
    <>
      {imageUpload.isLoading && <Loader />}

      <div className="register-form form">
        <div className="form-content-container">
          {/* <button className="back-btn" onClick={() => {
             navigate("/register");
          }}>
            <img src={BackArrow} alt="" />
          </button> */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-content">
              <div className="form-heading">
                <h3>Business Details</h3>
                {/* <ul className='form_step'>
                <li className='active'></li>
                <li></li>
                <li></li>
             </ul> */}
              </div>

              <div className="input-container">
                <div className="input-title">
                  <p>
                    <label>
                      Business Name<span>*</span>
                    </label>
                  </p>
                </div>
                <div className="input-field">
                  <input
                    placeholder="Enter Business Name"
                    name="business_name"
                    {...register("business_name", {
                      required: "Business name is requierd",
                    })}
                    Value={localStorage.getItem("bussinessName")}
                  />
                </div>
                {errors.business_name && (
                  <span className="text-danger">
                    {errors.business_name.message}
                  </span>
                )}
              </div>
              <div className="input-container file-type">
                <div className="input-title">
                  <label>
                    Business Logo<span>*</span>
                  </label>
                </div>
                <div className="input-field file-input">
                  <input
                    type="file"
                    name="bussiness_Logo"
                    {...register("bussiness_Logo")}
                    onChange={handleBusinessLogoSelect}
                    accept="image/png, image/jpeg"
                  />
                  <p>{businessLogo ? businessLogo?.name : "Choose File*"}</p>
                  <button>
                    <img src={PlusiC} alt="" />
                  </button>
                </div>
                {errors.bussiness_Logo && (
                  <span className="text-danger">
                    {errors.bussiness_Logo.message}
                  </span>
                )}
              </div>
              <div className="input-container file-type">
                <div className="input-title">
                  <label>
                    Business Card<span>*</span>
                  </label>
                </div>
                <div className="input-field file-input">
                  <input
                    type="file"
                    name="bussiness_Card"
                    {...register("bussiness_Card", {
                      required: "Business card is requierd",
                    })}
                    onChange={handleBusinessCardSelect}
                    accept="image/png, image/jpeg"
                  />
                  <p>{businessCard ? businessCard.name : "Choose File*"}</p>
                  <button>
                    <img src={PlusiC} alt="" />
                  </button>
                </div>
                {errors.bussiness_Card && (
                  <span className="text-danger">
                    {errors.bussiness_Card.message}
                  </span>
                )}
              </div>
              <div className="input-container">
                <div className="input-title">
                  <p>
                    <label>
                      GST No.<span>*</span>
                    </label>
                  </p>
                </div>
                <div className="input-field">
                  <input
                    type={"text"}
                    placeholder="Enter GST No"
                    name="gstin"
                    {...register("gstin", {
                      required: "GST no is required",
                      pattern: {
                        value:
                          /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                        message: "Invalid GST number",
                      },
                    })}
                    Value={localStorage.getItem("gstNo")}
                  />
                </div>
                {errors.gstin && (
                  <span className="text-danger">{errors.gstin.message}</span>
                )}
              </div>
              <div className="input-container">
                <div className="input-title">
                  <p>
                    <label>
                      Hallmark License<span>*</span>
                    </label>
                  </p>
                </div>
                <div className="input-field">
                  <input
                    type={"text"}
                    placeholder="Enter Hallmark No"
                    name="hallmark_number"
                    {...register("hallmark_number", {
                      required: "Hallmark number is required",
                      // pattern: {
                      //   value: /^[\dA-Za-z]{10}$/,
                      //   message: "Invalid Hallmark License"
                      // }
                    })}
                    Value={localStorage.getItem("hallMarkNo")}
                  />
                </div>
                {errors.hallmark_number && (
                  <span className="text-danger">
                    {errors.hallmark_number.message}
                  </span>
                )}
              </div>
              <div className="input-container">
                <div className="input-title">
                  <p>
                    <label>Website</label>
                  </p>
                </div>
                <div className="input-field">
                  <input
                    type={"text"}
                    placeholder="Enter Website Link"
                    name="website"
                    {...register("website")}
                    Value={localStorage.getItem("website")}
                  />
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className={
                    !SubmitGSTInfoMutation.isLoading &&
                    businessCard &&
                    businessLogo
                      ? "primary-btn w-100 p-lg "
                      : "primary-btn w-100 p-lg disable-button"
                  }
                  disabled={
                    SubmitGSTInfoMutation.isLoading ||
                    !businessCard ||
                    !businessLogo
                  }
                >
                  {SubmitGSTInfoMutation.isLoading ? (
                    <>
                      <Spinner animation="border" size="sm" className="me-2" />
                      Loading...
                    </>
                  ) : (
                    <>
                      Next
                      <img src={Arrow} alt="arrow-right" />
                    </>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="form-poster-container d-none d-md-flex">
          <div className="form_bg"></div>
        </div>
      </div>
    </>
  );
}

export default SignUpbusiness;
