import React from "react";
import fbIc from "../../assets/images/icons/fb-ic.svg";
import linkdinIc from "../../assets/images/icons/linkdin-ic.svg";
import twitterIc from "../../assets/images/icons/twitter-ic.svg";
import instagamIc from "../../assets/images/icons/ig-ic.svg";
import WhiteLogo from "../../assets/images/jeweclelogo.png";
import logo2 from "../../assets/images/logo2.png"

function Footer() {
  const token = localStorage.getItem("access_token");
  return (
    <>
      <footer className="main_footer">
        <div className="footer">
          <div className="container">
            <div className="row">
              <div className="col-sm-4 mb-3 mb-md-0">
                <p className="footer-heading">Sell On Jewecle</p>
                <ul className="footer-nav">
                  <li className="footer-nav-item">
                    <a href="">Sell Online</a>
                  </li>
                  <li className="footer-nav-item">
                    <a href="">grow your business</a>
                  </li>
                  <li className="footer-nav-item">
                    <a href="">Pricing & Commission</a>
                  </li>
                  <li className="footer-nav-item">
                    <a href="">Learning videos</a>
                  </li>
                  <li className="footer-nav-item">
                    <a href="">How it works</a>
                  </li>
                  <li className="footer-nav-item">
                    <a href="">shop online</a>
                  </li>
                </ul>
              </div>
              <div className="col-6 col-sm-4 mb-3 mb-md-0">
                <p className="footer-heading">Contact Us</p>
                <ul className="footer-nav">
                  <li className="footer-nav-item">
                    <a href="">jeweclebusiness@gmail.com</a>
                  </li>
                </ul>
                <div className="footer-social-links">
                  <p className="footer-heading">Follow us on</p>
                  <ul>
                    <li>
                      <a href="">
                        {" "}
                        <img src={fbIc} alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="">
                        {" "}
                        <img src={linkdinIc} alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="">
                        {" "}
                        <img src={twitterIc} alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="">
                        {" "}
                        <img src={instagamIc} alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-6 col-sm-4">
                <div className="footer-indentity">
                  <img className="w-32" src={logo2} alt=""  />
                  <p>
                    Sell your products to of customers on jewecle at 0%
                    commission
                  </p>
                  {!token && (
                    <a href="" className="primary-btn yellow-btn">
                      Start Selling
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
